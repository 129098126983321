import React from 'react';
import { connect } from 'react-redux';
import { EntityType } from '../../entities/common';
import { actionCreators } from '../../store/ProcessesListStore';
import ListEntityWithNameCreation from '../common/ListEntityWithNameCreation';

const secondaryText = 'Fill in the Process Name field below to create a new Process. You will be able to provide Process details later';

type OwnProps = {
    processEntityType: EntityType;
    onDismiss: () => void;
};

type Props = OwnProps & typeof actionCreators;

const ProcessCreation = (props: Props) => {
    const onSave = React.useCallback(
        (name: string) => props.createProcess(name, props.processEntityType, true),
        [props.processEntityType, props.createProcess]);

    return (
        <ListEntityWithNameCreation
            entityType={EntityType.Process}
            header={{ secondaryText }}
            onSave={onSave}            
            onDismiss={props.onDismiss}
        />
    );
}

export default connect(undefined, actionCreators)(ProcessCreation);