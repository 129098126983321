import * as React from "react";
import { Icon, IStyle } from "office-ui-fabric-react";

type Props = {
    color: string;
    percent: number;
    iconName: string;
    classNames?: {
        root?: string;
        icon?: string;
    };
    getIconRootStyles?: (shadeColor: string) => IStyle;
};

const ShadableEntityIcon = (props: React.PropsWithChildren<Props>) => {
    const { color, iconName, percent, classNames, getIconRootStyles } = props;
    const shadeColor = calculateShadeColor(color, percent)!;

    return (
        <div className={`logo align-center ${classNames?.root ?? ''}`} style={{ backgroundColor: shadeColor }}>
            <Icon
                iconName={iconName}
                className={`icon ${classNames?.icon ?? ''}`}
                styles={{ root: getIconRootStyles ? getIconRootStyles(shadeColor) : { color } }}
            />
            {props.children}
        </div>
    );
};

export function calculateShadeColor(color: string | undefined, percent: number, withAlpha?: boolean) {
    if (color == undefined) {
        return undefined;
    }

    const f = parseInt(color.slice(1), 16),
        t = percent < 0 ? 0 : 255,
        p = percent < 0 ? percent * -1 : percent,
        R = f >> 16,
        G = (f >> 8) & 0x00ff,
        B = f & 0x0000ff;

    if (withAlpha) {
        return `rgba(${R}, ${G}, ${B}, ${percent})`;
    }

    return "#" + (0x1000000 + (Math.round((t - R) * p) + R) * 0x10000 + (Math.round((t - G) * p) + G) * 0x100 + (Math.round((t - B) * p) + B)).toString(16).slice(1);
}

export default ShadableEntityIcon;
