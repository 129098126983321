import * as React from 'react';
import * as analytics from '../../analytics';
import { RouteComponentProps, withRouter } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as Metadata from "../../entities/Metadata";
import { UserState, isInReadonlyMode } from '../../store/User';
import { canCreate, canUpdate } from '../../store/permissions';
import { Icon, IconButton, IContextualMenuItem, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import CategoryColorStatusView from '../views/list/columns/CategoryColorStatusView';
import { nameof } from '../../store/services/metadataService';
import { ILayoutActions, LayoutService } from '../utils/LayoutService';
import { IInsightsData, EntityType, StatusCategory, IWarning } from '../../entities/common';
import { TenantState } from '../../store/Tenant';
import { notUndefined } from '../utils/common';
import { LayoutsState } from '../../store/layouts';
import PersistEntityViewMenu from '../common/PersistEntityViewMenu';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import { buildAiProjectDigestMenuItemIfEnabled, buildProjectPpmInsightsMenuItemIfEnabled, getArchiveDialogContent } from '../project/ProjectsList';
import ProjectSettingsPanel from '../project/ProjectSettingsPanel';
import AIDigestPanel from '../project/AIDigestPanel';
import EntityHeader from '../common/EntityHeader';
import ProjectLogo from './ProjectLogo';
import Link from '../common/Link';
import FavoriteToggle from '../common/FavoriteToggle';
import { SummaryEntityWarning } from '../common/warnings/SummaryEntityWarning';
import RefreshProjectButton from '../common/sectionsControl/uiControls/projectSummaryControl/RefreshProjectButton';
import TasksIcon from '../common/sectionsControl/uiControls/projectSummaryControl/TasksIcon';
import LinkedSystemIcons from '../common/sectionsControl/uiControls/summaryControl/LinkedSystemIcons';
import RemoveDialog from '../common/RemoveDialog';
import TasksButton from '../common/sectionsControl/uiControls/projectSummaryControl/TasksButton';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';
import ProjectSetBaselineConfirmation from './ProjectSetBaselineConfirmation';
import PrivateProjectIcon from '../common/PrivateProjectIcon';
import EntityHeaderMoreMenu from '../common/EntityHeaderMoreMenu';
import StageView from '../views/list/columns/StageView';
import CategoryColorStageView from '../views/list/columns/CategoryColorStageView';

interface IActions {
    updateImage?: (logo: File) => void;
    removeImage?: () => void;
    setFavorite?: (isFavorite: boolean) => void;
    openConfigurationPanel: () => void;
    layoutActions?: ILayoutActions;
    cloneProject: () => void;
    removeProject: () => void;
    archiveProject?: () => void;
    updateSettings?: (data: Partial<IInsightsData & ProjectsListStore.ProjectSettings>) => void;
    setProjectBaseline: (setTasksBaseline: boolean, setKeyDatesBaseline: boolean) => void;
}

type OwnProps = {
    entity: ProjectsListStore.ProjectInfo;
    warnings?: IWarning[];
    warningsTypeMap?: string[];
    navigateToDetails?: boolean;
    hideWarnings?: boolean;
    disableChangeView?: boolean;
    actions: IActions;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
    projectFields: Metadata.Field[];
    layouts: LayoutsState;
}
type Props = OwnProps & StateProps & RouteComponentProps<{}>;

const trackProps = { entityType: EntityType.Project };

const ProjectHeader = (props: Props) => {
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
    const [isArchiveDialogOpen, setIsArchiveDialogOpen] = React.useState(false);
    const [layoutToApply, setLayoutToApply] = React.useState<Metadata.Layout>();
    const [isProjectSettingsOpen, setIsProjectSettingsOpen] = React.useState(false);
    const [isAIDigestPanelOpen, setIsAIDigestPanelOpen] = React.useState(false);
    const [isProjectBaselinePanelOpen, setIsProjectBaselinePanelOpen] = React.useState(false);
    const { entity, tenant, warnings, layouts, user, projectFields, actions } = props;
    const canClone = canCreate(user.permissions.project) && (entity.isEditable || entity.isArchived) && !entity.isPrivate;
    const _updateProjectSettings = (data: Partial<IInsightsData & ProjectsListStore.ProjectSettings>) => {
        actions.updateSettings?.(data);
        const keys = Object.keys(data);
        if (keys.includes(nameof<IInsightsData>("statusCalculationDisabled"))) {
            analytics.trackToggle("Calculated automatically - 'Project statuses' updated", props.user, !data.statusCalculationDisabled, trackProps);
        }
        if (keys.includes("keyDateStatusCalculationDisabled")) {
            analytics.trackToggle("Calculated automatically - 'Key dates statuses' updated", props.user, !data.keyDateStatusCalculationDisabled, trackProps);
        }
        if (keys.includes("taskStatusCalculationDisabled")) {
            analytics.trackToggle("Calculated automatically - 'Tasks statuses' updated", props.user, !data.taskStatusCalculationDisabled, trackProps);
        }
        if (keys.includes("warningsCalculationDisabled")) {
            analytics.trackToggle("Calculated automatically - 'Warnings' updated", props.user, !data.warningsCalculationDisabled, trackProps);
        }
        if (keys.includes("progressCalculationType")) {
            analytics.trackEvent("Progress calculation changed", props.user, { progressCalculationType: data.progressCalculationType, ...trackProps });
        }
    };
    const _isMoreMenuDisabled = () => {
        if (!props.entity.isEditable) {
            return false;
        }
        return !(canCreate(props.user.permissions.project) || canUpdate(props.user.permissions.project));
    };
    const moreMenuItems: IContextualMenuItem[] = [
        buildProjectPpmInsightsMenuItemIfEnabled(tenant, user, entity, props.history),
        buildAiProjectDigestMenuItemIfEnabled(tenant, user, entity.isArchived, entity.isEditable, () => setIsAIDigestPanelOpen(true)),
        {
            key: 'project-settings',
            iconProps: { iconName: 'Settings' },
            name: 'Project Settings',
            onClick: () => setIsProjectSettingsOpen(true),
        },
        {
            key: 'project-baseline',
            iconProps: { iconName: 'PPMXBaseline' },
            name: 'Set Project Baseline',
            onClick: () => setIsProjectBaselinePanelOpen(true)
        },
        entity.canConfigure && actions.layoutActions?.applyLayout
            ? {
                ...LayoutService.buildApplyLayoutMenuItem(layouts, (layout) => setLayoutToApply(layout), entity.layoutId),
                disabled: !entity.isEditable
            } : undefined,
        {
            key: 'cloneProject',
            iconProps: { iconName: 'Copy' },
            name: 'Clone Project',
            title: entity.isArchived
                ? "A project will be duplicated, using a limited dataset from an existing archived project."
                : entity.isPrivate
                    ? "Private projects cannot be cloned."
                    : undefined,
            disabled: !canClone,
            onClick: () => { actions.cloneProject() }
        },
        !!actions.archiveProject && !entity.isArchived ? {
            key: 'archiveProject',
            iconProps: { iconName: 'Archive' },
            name: 'Archive Project',
            title: entity.isPrivate ? "Private projects cannot be archived." : undefined,
            disabled: !entity.isEditable || entity.isPrivate,
            onClick: () => setIsArchiveDialogOpen(true)
        } : undefined,
        {
            key: 'deleteProject',
            iconProps: { iconName: 'Delete' },
            name: 'Delete Project',
            className: 'more-deleteButton',
            disabled: !entity.isEditable && !entity.isArchived,
            onClick: () => setIsRemoveDialogOpen(true)
        }
    ].filter(notUndefined);
    const readonlyMode = isInReadonlyMode(user, tenant, entity);
    const isConnectPanelAvailable = entity.isEditable || entity.sourceInfos.length && readonlyMode;
    const overallStatusField = projectFields.find(_ => _.name === nameof<ProjectsListStore.ProjectAttrs>("OverallStatus"))!;
    const statusDescriptor = StatusDescriptorFactory.createStatusDescriptor(overallStatusField);
    const statusOption = statusDescriptor.getOptionOrDefault(entity.attributes.OverallStatus, StatusCategory.NA);
    const stageOption = entity.attributes.Stage;
    return <>
        <EntityHeader
            entity={entity}
            nameTitle={entity.attributes.Name}
            logo={<ProjectLogo imageId={entity.imageId}
                onChanged={!entity.isEditable ? undefined : actions.updateImage}
                onRemove={!entity.isEditable ? undefined : actions.removeImage} />}
            name={<>
                {entity.isPrivate && <PrivateProjectIcon />}
                {props.navigateToDetails
                    ? <Link href={ProjectsListStore.getProjectPath(entity)} title="Navigate to Project Dashboard">
                        {entity.attributes.Name}
                    </Link>
                    : entity.attributes.Name}
            </>}
            leftSide={<>
                {!entity.isArchived && actions.setFavorite && <FavoriteToggle
                    isFavorite={entity.isFavorite}
                    disabled={readonlyMode}
                    onChange={actions.setFavorite}
                />}
                {entity.isArchived
                    ? <div className="color-status" title="Archived">
                        <div className={`align-center status Archived`}>
                            <Icon iconName="Archive" className="status-icon" />
                            <span className="status-label">Archived</span>
                        </div>
                    </div>
                    : <CategoryColorStatusView
                        statusOption={statusOption}
                        trend={entity.insights.statuses[nameof<ProjectsListStore.ProjectAttrs>("OverallStatus")]?.trend}
                        title={overallStatusField?.label}
                    />}
                {stageOption && <CategoryColorStageView stageOption={stageOption} withBorder />}
                {!props?.hideWarnings && props.warningsTypeMap && <SummaryEntityWarning warnings={warnings ?? []}
                    message="There might be some data inconsistency in your project. For more details please review items marked with warning icons below."
                    commonWarningTypes={props.warningsTypeMap} />}

            </>}
            buttons={<>
                {!props.navigateToDetails && <TasksButton entity={entity} />}
                {!entity.isArchived && <RefreshProjectButton entity={entity} />}
                <TasksIcon entity={entity} />
                <LinkedSystemIcons
                    sourceInfos={entity.sourceInfos}
                    taskProgresses={entity.calculation.taskProgresses}
                    showTitle
                    showMetrics
                    allowNavigate
                    entityType={EntityType.Project} />
                {!entity.isArchived && <IconButton iconProps={{ iconName: 'PPMXLink' }}
                    className="connect-launcher"
                    title="Connect"
                    disabled={!isConnectPanelAvailable}
                    onClick={() => actions.openConfigurationPanel()} />}
                <EntityHeaderMoreMenu
                    entity={entity}
                    entityType={EntityType.Project}
                    items={moreMenuItems}
                    disabled={_isMoreMenuDisabled()} />
            </>}
            views={actions.layoutActions
                ? <PersistEntityViewMenu
                    layoutsState={layouts}
                    entity={entity}
                    entityType={EntityType.Project}
                    readonlyMode={readonlyMode}
                    disableChangeView={props.disableChangeView}
                    user={user}
                    viewLayout={actions.layoutActions?.viewLayout}
                    saveLayout={entity.isEditable ? actions.layoutActions?.saveLayout : undefined}
                    savePinnedViews={entity.canConfigure
                        ? actions.layoutActions?.updateEntityPinnedViews
                        : undefined}
                    saveEntityLayout={entity.canConfigure || readonlyMode
                        ? actions.layoutActions?.updateEntityLayout
                        : undefined}
                    deleteLayout={actions.layoutActions?.deleteLayout}
                    disableManageConfiguration={!!entity.isArchived}
                    profileLayoutId={user.permissions.projectProfileLayoutId}
                    layoutIdByEntityId={user.permissions.layoutIdByProjectIdMap?.[entity.id]}
                />
                : undefined}
        ></EntityHeader>
        {
            isRemoveDialogOpen && <RemoveDialog
                onClose={() => setIsRemoveDialogOpen(false)}
                onComplete={() => { actions.removeProject(); }}
                dialogContentProps={{
                    title: "Delete project",
                    subText: `Are you sure you want to delete project "${entity!.attributes.Name}"?`
                }}
                confirmButtonProps={{ text: "Delete" }} />
        }
        {
            isArchiveDialogOpen && actions.archiveProject && <RemoveDialog
                onClose={() => setIsArchiveDialogOpen(false)}
                onComplete={() => { actions.archiveProject?.(); }}
                dialogContentProps={getArchiveDialogContent([entity])}
                confirmButtonProps={{ text: "Archive" }} >
                <MessageBar messageBarType={MessageBarType.warning}>Restoring an archived project will not be feasible, but it will be possible to clone it.</MessageBar>
            </RemoveDialog>
        }
        {
            isProjectSettingsOpen && <ProjectSettingsPanel
                entity={entity}
                readonlyMode={readonlyMode}
                onDismiss={() => setIsProjectSettingsOpen(false)}
                onSave={_updateProjectSettings} />
        }
        {
            isAIDigestPanelOpen && <AIDigestPanel
                project={entity}
                onDismiss={() => setIsAIDigestPanelOpen(false)} />
        }
        {
            layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => actions.layoutActions!.applyLayout!(layoutToApply)}
                onDismiss={() => setLayoutToApply(undefined)}
                entityType={EntityType.Project}
                layoutName={layoutToApply!.name}
            />
        }
        {
            isProjectBaselinePanelOpen && <ProjectSetBaselineConfirmation
                onConfirm={(setTasksBaseline, setKeyDatesBaseline) => { props.actions.setProjectBaseline?.(setTasksBaseline, setKeyDatesBaseline) }}
                onDismiss={() => setIsProjectBaselinePanelOpen(false)}/>
        }
    </>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    const projectFields = state.fields[EntityType.Project];
    return {
        user: state.user,
        tenant: state.tenant,
        projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
        layouts: state.layouts[EntityType.Project]
    }
}

export default withRouter<OwnProps>(connect(mapStateToProps)(ProjectHeader));