import * as React from 'react';
import { LayoutsState } from "../../../store/layouts";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { EntityType } from '../../../entities/common';
import { notUndefined } from '../../utils/common';
import DropdownInput, { DropdownInputProps } from '../inputs/DropdownInput';
import { ILayoutInfo } from '../../../entities/Subentities';

type OwnProps = Pick<DropdownInputProps, 'useEmptyValue' | 'readOnly' | 'disabled'> & {
    entityType: EntityType;
    layoutId: string;
    onChanged: (layout: ILayoutInfo | null) => void;
}

type StateProps = {
    layouts: LayoutsState;
};

type Props = OwnProps & StateProps;

const EntityLayoutsDropdown = (props: Props) => {
    const options = React.useMemo(() => props.layouts.allIds.map(_ => (props.layouts.byId[_].isView ? undefined : {
        key: _,
        text: props.layouts.byId[_].name
    })).filter(notUndefined), [props.layouts]);

    const onChanged = React.useCallback((layoutId: string | undefined) => {
        const layout = layoutId ? props.layouts.byId[layoutId] : undefined
        const layoutInfo = layout ? { id: layout.id, name: layout.name } : null;
        props.onChanged(layoutInfo);
    }, [props.layouts, props.onChanged]);

    return (
        <DropdownInput
            {...props}
            value={props.layoutId}
            inputProps={{ options }}
            useEmptyValue={props.useEmptyValue}
            onChanged={onChanged}
        />
    );
};

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        layouts: state.layouts[ownProps.entityType]
    };
}

export default connect(mapStateToProps)(EntityLayoutsDropdown);
