import { Selection, ISelectionOptions, IObjectWithKey } from 'office-ui-fabric-react';

type SelectedIndexMap = { [idx: number]: boolean };
type T = () => void;

export default class SelectionExt extends Selection {
    private _eventHandlersMap = new Map<T, number | undefined>();
    private _customGetKey?: (item: IObjectWithKey, index?: number) => string | number;
    //used get current Selection.defaultGetKey implementation
    private _defaultSelection = new Selection();

    private _selectedIndicesMap: SelectedIndexMap = {};

    constructor(options: ISelectionOptions = {}) {
        super({
            ...options, onSelectionChanged: () => {
                options.onSelectionChanged?.();
                const indicesMap = this.getSelectedIndices().reduce((cum, cur) => ({...cum, [cur]: true}), {} as SelectedIndexMap);

                this._eventHandlersMap.forEach((idx, handler) => {
                    if (idx === undefined
                        || indicesMap[idx] !== this._selectedIndicesMap[idx]) handler();
                });

                this._selectedIndicesMap = indicesMap;
            },
            getKey: (item: IObjectWithKey, index?: number) => {
                if (this._customGetKey) {
                    return this._customGetKey(item, index);
                }
                if (options.getKey) {
                    return options.getKey(item, index);
                }
                return this._defaultSelection.getKey(item, index);
            }
        });
    }

    public AddEventHandler = (handler: T, index?: number) => {
        this._eventHandlersMap.set(handler, index);
    }

    public RemoveEventHandler = (handler: T) => {
        this._eventHandlersMap.delete(handler);
    }

    public SetGetKey = (getKey?: (item: IObjectWithKey, index?: number) => string | number) => {
        this._customGetKey = getKey;
    }
}