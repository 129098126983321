import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { EntityType } from '../../../entities/common';
import { ApplicationState } from '../../../store';
import { actionCreators as UserActionCreators, UserState } from '../../../store/User';
import { actionCreators as UserInterfaceActionCreators, UserInterfaceState } from '../../../store/UserInterfaceStore';
import { BaseSectionsContainerProps } from '../../common/sectionsControl/SectionsContainer/BaseSectionsContainer';
import { Process } from '../../../store/process/common';
import { SectionsContainer } from '../../common/sectionsControl/SectionsContainer';
import { DETAILS_SECTION_ID, ProcessSections } from './ProcessSections';

type OwnProps = Pick<BaseSectionsContainerProps, 'controlsConfig'> & {
    process: Process;
};

interface StateProps {
    user: UserState;
    userInterface: UserInterfaceState;
};

type ActionProps = {
    userActions: typeof UserActionCreators;
    userInterfaceActions: typeof UserInterfaceActionCreators;
};

type Props = OwnProps & ActionProps & StateProps & RouteComponentProps<{}>;

const ProcessSectionsContainer = (props: Props) => {
    return (
        <SectionsContainer
            {...props}
            entity={{
                ...props.process,
                sections: ProcessSections,
                // todo: maybe move out of entity. maybe re-think the approach
                hiddenActionsContainerIds: [DETAILS_SECTION_ID],
            }}
            entityType={EntityType.Process}
        />
    );
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        userActions: bindActionCreators(UserActionCreators, dispatch),
        userInterfaceActions: bindActionCreators(UserInterfaceActionCreators, dispatch)
    };
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        userInterface: state.userInterface
    };
}

export default withRouter<OwnProps>(connect(mapStateToProps, mergeActionCreators)(ProcessSectionsContainer));
