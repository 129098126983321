import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import { bindActionCreators } from 'redux';
import { Dictionary, EntityType, IPatch, StageCategory } from "../../../entities/common";
import { DetailsSpinner } from "../../common/Spinner";
import * as ProcessStagesControl from '../../common/sectionsControl/uiControls/ProcessStagesControl';
import { UserState } from '../../../store/User';
import { actionCreators as ProcessesActionCreators } from '../../../store/ProcessesListStore';
import { actionCreators as NotificationsActionCreators } from "../../../store/NotificationsStore";
import { IProcessAttrs, Process, ProcessStatus, StageType } from '../../../store/process/common';
import { nameof, namesof } from '../../../store/services/metadataService';
import { IProcessStage } from '../../../entities/Subentities';
import { ICreationData } from '../../../store/Subentity';
import { IControlConfiguration } from '../../common/interfaces/ISectionUIControlProps';
import { TenantState } from '../../../store/Tenant';
import { IFieldsAreaConfiguration } from '../../common/sectionsControl/uiControls/fieldsArea/common';
import ProcessHeader from './ProcessHeader';
import ProcessSectionsContainer from './ProcessSectionsContainer';
import { buildViewTypeSelect, ViewTypeViews, withViewType } from '../../common/ViewTypeSelect';
import { Views } from '../../../store/services/viewSaver';

const disabledReadOnlyFields = namesof<IProcessAttrs>(['IsDefault', 'EntityType']);

interface IConfiguration extends Dictionary<IControlConfiguration> {
    FieldsArea: IFieldsAreaConfiguration;
    ProcessStagesControl: ProcessStagesControl.IConfiguration;
}

type StateProps = {
    tenant: TenantState;
    user: UserState;
    entity?: Process;
    isLoading: boolean;
};

type ActionProps = {
    processessActions: typeof ProcessesActionCreators;
    notificationsActions: typeof NotificationsActionCreators;
};

type Props = RouteComponentProps<{ id: string }> & StateProps & ActionProps;

const ProcessDetails = (props: Props) => {
    const { entity } = props;
    
    React.useEffect(() => {
        props.processessActions.loadProcess(props.match.params.id);
    }, [(props.match.params.id)]);

    const cloneProcess = () => {
        props.processessActions.cloneProcess(entity!.id);
    };

    const removeProcess = () => {
        props.processessActions.removeProcesses([entity!.id]);
    };

    const updateProcessStatus = (status: ProcessStatus) => {
        props.processessActions.updateProcessAttributes(entity!.id, { [nameof<IProcessAttrs>('Status')]: status });
    };

    const refreshEntity = () => {
        props.processessActions.loadProcess(entity!.id);
    };

    const onEditComplete = (fieldName: string, fieldValue: any, extra: Dictionary<any>): void => {
        if (!fieldName) return;
        props.processessActions.updateProcessAttributes(entity!.id, { [fieldName]: fieldValue, ...extra });
    };

    const createProcessStage = (processStage: ICreationData) => {
        props.processessActions.createProcessStages(props.entity!.id, [processStage]);
    };
    
    const createProcessStages = (processStages: ICreationData[]) => {
        props.processessActions.createProcessStages(props.entity!.id, processStages);
    };

    const updateProcessStage = (processStageId: string, changes: Dictionary<unknown>) => {
        props.processessActions.updateProcessStages(props.entity!.id, [{ id: processStageId, attributes: changes }]);
    };

    const updateProcessStages = (processStages: IPatch<IProcessStage>[]) => {
        props.processessActions.updateProcessStages(props.entity!.id, processStages);
    };

    const removeProcessStages = (processStageIds: string[]) => {
        props.processessActions.removeProcessStages(props.entity!.id, processStageIds);
    };

    const updateStageTypes = (stageTypes: StageType[]) => {
        props.processessActions.updateProcessStageTypes(props.entity!.id, stageTypes);
    };

    const dragProcessStages = (entities: string[], groupId?: string, insertbeforeId?: string) => {
        if (!groupId) return;
        const category = Number(groupId) as StageCategory;
        props.processessActions.dragProcessStagesToCategory(props.entity!.id, entities, category, insertbeforeId);
    };

    const buildControlsConfigurations = (): IConfiguration => {
        return {
            ['FieldsArea']: {
                datacontext: {
                    entityId: entity!.id,
                    entityType: EntityType.Process,
                    readOnlyFields: disabledReadOnlyFields,
                    disabledFields: disabledReadOnlyFields,
                },
                actions: {
                    refreshEntity,
                    onEditComplete,
                    updateUIControl: null,
                },
            },
            ['ProcessStagesControl']: {
                ...buildViewTypeSelect(ViewTypeViews.ProcessStagesControl),
                actions: {
                    create: createProcessStage,
                    update: updateProcessStage,
                    createBulk: createProcessStages,
                    updateBulk: updateProcessStages,
                    remove: removeProcessStages,
                    updateUIControl: null,
                    refreshEntity,
                    updateStageTypes,
                    dragEntities: dragProcessStages,
                }
            },
        };
    }

    return (
        <DetailsSpinner isLoading={props.isLoading}>
            {entity && <ProcessHeader
                entity={entity}
                actions={{ cloneProcess, removeProcess, updateProcessStatus }}
            />}
            {entity && (
                <ProcessSectionsContainer
                    process={entity}
                    controlsConfig={buildControlsConfigurations()}
                />
            )}
        </DetailsSpinner>
    );
};


function mergeActionCreators(dispatch: any): ActionProps {
    return {
        processessActions: bindActionCreators(ProcessesActionCreators, dispatch),
        notificationsActions: bindActionCreators(NotificationsActionCreators, dispatch),
    };
}

function mapStateToProps(state: ApplicationState, ownProps: RouteComponentProps<{ id: string }>): StateProps {
    return {
        tenant: state.tenant,
        user: state.user,
        entity: state.processes.activeEntity && state.processes.activeEntity.id === ownProps.match.params.id ? state.processes.activeEntity : undefined,
        isLoading: state.processes.isLoading,
    };
}

export default connect(mapStateToProps, mergeActionCreators)(ProcessDetails);
