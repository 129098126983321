import { Link, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import React, { useState } from 'react'
import { ConfirmationDialog } from '../../common/ConfirmationDialog';
import EntityPicker from '../../common/inputs/EntityPicker';
import { IFindResult } from '../../common/inputs/EntityPickerInput';
import { ServerEntityType } from '../../../entities/common';
import { IWithProcess, ProcessInfo } from '../../../store/ProjectsListStore';
import LabellableComponent from '../../common/LabellableComponent';
import TextInput from '../../common/inputs/TextInput';

type Props = {
    readonly?: boolean,
    value: IWithProcess;
    onChange: (value: IWithProcess) => void;
};

const ProjectProcessSection = (props: Props) => {
    const { readonly, value, onChange } = props;
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [processInfo, setProcessInfo] = useState<ProcessInfo>(value.process);

    return (
        <>
            <div className='section-description'>
                Select the process for the current project and setup approval workflow. Please refer to
                <Link href="https://help.ppm.express" target="_blank"> this article</Link> for more details.
            </div>
            <LabellableComponent className="field-container" label="Process">
                <EntityPicker
                    value={processInfo}
                    searchPath="api/process/find"
                    filter={{ exceptIds: processInfo ? [processInfo.id] : [], entityType: ServerEntityType.Project }}
                    inputProps={{ readonly: readonly }}
                    onEditComplete={_ => {
                        setProcessInfo((_ as IFindResult)); 
                        setShowConfirmation(true); 
                    }} />
            </LabellableComponent>
            <LabellableComponent className="field-container" label="API Based approval workflow url">
                <TextInput value={value.apiBasedApprovalUrl} onChanged={_ => onChange({process: value.process, apiBasedApprovalUrl: _ || undefined})}/>
            </LabellableComponent>
            
            {showConfirmation &&
                <ConfirmationDialog
                    dialogContentProps={{
                        isMultiline: true,
                        title: "Changing Project Process",
                        subText: `Are you sure you want to change Project Process? `
                    }}
                    onNo={() => { }}
                    onDismiss={() => setShowConfirmation(false)}
                    onYes={() => onChange({process: processInfo, apiBasedApprovalUrl: value.apiBasedApprovalUrl})}
                >
                    <MessageBar messageBarType={MessageBarType.warning}>
                        Project Stage will be reset to the initial default value: the first value in the Project Stage select field or the first stage in the selected Stage-Gate process.
                    </MessageBar>
                </ConfirmationDialog>}
        </>);
}

export default ProjectProcessSection;
