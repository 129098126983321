import * as React from "react";
import { Icon } from "office-ui-fabric-react";
import { IGroupInfo } from "../../entities/Metadata";
import { ungroupedGroup } from "../common/inputs/GroupDropdown";
import ShadableEntityIcon from "../common/ShadableEntityIcon";

type TaskIconEntity = {
    attributes?: { Group?: IGroupInfo };
    externalData: { NameIcon?: string };
    isAutoMode?: boolean;
    hierarchy?: { isParent: boolean };
};

const DefaultSummaryIconColor: string = "#6a6b6d";

type TaskIconProps = {
    entity: TaskIconEntity;
    milestoneIconName?: string;
    summaryIcon?: boolean;
};

const TaskIcon = (props: TaskIconProps) => {
    const { entity, summaryIcon, milestoneIconName } = props;

    if (milestoneIconName) {
        return (
            <div className={`logo align-center ${milestoneIconName}`}>
                <Icon className={milestoneIconName} iconName={milestoneIconName} />
                {summaryIcon && entity.hierarchy?.isParent && <SummaryTaskChevronIcon />}
            </div>
        );
    }

    if (entity.externalData.NameIcon) {
        return (
            <div className="logo task-logo align-center">
                <span className="icon" style={{ backgroundImage: `url(${entity.externalData.NameIcon})` }} />
                {summaryIcon && entity.hierarchy?.isParent && <SummaryTaskChevronIcon color={DefaultSummaryIconColor} />}
            </div>
        );
    }

    const color = entity.attributes?.Group?.color || ungroupedGroup.color;
    return <BaseTaskIcon color={color} percent={0.5} isAutoMode={entity.isAutoMode} isParent={entity.hierarchy?.isParent} summaryIcon={summaryIcon} />;
};

export default TaskIcon;

type BaseTaskIconProps = {
    color?: string;
    percent: number;
    isAutoMode?: boolean;
    isParent?: boolean;
    summaryIcon?: boolean;
};

export const BaseTaskIcon = (props: BaseTaskIconProps) => {
    const { percent, isAutoMode, isParent, summaryIcon } = props;
    const color = props.color ?? ungroupedGroup.color;

    return (
        <ShadableEntityIcon
            color={color}
            percent={percent}
            iconName="StatusCircleCheckmark"
            classNames={{
                root: "task-logo",
                icon: isAutoMode ? "auto-mode" : undefined,
            }}
            getIconRootStyles={(shadeColor) => ({ background: color, color: shadeColor })}
        >
            {summaryIcon && isParent && <SummaryTaskChevronIcon color={color} />}
        </ShadableEntityIcon>
    );
};

const SummaryTaskChevronIcon = (props: { color?: string }) => <Icon iconName="Down" className={"expandable"} styles={{ root: { color: props.color } }} />;
