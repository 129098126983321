import * as React from 'react';
import {
    CheckboxVisibility, CommandBarButton, DirectionalHint, ICommandBarItemProps, IconButton, IContextualMenuItem,
    IDialogContentProps, MessageBar, MessageBarType, ScrollablePane, ScrollbarVisibility, Selection
} from 'office-ui-fabric-react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Dictionary, EntityType, mapServerEntityType, updateCheckboxOptions } from '../../../entities/common';
import { ApplicationState } from '../../../store';
import { actionCreators as processActionCreators } from '../../../store/ProcessesListStore';
import { UserState } from "../../../store/User";
import { CommonOperations, contains } from "../../../store/permissions";
import Spinner from '../../common/Spinner';
import { bindActionCreators } from 'redux';
import * as Metadata from "../../../entities/Metadata";
import EntityDetailsList, {  } from '../../common/extensibleEntity/EntityDetailsList';
import RemoveDialog from '../../common/RemoveDialog';
import { IDeletionResult } from '../../../store/services/storeHelper';
import { canBeDeleted, IProcessAttrs, Process, ProcessStatus } from '../../../store/process/common';
import { groupBy, notUndefined } from '../../utils/common';
import { FilterHelper } from '../../../store/process/filters';
import { ActiveFilter as ProjectActiveFilter } from '../../../store/project/filters';
import ProcessCreation from '../../process/ProcessCreation';
import EntityGroupHeader, { EntityGroup } from '../../common/extensibleEntity/EntityGroupHeader';
import { nameof, namesof } from '../../../store/services/metadataService';
import SelectionModeSwitchableCommandBar from '../../common/SelectionModeSwitchableCommandBar';
import { ClearFilterComponent } from '../../common/sectionsControl/SectionPlaceholder';
import ListMenuFilterSelector from '../../common/ListMenuFilterSelector';

const buildProcessStatusFilters = (): Metadata.IFilter<Metadata.BaseFilterValue>[] => {
    return Object.entries(ProcessStatus)
        .filter(([_, value]) => typeof value === 'number')
        .map(([key, value]) => ({
            id: key,
            name: `${key} Processes`,
            attributeNames: [{ name: nameof<IProcessAttrs>('Status'), type: 'attributes' }],
            value: { attributes: { [nameof<IProcessAttrs>('Status')]: [value.toString()] } },
            isBuiltIn: true,
            isDefault: false,
            isPublic: true,
            isNotSaved: false,
        }));
}

const allProcessesFilter: Metadata.IFilter<Metadata.BaseFilterValue> = {
    id: "54a24bf5-4e3d-407a-9c07-dbae406bb6df",
    name: "All",
    attributeNames: [],
    value: {},
    isBuiltIn: true,
    isDefault: true,
    isPublic: true,
    isNotSaved: false,
};

const processFilters: Metadata.IFilter<Metadata.BaseFilterValue>[] = [
    allProcessesFilter,
    ...buildProcessStatusFilters(),
];

export const DefaultGroups: Metadata.Group[] = [
    {
        id: EntityType.Project,
        name: "Project Process",
        color: "#128a10",
        isShown: true,
        isDefault: true
    },
];

const processEntityType = EntityType.Process;

type StateProps = {
    fields: Metadata.Field[];
    user: UserState;
    processes: Process[];
    isLoading: boolean;
    isListLoading: boolean;
    deletionResult?: IDeletionResult[];
};

type ActionProps = {
    processesActions: typeof processActionCreators;
};

export type Props = StateProps & ActionProps & RouteComponentProps<{}>;

const ProcessesList = (props: Props) => {
    const [selectedProcesses, setSelectedProcesses] = React.useState<Process[]>([]);
    const [newProcessEntityType, setNewProcessEntityType] = React.useState<EntityType>();
    const [processesToRemove, setProcessesToRemove] = React.useState<Process[]>([]);
    const [activeFilter, setActiveFilter] = React.useState(allProcessesFilter);
    
    const canManage = contains(props.user.permissions.common, CommonOperations.Administrate);

    React.useEffect(() => {
        props.processesActions.requestProcesses();
    }, []);

    const selection = React.useMemo(() => new Selection({
        onSelectionChanged: () => {
            setSelectedProcesses(selection.getSelection() as Process[]);
        }
    }), []);

    const entityFilterHelper = React.useMemo(() => new FilterHelper({ fields: props.fields }), [props.fields]);

    const applyFilter = (process: Process[]) => {
        return process.filter(isItemVisible);
    }

    const isItemVisible = (process: Process): boolean => {
        if (!activeFilter) return true;

        const filterValue = activeFilter.value;
        const allAttributes = entityFilterHelper.getFilterAttributes(props.fields);

        for (const type in filterValue) {
            if (!entityFilterHelper.helpersMap[type].validateItem(process, filterValue[type], allAttributes.filter(_ => _.type === type))) {
                return false;
            }
        }

        return true;
    }

    const updateProcessesStatus = (processes: Process[], status: ProcessStatus) => {
        const statusUpdates = processes.reduce((acc, process) => {
            acc[process.id] = { [nameof<IProcessAttrs>('Status')]: status };
            return acc;
        }, {} as Dictionary<Dictionary<ProcessStatus>>);
        props.processesActions.bulkUpdateProcesses(statusUpdates)
    };

    const buildActivateMenuItem = (processes: Process[]): IContextualMenuItem => ({
        key: 'activate',
        name: 'Activate',
        iconProps: { iconName: 'CircleAddition' },
        onClick: () => updateProcessesStatus(processes, ProcessStatus.Active)
    });

    const buildDeactivateMenuItem = (processes: Process[]): IContextualMenuItem => ({
        key: 'deactivate',
        name: 'Deactivate',
        iconProps: { iconName: 'Blocked2' },
        onClick: () => updateProcessesStatus(processes, ProcessStatus.Inactive)
    });

    const renderThreeDotsMenu = (process: Process) => {
        const items: IContextualMenuItem[] = [
            buildStatusItemIfSelectedStatusDiffers(ProcessStatus.Active, buildActivateMenuItem),
            buildStatusItemIfSelectedStatusDiffers(ProcessStatus.Inactive, buildDeactivateMenuItem),
            {
                key: 'view-related',
                name: `View Related Projects`,
                iconProps: { iconName: 'PPMXListView' },
                onClick: () => props.history.push(`/projects`, new ProjectActiveFilter("Custom").withProcess(process.id).buildLocationState())
            },
            !process.attributes.IsDefault && process.isEditable ? {
                key: 'set-as-default',
                name: 'Set as Default',
                iconProps: { iconName: 'Copy' },
                onClick: () => props.processesActions.makeProcessDefault(process.id)
            } : undefined,
            {
                key: 'edit',
                name: process.isEditable ? 'Edit' : 'View',
                iconProps: { iconName: process.isEditable ? 'Edit' : 'View' },
                onClick: () => props.history.push(`/process/${process.id}`)
            },
            process.isEditable ? {
                key: 'clone',
                name: 'Clone',
                iconProps: { iconName: 'Copy' },
                onClick: () => props.processesActions.cloneProcess(process.id)
            } : undefined,
            canBeDeleted(process) ? {
                key: 'delete',
                name: 'Delete',
                iconProps: { iconName: "Delete", style: { color: 'red' } },
                disabled: props.isLoading,
                style: {
                    color: (props.isLoading ? 'initial' : 'red'),
                    backgroundColor: (props.isLoading ? 'lightgrey' : 'unset')
                },
                onClick: () => setProcessesToRemove([process])
            } : undefined
        ].filter(notUndefined);

        return <div className="menu">
            <IconButton
                menuIconProps={{ iconName: 'PPMXMore' }}
                menuProps={{
                    directionalHint: DirectionalHint.bottomRightEdge,
                    items
                }}
            />
        </div>;
    }

    const buildStatusItemIfSelectedStatusDiffers = (status: ProcessStatus, updateStatusItemBuilder: (processes: Process[]) => IContextualMenuItem) => {
        return selectedProcesses.some(_ => _.attributes.Status !== status)
            ? updateStatusItemBuilder(selectedProcesses)
            : undefined;
    }
    
    const buildSelectionModeCommands = (): ICommandBarItemProps[] => {
        if (!canManage) {
            return [];
        }

        return [
            buildStatusItemIfSelectedStatusDiffers(ProcessStatus.Active, buildActivateMenuItem),
            buildStatusItemIfSelectedStatusDiffers(ProcessStatus.Inactive, buildDeactivateMenuItem),
            selectedProcesses.length === 1 && !canBeDeleted(selectedProcesses[0]) 
                ? undefined 
                : {
                    key: 'delete',
                    text: 'Delete',
                    iconProps: { iconName: 'Delete' },
                    onClick: () => setProcessesToRemove(selectedProcesses)
                }
        ].filter(notUndefined);
    }

    const buildListGroups = () => {
        const processesByEntityType = groupBy(props.processes, _ => mapServerEntityType[_.attributes.EntityType]!.toString());
        return DefaultGroups.map<EntityGroup>(_ => ({
            key: _.id,
            name: _.name,
            color: _.color,
            count: processesByEntityType[_.id]?.length ?? 0,
            hideMarker: true
        }));
    };

    const buildDeletionResultDialogContent = (deletionResult: IDeletionResult[]): IDialogContentProps => {
        if (deletionResult.length === 1) {
            return deletionResult[0].isDeleted
                ? {
                    title: "Process deletion is complete",
                    subText: `Process "${deletionResult[0].name}" was deleted successfully.`
                }
                : {
                    title: "Unable to delete process",
                    subText: "The selected process cannot be deleted."
                }
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return {
            title: "Processes deletion is completed",
            subText: `Selected processes (${deleted.length} items) were deleted successfully.`
        }
    }

    const buildCommands = (): IContextualMenuItem[] => {
        return [
            {
                key: 'new-process',
                onRender: () => (
                    <CommandBarButton
                        key="new-entity-header-button"
                        className='align-center new-entity-header-button'
                        iconProps={{ iconName: 'Add' }}
                        text="New"
                        menuProps={{
                            items: [
                                {
                                key: 'emailMessage',
                                text: 'New Project Process',
                                iconProps: { iconName: 'Add' },
                                onClick: () => setNewProcessEntityType(EntityType.Project)
                                },
                            ],
                            directionalHintFixed: true,
                        }}
                        disabled={!canManage}
                        styles={{ root: { height: 40, padding: '0 8px' } }}
                    />
                )
            }
        ];
    }

    const buildFarCommands = (): IContextualMenuItem[] => {
        return [
            {
                key: 'filter',
                onRender: () => (
                    <ListMenuFilterSelector
                        filters={processFilters}
                        activeFilter={activeFilter}
                        canManageConfiguration={canManage}
                        onActiveItemChanged={(id) => {
                            const newActiveFilter = processFilters.find(_ => _.id === id) ?? allProcessesFilter;
                            setActiveFilter(newActiveFilter);
                        }}
                    />
                )
            }
        ];
    }

    const selectionModeCommands = buildSelectionModeCommands();

    if (props.isLoading || props.isListLoading) {
        return <Spinner />;
    }

    return <>
        <div className="processes">
            <h2>Process Management</h2>
            <div className="entities-list">
                <div className="entities-list-header">
                    <SelectionModeSwitchableCommandBar
                        items={buildCommands()}
                        farItems={buildFarCommands()}
                        selectionMode={{
                            enabled: selectedProcesses.length > 0,
                            items: selectionModeCommands,
                            selectedCount: selectedProcesses.length,
                            onCancel: () => selection.setAllSelected(false),
                        }}
                    />
                </div>
                <div key='list' className="entities-list-body list-container">
                    <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                        <ClearFilterComponent
                            items={props.processes}
                            filteredItems={applyFilter(props.processes)}
                            onClearFilter={() => setActiveFilter(allProcessesFilter)}>
                            <EntityDetailsList
                                entities={applyFilter(props.processes)}
                                entityType={processEntityType}
                                fields={props.fields}
                                displayFields={namesof<IProcessAttrs>(["Name", "IsDefault", "Status", "Description"])}
                                selection={selection}
                                groups={buildListGroups()}
                                listGrouping={{
                                    getGroupKey: (process: Process) => mapServerEntityType[process.attributes.EntityType]!,
                                    renderGroupHeader: (group, isCollapsed, isSelected, onToggleCollapse, onToggleSelect) => (
                                        <EntityGroupHeader
                                            group={group}
                                            isCollapsed={isCollapsed}
                                            isSelected={isSelected}
                                            onToggleCollapse={onToggleCollapse}
                                            onToggleSelect={onToggleSelect}
                                            renderCounter={(grp) => <span className="subitems-counter">{grp.count}</span>}
                                        />
                                    ),
                                }}
                                onItemMenuRender={renderThreeDotsMenu}
                                {...updateCheckboxOptions({
                                    checkboxVisibility: !canManage ? CheckboxVisibility.hidden : undefined
                                }, selectionModeCommands, true)}
                            />
                        </ClearFilterComponent>
                    </ScrollablePane>
                </div>
            </div>
        </div>
        {newProcessEntityType !== undefined && (
            <ProcessCreation
                processEntityType={newProcessEntityType}
                onDismiss={() => setNewProcessEntityType(undefined)}
            />
        )}
        {!!processesToRemove.length && (
            <RemoveDialog
                onClose={() => setProcessesToRemove([])}
                onComplete={() => props.processesActions.removeProcesses(processesToRemove.map(_ => _.id))}
                dialogContentProps={buildRemoveDialogContentProps(processesToRemove)}
                confirmButtonProps={{ text: "Delete", disabled: processesToRemove.length === 0 }}
            >
            </RemoveDialog>
        )}
        {props.deletionResult && (
            <RemoveDialog
                onClose={() => props.processesActions.dismissDeletionResult()}
                confirmButtonProps={{ text: "Got it" }}
                modalProps={{ styles: { main: { minWidth: 500 } } }}
                dialogContentProps={buildDeletionResultDialogContent(props.deletionResult)}
            >
                {props.deletionResult.length > 1 && props.deletionResult.some(_ => !_.isDeleted) && (
                    <MessageBar messageBarType={MessageBarType.warning} isMultiline={true}>
                        Failed to delete the following processes:
                        <ul>
                            {props.deletionResult.filter(_ => !_.isDeleted).map(_ => <li key={_.id}>{_.message}</li>)}
                        </ul>
                    </MessageBar>
                )}
            </RemoveDialog>
        )}
    </>;
};

const buildRemoveDialogContentProps = (processesToRemove: Process[]): IDialogContentProps => {
    if (processesToRemove.length === 1) {
        return {
            title: "Delete process",
            subText: `Are you sure you want to delete process "${processesToRemove[0].attributes.Name}"?`
        }
    }

    return {
        title: "Delete processes",
        subText: `Are you sure you want to delete selected processes (${processesToRemove.length} items)?`
    }
}

const mapStateToProps = (state: ApplicationState): StateProps => {
    const fields = state.fields[processEntityType];
    return {
        fields: fields.allIds.map(_ => fields.byId[_]),
        user: state.user,
        processes: state.processes.allIds.map(_ => state.processes.byId[_]),
        isLoading: state.processes.isLoading,
        isListLoading: state.processes.isListLoading,
        deletionResult: state.processes.deletionResult,
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        processesActions: bindActionCreators(processActionCreators, dispatch),
    };
}

export default connect(mapStateToProps, mergeActionCreators)(ProcessesList);
