import * as React from 'react';
import { IconButton, IContextualMenuItem, mapEnumByName } from 'office-ui-fabric-react';
import { notUndefined } from '../../utils/common';
import EntityHeader from '../../common/EntityHeader';
import RemoveDialog from '../../common/RemoveDialog';
import Logo from '../../common/Logo';
import StageView from '../../views/list/columns/StageView';
import { canBeDeleted, Process, ProcessStatus } from '../../../store/process/common';
import { ProcessStatusMap } from '../../../store/ProcessesListStore';

interface IActions {
    cloneProcess: () => void;
    removeProcess: () => void;
    updateProcessStatus: (status: ProcessStatus) => void;
}

type Props = {
    entity: Process;
    actions: IActions;
}

const ProcessHeader = (props: Props) => {
    const { entity, actions } = props;
    const [isRemoveDialogOpen, setIsRemoveDialogOpen] = React.useState(false);
    
    const statuses = entity.isEditable
        ? mapEnumByName(ProcessStatus, (_, value) => {
            const status = Number(value);
            return {
                key: status.toString(),
                data: status,
                name: ProcessStatusMap[status].title,
                onClick: () => actions.updateProcessStatus(status)
            };
        })!.filter(notUndefined)
        : undefined

    const moreMenuItems: IContextualMenuItem[] = [
        {
            key: 'clone-process',
            iconProps: { iconName: 'Copy' },
            name: 'Clone Process',
            disabled: !entity.isEditable,
            onClick: () => actions.cloneProcess()
        },
        {
            key: 'delete-process',
            iconProps: { iconName: 'Delete' },
            name: 'Delete Process',
            className: 'more-deleteButton',
            disabled: !canBeDeleted(entity),
            onClick: () => setIsRemoveDialogOpen(true)
        }
    ];
    
    return <>
        <EntityHeader
            entity={entity}
            name={entity.attributes.Name}
            nameTitle={entity.attributes.Name}
            logo={<Logo className="process-logo" />}
            leftSide={(
                <StageView
                    value={entity.attributes.Status}
                    className="process-status"
                    map={ProcessStatusMap}
                    subMenuItems={statuses?.filter(_ => _.data !== entity.attributes.Status)}
                />
            )}
            buttons={<>
                <IconButton
                    menuIconProps={{ iconName: 'More' }}
                    className='menu'
                    text='More'
                    disabled={!entity.isEditable}
                    menuProps={{ items: moreMenuItems, className: 'section-menu' }} />
            </>}
        />
        {
            isRemoveDialogOpen &&
                <RemoveDialog
                    onClose={() => setIsRemoveDialogOpen(false)}
                    onComplete={() => { actions.removeProcess(); }}
                    dialogContentProps={{
                        title: "Delete process",
                        subText: `Are you sure you want to delete process "${entity.attributes.Name}" ?`
                    }}
                    confirmButtonProps={{ text: "Delete" }}
                />
        }
    </>;
}

export default ProcessHeader;