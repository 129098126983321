import * as React from 'react';
import { ItemCreation, ICommand, IHeader } from "./ItemCreation";
import { Validator } from "../../validation";
import { EntityType, entityTypeLabelMap } from '../../entities/common';

const validators = {
    name: Validator.new().required().build()
};

type Props = {
    entityType: EntityType;
    entityTypeLabel?: string;
    onSave: (name: string) => void;
    onDismiss: () => void;
    header?: Partial<IHeader>;
    children?: (updateIsDirty: (isDirty: boolean) => void) => React.ReactNode;
};

const ListEntityWithNameCreation = (props: Props) => {
    const [name, setName] = React.useState('');
    const [isDirty, setIsDirty] = React.useState(false);
    
    const entityTypeLabel = props.entityTypeLabel ?? entityTypeLabelMap[props.entityType].singular;

    const _onSave = React.useCallback(() => {
        const trimmed = name && name.trim();
        if (trimmed) {
            props.onSave(trimmed);
            props.onDismiss();
        }
    }, [props.onSave, props.onDismiss, name]);

    const _getCommands = React.useCallback((): ICommand[] => {
        return [
            { primary: true, text: `Create ${entityTypeLabel}`, onClick: _onSave, disabled: !validators.name.isValid(name) },
            { text: "Cancel", onClick: props.onDismiss }
        ];
    }, [entityTypeLabel, _onSave, name, props.onDismiss]);

    return (
        <ItemCreation
            onDismiss={props.onDismiss}
            isDirty={isDirty}
            commands={_getCommands()}
            header={{
                text: `Create ${entityTypeLabel}`,
                secondaryText: `Fill in the fields below to create new ${entityTypeLabel}`,
                nameEditorLabel: `${entityTypeLabel} Name`,
                onChanged: (newValue: string) => {
                    setIsDirty(true);
                    setName(newValue);
                },
                validator: validators.name,
                ...props.header
            }}>
            {props.children?.(setIsDirty)}
        </ItemCreation>
    );
}

export default ListEntityWithNameCreation;