import { Dictionary, IBaseEntity, OriginScale } from "./common";
import { SourceType } from "../store/ExternalEpmConnectStore";
import { toDictionaryByName } from '../components/utils/common';

export const NEW_ID = "new";
export const DEFAULT_ID = "default";

export interface ISectionInfo {
    id: string;
    name: string;
    label?: string;
    description?: string;
    isSelected: boolean;
    isOpen: boolean;
    isAccessible: boolean;
    settings: Dictionary<any>;
}

export interface FieldInfo {
    label: string;
    name: string;
    description?: string;
    type: FieldType;
    group: FieldGroup;
    settings?: Dictionary<any>;
    isNative: boolean;
    isCustom: boolean;
    isReadonly: boolean;
    isSystem: boolean;
    defaultValue?: any;
    isFake?: boolean;
}

export interface FieldSettings {
    required?: boolean;
    multiline?: boolean;
    multichoice?: boolean;
}

export interface Field extends FieldInfo {
    id: string;
}

export interface CalculatedField extends Field {
    calculate: any;
}

export function getLabel(obj: { label?: string; name: string; }): string {
    return obj.label ?? obj.name;
}

export function isRequired(field: FieldInfo): boolean {
    if (field.isSystem) {
        return true;
    }

    if (field.settings && field.settings.required === true) {
        return true;
    }

    return false;
}

export function toMap(fields: Field[], isFake?: (field: Field) => boolean): Dictionary<Field> {
    const dictionary = new Dictionary<Field>();
    if (isFake) {
        fields.forEach(_ => {
            if (isFake(_) && !_.name) {
                const tmpName = _.id;
                dictionary[tmpName] = _;
                _.name = tmpName;
            } else {
                dictionary[_.name] = _;
            }
        });
        return dictionary;
    }

    return toDictionaryByName(fields);
}

export function findMatchingFieldName(fields: Field[], field: Field, isFieldFake: (field: Field) => boolean): string | undefined {
    return fields.find(_ => getName(_, isFieldFake) === getName(field, isFieldFake))?.name;
}

function getName(field: Field, isFieldFake: (field: Field) => boolean): string {
    return isFieldFake(field) ? getLabel(field) : field.name;
}

export interface Layout {
    id: string;
    name: string;
    isDefault: boolean;
    isView: boolean;
    sections: Section[];
    readonly?: boolean;
}

export const LayoutBuilder = {
    empty(isView: boolean, name?: string): Layout {
        return { id: NEW_ID, name: name || 'Layout', isDefault: false, isView, sections: [] };
    }
}

export interface Section extends ISectionInfo {
    uiControls: UIControl[];
}

export interface UIControl {
    id: string;
    type: string;
    settings: any;
}

export enum FieldType {
    Text = 0,
    Integer = 1,
    Decimal = 2,
    Flag = 3,
    Date = 4,
    Resource = 5,
    Portfolio = 6,
    User = 7,
    Project = 8,
    Challenge = 9,
    Idea = 10,
    Group = 11,
    Task = 12,
    Objective = 13,
    DateTime = 14,
    Program = 15,
    Ref = 16,
    Hyperlink = 17,
    Predecessor = 18,
    ProcessStage = 19,
    Layout = 21,
    StageType = 22
}

export enum FieldGroup {
    SystemFields = 0,
    CustomFields = 1,
    Details = 2,
    Timeline = 3,
    BudgetsAndBenefits = 4,
    Prioritization = 5,
    ResourcePlanning = 6,
    Statuses = 7,
    ScheduleSummary = 8,
    PPMXTasks = 9,
    VSTS = 10,
    Jira = 11,
    O365Planner = 12,
    Spo = 13,
    MondayCom = 14,
    Smartsheet = 15,
    MPPFile = 16,
    P4W = 17,
}

export const fieldTypeMap: { [i: number]: string } = {
    [FieldType.Text]: "Text",
    [FieldType.Integer]: "Integer",
    [FieldType.Decimal]: "Decimal",
    [FieldType.Flag]: "Flag",
    [FieldType.Date]: "Date",
    [FieldType.DateTime]: "DateTime",
    [FieldType.Resource]: "Resource",
    [FieldType.Portfolio]: "Portfolio",
    [FieldType.User]: "User",
    [FieldType.Project]: "Project",
    [FieldType.Challenge]: "Challenge",
    [FieldType.Idea]: "Idea",
    [FieldType.Group]: "Group",
    [FieldType.Task]: "Task",
    [FieldType.Program]: "Program",
    [FieldType.Hyperlink]: "Hyperlink",
    [FieldType.Predecessor]: "Predecessor"
}

export interface BaseFilterValue {
    attributes?: { [k: string]: any },
    calculation?: { [k: string]: any },
    [k: string]: any,
}

export interface IFilter<T extends BaseFilterValue = BaseFilterValue> extends ISelectableItem {
    id: string;
    name: string;
    value: T;
    attributeNames: { name: string, type: keyof T }[];
    readonly isBuiltIn: boolean;
    readonly isDefault: boolean;
    type?: SourceType;
}

export interface ISelectableItem {
    id: string;
    name: string;
    isBuiltIn: boolean;
    isNotSaved: boolean;
    isPublic?: boolean;
}

export interface FilterAttribute<T extends BaseFilterValue> {
    type: Extract<keyof T, string>,
    value: any,
    name: string,
    displayName: string,
}

export class Filter {
    public static empty(name?: string): IFilter<BaseFilterValue> {
        return { id: NEW_ID, name: name || 'Filter', value: {}, attributeNames: [], isBuiltIn: false, isDefault: false, isNotSaved: true, isPublic: false };
    }
    public static copy<T extends BaseFilterValue>(filter: IFilter<T>): IFilter<T> {
        return {
            ...filter,
            isPublic: false,
            isBuiltIn: false,
            isDefault: false,
            isNotSaved: true,
            name: generateCopiedName(filter.name),
            id: NEW_ID
        }
    }

    public static getAutoFilterId(filters: IFilter<BaseFilterValue>[]): string | undefined {
        return Filter.getAutoFilter(filters)?.id;
    }
    
    public static getAutoFilter(filters: IFilter<BaseFilterValue>[]): IFilter<BaseFilterValue> | undefined {
        if (!filters.length) {
            return undefined;
        }

        const defaultFilter = filters.find(_ => _.isDefault);
        return (defaultFilter || filters[0]);
    }
}

export interface IEntityFilterHelper<T> {
    helpersMap: { [K: string]: IFilterHelper<BaseFilterValue, T> },
    getFilterAttributes: (fields: Field[], nonFilterableFields?: string[]) => FilterAttribute<BaseFilterValue>[],
    newFilter: (name: string, sourceType?: SourceType) => IActiveFilter
}

export interface IFilterHelper<TFIlterValue extends BaseFilterValue, TItem> {
    buildFilterElement: (attr: FilterAttribute<TFIlterValue>,
        filter: IFilter<TFIlterValue>,
        onFilterEditComplete: (type: number | string, name: string, value: any) => void) => JSX.Element | null,
    removeFilterAttribute: (attrName: string, typeValue: any) => any,
    setAttributeValue: (attrName: string, value: any, oldValue: any) => any,
    getAttributeValues: (value: any) => string[];
    validateItem: (item: TItem, filterValue: any, attributes: any[]) => boolean;
}

export interface IConnectionInfo {
    id: string;
    title: string;
    name: string;
    type?: string;
}

export enum FormatType {
    Cost = 0,
    Duration = 1,
    Percent = 2,
    Days = 3
}

export enum ViewTypes {
    card = "card",
    list = "list",
    timeline = "timeline"
}

export interface IServerSubView {
    id: string;
    name: string;
    isBuiltIn: boolean;
    isPublic: boolean;
    type?: SourceType;
    data: Dictionary<any>;
}

export interface IServerViews {
    card: {
        data: CardViewSettings;
    }
    list: {
        data: Dictionary<any> & ListViewSettings;
        subViews: IServerSubView[];
    },
    timeline: {
        data: Dictionary<any> & TimelineViewSettings;
        subViews: IServerSubView[];
    }
}

export interface IFieldColumn extends IBaseEntity {
    name?: string;
    fieldName?: string;
    componentPath?: string;
    minWidth?: number;
    maxWidth?: number;
    isResizable: boolean;
    isMultiline: boolean;
    iconName?: string;
}

export interface ISubView extends IBaseEntity, ISelectableItem { }

export interface IListSubView extends ISubView {
    columns: ISubViewColumn[];
    type?: SourceType;
}

export interface ISubViewColumn extends IBaseEntity {
    width?: number;
}

export interface ICardSubView extends ISubView {
    showCosts: boolean;
    showWork?: boolean;
}

export interface ITimelineSubView extends IListSubView {
}

export const viewsMappings = {
    toSubView: (_: IServerSubView): IListSubView => {
        return {
            id: _.id,
            isBuiltIn: _.isBuiltIn,
            name: _.name,
            columns: _.data.columns,
            type: _.type,
            isNotSaved: false,
            isPublic: _.isPublic
        };
    },
    toServerSubView: (subView: IListSubView) => {
        return {
            id: subView.id && subView.id !== NEW_ID ? subView.id : undefined,
            isPublic: subView.isPublic,
            name: subView.name,
            type: subView.type,
            data: {
                columns: subView.columns.map(_ => ({ id: _.id }))
            }
        };
    }
}

export const SubView = {
    empty(name?: string, sourceType?: SourceType): IListSubView {
        return {
            id: NEW_ID,
            name: name || 'New view',
            isBuiltIn: false,
            isNotSaved: true,
            isPublic: false,
            type: sourceType,
            columns: [] as IBaseEntity[]
        };
    },
    copy(subView: IListSubView): IListSubView {
        return {
            ...subView,
            isPublic: false,
            name: generateCopiedName(subView.name),
            id: NEW_ID
        }
    }
}

export function generateCopiedName(name: string): string {
    const matches = name.match(/(.+)\ \((\d+)\)$/);
    let index = 1;
    if (matches) {
        name = matches[1];
        index = parseInt(matches[2]) + 1;
    }
    return `${name} (${index})`;
}

export interface IUpdateLayoutInfo {
    name?: string;
    isView?: boolean;
    isDefault?: boolean;
    sections?: Dictionary<IUpdateSectionInfo>;
}
export interface IUpdateSectionInfo {
    isSelected?: boolean;
    index?: number;
    label?: string;
    isOpen?: boolean;
    description?: string;
}

export interface IUpdateFieldInfo {
    label: string;
    description?: string;
    settings: Dictionary<any>;

    optionsUpdate?: OptionUpdate[];
}

export interface ICreateFieldInfo {
    label: string;
    name: string;
    description?: string;
    type: FieldType;
    settings?: Dictionary<any>;
    defaultValue?: any;
}

export enum Operation {
    Replace = 1,
    Remove = 2,
    Add = 3,
    Move = 4
}

export type Option = { name: string; color?: string; };
export const ToOption = (_: string): Option => ({name: _});

export type OptionUpdate<T extends Option = Option> = {
    operation: Operation;
    path?: string;
    value?: T;
    index?: number;
}

export function isFullEntityRefreshRequired(update: IUpdateFieldInfo): boolean {
    const selectOrStatus = !!update.optionsUpdate && update.optionsUpdate.length > 0;
    const slider = update.settings.minValue !== undefined || update.settings.maxValue !== undefined;
    return selectOrStatus || slider;
}

export type PreFilterOption<T> = {
    key: string;
    name: string;
    predicate: (entity: T) => boolean;
}

export type PreFilter<T> = {
    active?: PreFilterOption<T>;
    items: PreFilterOption<T>[];
    byId?: Dictionary<PreFilterOption<T>>;
    onChange: (filter?: PreFilterOption<T>) => void;
}

export namespace PreFilter {
    export function create<T>(options: PreFilterOption<T>[], onChange: (filter?: PreFilterOption<T>) => void): PreFilter<T> {
        return {
            items: options,
            onChange,
            byId: options.reduce((p, c) => ({ ...p, [c.key]: c }), new Dictionary<PreFilterOption<T>>())
        }
    }

    export function isItemVisible<T>(preFilter: PreFilter<T>, item: T, preFilterId?: string,) {
        const filter = get(preFilter, preFilterId);
        return filter && !filter.predicate(item);
    }

    export function get<T>(preFilter?: PreFilter<T>, preFilterId?: string): PreFilterOption<T> | undefined {
        if (!preFilter) {
            return undefined;
        }

        return preFilterId
            ? (
                preFilter.byId
                    ? preFilter.byId[preFilterId]
                    : preFilter.items.find(_ => _.key == preFilterId))
            : undefined;
    }
}

export interface IBaseGroupInfo {
    name: string;
    color: string;
}

export interface IGroupInfo extends IBaseGroupInfo {
    id: string
}

export interface Group extends IGroupInfo {
    isShown: boolean;
    isDefault: boolean;
}

export enum KeyDateFilters {
    allKeydatesFilterId = "20cf51db-e34c-4faa-8b49-45c25d915a8b"
}

export enum RiskFilters {
    activeRisksFilterId = "7404fa00-27b7-46c3-ab1c-96e7727e6c5b"
}

export enum IssueFilters {
    activeIssuesFilterId = "c1567406-0de3-466b-9913-e21ef6e7ee60"
}

export enum ChangeRequest {
    pendingChangeRequestsItemsFilterId = "1fa18559-2434-40ba-8ec4-0ea9af485bb5"
}

export namespace TaskFilters {
    export enum Ppmx {
        allTaskFilterId = "bac74312-f9eb-42b1-8da5-23e2093e742b",
        notStartedTaskFilterId = "30146e9c-a580-46d8-84c9-f1bd5b1ce67b",
        inprogressTaskFilterId = "01e7be66-1a2c-4ed8-bff6-2ed34a7b7a18",
        completedTaskFilterId = "cac5c2d4-5db5-4d4d-bb3c-e8efe7c76064",
        naStatusTaskFilterId = "563992f6-4118-4882-b80b-c6f39f7d18b6",
        greenStatusTaskFilterId = "2f678c10-d83d-43f2-afaf-8ab330b8bc68",
        amberStatusTaskFilterId = "b5c7d024-9d30-4fe5-9457-a641415af663",
        redStatusTaskFilterId = "a24f99cb-9285-4811-8665-d4229416beb2"
    }

    export enum Planner {
        allTaskFilterId = "1fa07bcc-bf4d-451e-801a-1e0ba3142953",
        notStartedTaskFilterId = "9d3824bc-bbdb-48e3-a31e-f1415175356c",
        inprogressTaskFilterId = "d5bc86a8-1fde-4185-a7cb-cd8040565370",
        completedTaskFilterId = "2c3ee29d-980d-4e4c-8166-3554bb69bf9a",
        naStatusTaskFilterId = "f6e71a08-fe6e-45cb-abea-94086f9e3b14",
        greenStatusTaskFilterId = "c1631507-bbe7-4f4e-bc01-16eb75d3246d",
        amberStatusTaskFilterId = "91413045-6b2c-479c-b8d3-02e373f2f2bb",
        redStatusTaskFilterId = "c5be7a21-a54d-4046-a221-52af94c0b750"
    }

    export enum Spo {
        naStatusFilterId = "9d0a0cd6-4095-46d3-8fbd-5c5797292518",
        greenStatusFilterId = "cd398d11-6b8d-49b4-b2c8-f1e03dc10381",
        amberStatusFilterId = "716dbf69-55a2-414b-87c3-7df3acdbeaf2",
        redStatusFilterId = "1ee162fd-cf1e-42ca-abf5-7156d9f32f3c",
        allTasksFilterId = "05df8ebb-e7f8-4daf-bacf-30356b84c839",
        notStartedTasksFilterId = "ea826b8f-65a7-417d-b899-bd56dfbaa92d",
        inProgressTasksFilterId = "84b9d85d-9034-4dc5-b5f4-29004e8d5ad2",
        completedTasksFilterId = "b271e318-32c9-42ca-b91f-6345ab13a15c",
        allMilestonesFilterId = "87dd14b8-b42c-4fbe-8517-6747867a06d2",
        outlineFilterId = "c3b6b2f0-f8a0-42ea-8525-59ddbe5faffb"
    }

    export enum P4W {
        naStatusFilterId = "3bb55690-3cc3-42bc-8e14-c7f0f10d4c53",
        greenStatusFilterId = "edabf242-6344-40a8-ac0e-dd1835dab029",
        amberStatusFilterId = "b5087910-18e2-48f6-86e8-c8740610ef7b",
        redStatusFilterId = "58e68794-bbc3-4b0e-950c-d9fd1444d16a",
        allTasksFilterId = "2dfaf82f-dc11-4210-b713-f4f8cfee2500",
        notStartedTasksFilterId = "7f482be6-b0b7-4479-8d6b-4e03c0024ed6",
        inProgressTasksFilterId = "0fa8e261-1039-423d-9a2d-33c5f8ee9abe",
        completedTasksFilterId = "b707d01a-f918-403c-8436-0e2d25634581",
        allMilestonesFilterId = "fb1104b4-75cc-4308-b2af-87d79075a7d3",
        outlineFilterId = "8c133dc1-2e5c-4f93-a73f-19a47f17df5c"
    }

    export enum VSTS {
        topLevelItemsFilterId = "f9876824-256d-4e62-b9dd-7c109377c0c5",
        allTasksFilterId = "07ace7ee-bf2d-4d54-bac1-1ac8d5dba09d",
        allBugsFilterId = "3ddeac96-a55a-4136-b30f-5f32db902fa8",
        allEpicsFilterId = "3c7ec778-0f84-4c47-b4d8-0a0eb972b387",
        allFeaturesFilterId = "69702778-0b96-4673-8ecc-63c5d9457ced",
        allUserStoriesFilterId = "e9d2d362-8b50-47d2-89ce-2457871ab726",
        openTasksFilterId = "ccfdc773-659a-42f6-9c1e-2ba7f6b2e8ad",
        openBugsFilterId = "b6ceef6d-0f64-4830-8425-be0759356933",
        openEpicsFilterId = "cb86a0bd-1a03-4e5c-8395-868eeb489c51",
        openFeaturesFilterId = "11a7f5ba-c583-46ed-aaaa-baca04e235c2",
        openUserStoriesFilterId = "292c0d6c-7bfd-4463-b40c-b783fe529b30"
    }

    export enum Jira {
        allFilterId = "82007038-7485-48a0-826f-75c3b79a172a",
        allTasksFilterId = "18485b3e-0f88-4de2-87a7-96d01085052b",
        allBugsFilterId = "3b43dec2-3277-46f9-951b-45a272c75fa3",
        allEpicsFilterId = "0825ea5c-b0f4-4a2f-91de-df5ef581481b",
        allFeaturesFilterId = "572ca2a0-1d91-4d37-991b-86a98bb9655e",
        allUserStoriesFilterId = "797f8c3e-28b4-435a-9c20-5f88eb9da149",
        openEpicsFilterId = "644eac42-dee7-4fcb-b2f4-00396dcbc4d1",
        openFeaturesFilterId = "437d0afd-fc88-47ce-aac1-d059175b560f",
        openStoriesFilterId = "e84555f4-e089-4a15-a4a1-5e1e0a1eb321",
        openTasksFilterId = "1cc88a03-b908-4abd-b58c-84cc6d6bbef2",
        openBugsFilterId = "837b1f96-1c59-49bc-a6ad-a5186b8fcb56"
    }

    export enum MondayCom {
        topLevelItemsFilterId = "24c7cc26-687d-4fe7-a9f5-1ebd4fb34a47",
        notStartedTaskFilterId = "98fe21b5-fa11-4f1e-bf8a-cf48dd78ec4b",
        inprogressTaskFilterId = "febf609b-6585-4672-8e87-3582455a5113",
        completedTaskFilterId = "3e958585-da2a-4424-a4ff-676b944f891a",
        naStatusTaskFilterId = "72b3d4fc-2f24-4fd4-8058-cf5b41e5b5d1",
        greenStatusTaskFilterId = "59fa0cf1-fe1c-45d9-a270-4782ad98b34a",
        amberStatusTaskFilterId = "b81ffa2a-fa98-4d66-b40a-dccce2fb9f42",
        redStatusTaskFilterId = "3c5c463c-ea1a-416e-b140-e41c2730117d"
    }

    export enum Smartsheet {
        outlineFilterId = "d926541f-0e82-45cd-af28-6edfc148da79",
        notStartedTaskFilterId = "920b470b-9383-41dc-9178-2446e97d0625",
        inprogressTaskFilterId = "97135ea9-1d0d-4329-b3c7-0213fb38ae30",
        completedTaskFilterId = "93f090f3-805b-41ee-aee5-86548ab59564",
        naStatusTaskFilterId = "512a7a75-4fa4-4b6a-b0a7-ebcd94b5e9dc",
        greenStatusTaskFilterId = "dcb36ad5-dd4c-4c5e-9d8c-6aadc641ee68",
        amberStatusTaskFilterId = "1215ed2c-d24c-48f1-8e78-05c10f67e71b",
        redStatusTaskFilterId = "eb6ad25b-9405-4703-97d6-240b32f831de"
    }

    export enum MPPFile {
        naStatusFilterId = "a9b4fd4c-7376-49a3-aebd-43a9ddb5e8da",
        greenStatusFilterId = "18031792-53b7-48c7-b4d1-ed7b8a5325b3",
        amberStatusFilterId = "110aaf08-125c-4b4b-a977-626d9119ad7e",
        redStatusFilterId = "80fdd202-0a22-4415-b739-4f9e44293af1",
        allTasksFilterId = "4fbf67cb-c777-4f4c-8fee-980c4e61e852",
        notStartedTasksFilterId = "16aada51-c661-4a09-acc7-90f34ba0add1",
        inProgressTasksFilterId = "b9f82ef5-e37e-464d-a06f-44fe48ad73e6",
        completedTasksFilterId = "d3e78848-c366-4bfd-b4e3-cef0ae8cc1f4",
        allMilestonesFilterId = "e555ff03-ec2c-4469-822e-ae0a854fd922",
        outlineFilterId = "bd73bd16-38e3-4fad-93d8-f7b99abf44ac"
    }
}

export enum TaskPrefilterKey {
    today = 'today',
    thisweek = 'thisweek',
    late = 'late',
    slipped = 'slipped',
    nodates = 'nodates',
    currentIteration = 'currentIteration',
    noassignments = 'noassignments',
}

export enum IssuePrefilterKey {
    late = 'late'
}

export enum RiskPrefilterKey {
    late = 'late'
}

export enum KeyDatePrefilterKeys {
    late = 'late',
    slipped = 'slipped'
}

export enum DeliverablePrefilterKey {
    late = 'late'
}

export enum IterationPrefilterKey {
    late = 'late'
}

export enum RoadmapItemPrefilterKey {
    withWarnings = 'withWarnings'
}

export interface IActiveFilter { build: () => IFilter<BaseFilterValue> }

export enum SortDirection {
    ASC = 0,
    DESC = 1
}

type ViewSettings = {
    activeSubViewId?: string;
}

export type CardViewSettings = {
    sortById?: string;
} & ViewSettings;

export type ListViewSettings = {
    sortBy: {
        fieldName: string;
        direction: SortDirection;
    },
    subViews: Dictionary<Dictionary<{ width: number }>>;
} & ViewSettings;

export type TimelineViewSettings = {
    scale: OriginScale;
} & ListViewSettings;

export interface IWithSections {
    sections: Section[];
}

export interface IWithPinnedViews {
    pinnedViews: string[];
}

export const getOption = (field: Field, name?: string) => (name || name === '0')
    ? getOptions(field).find(_ => _.key == name)
    : undefined;

export const getOptions = (field: Field) => {
    if (!field.settings?.options) {
        return [];
    }

    return normalizeOptions(field.settings?.options);
}

export const normalizeOptions = (options: any): { key: string | number, text: string, color?: string }[] => {
    if (!options) {
        return [];
    }
    if (Array.isArray(options)) {
        return options.map(_ => ({ key: _.name, text: _.name, color: _.color }));
    }

    const keys = Object.keys(options);
    return keys.map(_ => ({ key: !isNaN(Number(_)) ? Number(_) : _, text: options[_] }));
}

export const searchPathMap = {
    [FieldType.Resource]: 'resource',
    [FieldType.User]: 'user',
    [FieldType.Portfolio]: 'portfolio',
    [FieldType.Project]: 'project',
    [FieldType.Challenge]: 'challenge',
    [FieldType.Idea]: 'idea',
    [FieldType.Objective]: 'objective',
    [FieldType.Program]: 'program'
};
