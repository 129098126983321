import * as React from 'react';
import { IContextualMenuProps, IContextualMenuItem, CommandBar, ICommandBarItemProps, DefaultButton } from 'office-ui-fabric-react';
import ProjectCreation from '../project/ProjectCreation';
import ProgramCreation from '../program/ProgramCreation';
import PortfolioCreation from '../portfolio/PortfolioCreation';
import ResourceCreation from '../resource/ResourceCreation';
import ChallengeCreation from '../challenge/ChallengeCreation';
import ObjectiveCreation from '../objective/ObjectiveCreation';
import { connect } from "react-redux";
import { ApplicationState } from "../../store/index";
import { actionCreators, UserState } from "../../store/User";
import { canCreate, contains, CommonOperations, LicenseType } from "../../store/permissions";
import { EntityType } from '../../entities/common';
import RoadmapCreation from '../roadmap/RoadmapCreation';
import { PPMFeatures, Subscription } from '../../store/Tenant';
import { notUndefined } from '../utils/common';

type State = {
    entityTypeToCreate?: EntityType;
}

type StateProps = {
    user: UserState;
    subscription: Subscription
};

type OwnProps = {
    entityTypes: EntityType[];
    currentEntityType: EntityType;
};

type Props = StateProps & typeof actionCreators & OwnProps;

class NewEntityHeaderButton extends React.Component<Props, State>{
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    public render() {
        const menuProps = this._getNewButtonOptions();
        if (!menuProps.items.length) return null;

        const { entityTypeToCreate } = this.state;
        const { currentEntityType, subscription } = this.props;
        const newButtonCommandItem = this._getNewButtonCommandItem(currentEntityType);
        const dropdowButtonCommandItem = this._getDropdownButtonMenuItem(menuProps, currentEntityType);

        return [
            <CommandBar
                key="new-entity-header-button"
                className='align-center new-entity-header-button'
                items={[newButtonCommandItem]}
                farItems={[dropdowButtonCommandItem].filter(notUndefined)}
                styles={{ root: { height: '40px', padding: 0 }, primarySet: { width: '100%' } }}
            />,
            entityTypeToCreate === EntityType.Portfolio && <PortfolioCreation key="portfolio-creation" onDismiss={this._dismissCreation} openOnComplete />,
            entityTypeToCreate === EntityType.Program && <ProgramCreation key="program-creation" onDismiss={this._dismissCreation} openOnComplete />,
            entityTypeToCreate === EntityType.Project && <ProjectCreation key="project-creation" onDismiss={this._dismissCreation} openOnComplete
                showConfigureConnectionsOption={Subscription.hasSyncableIntegration(subscription)} />,
            entityTypeToCreate === EntityType.Resource && <ResourceCreation key="resource-creation" onDismiss={this._dismissCreation} openOnComplete />,
            entityTypeToCreate === EntityType.Challenge && <ChallengeCreation key="challenge-creation" onDismiss={this._dismissCreation} openOnComplete />,
            entityTypeToCreate === EntityType.Objective && <ObjectiveCreation key="objective-creation" onDismiss={this._dismissCreation} />,
            entityTypeToCreate === EntityType.Roadmap && <RoadmapCreation key="roadmap-creation" onDismiss={this._dismissCreation} openOnComplete />,
            entityTypeToCreate === EntityType.PrivateProject && <ProjectCreation key="private-project-creation" onDismiss={this._dismissCreation} openOnComplete isPrivateProject
                showConfigureConnectionsOption={Subscription.hasSyncableIntegration(subscription)} />
        ];
    }
    private _dismissCreation = () => {
        this.setState({ entityTypeToCreate: undefined });
    }

    private _getNewButtonCommandItem = (entityType: EntityType): ICommandBarItemProps => {
        const hasPermissons = this._hasPermissions(entityType);
        return {
            key: "new-header-button",
            text: "New",
            title: "Create new item",
            buttonStyles: {
                root: {
                    minWidth: 70,
                    width: '100%',
                    display: 'flex'
                },
            },
            iconProps: { iconName: "Add" },
            onClick: () => this._openCreateEntityPopup(entityType),
            disabled: !hasPermissons
        };
    }

    private _getDropdownButtonMenuItem = (menuProps: IContextualMenuProps, entityType: EntityType): ICommandBarItemProps | undefined => {
        return entityType !== EntityType.Objective && entityType !== EntityType.Challenge && menuProps.items.length > 1
            ? {
                key: "new-header-dropdown",
                subMenuProps: menuProps,
                className: "new-header-dropdown",
                buttonStyles: {
                    root: {
                        minWidth: "none",
                    },
                    menuIcon: {
                        backgroundColor: "transparent !important"
                    }
                },
                disabled: this.props.user.license !== LicenseType.Regular
            } : undefined;
    }

    private _hasPermissions(entityType: string): boolean {
        let hasPermissons: boolean;
        switch (entityType) {
            case EntityType.Portfolio:
                hasPermissons = canCreate(this.props.user.permissions.portfolio);
                break;
            case EntityType.Program:
                hasPermissons = canCreate(this.props.user.permissions.program);
                break;
            case EntityType.Project:
            case EntityType.PrivateProject:
                hasPermissons = canCreate(this.props.user.permissions.project);
                break;
            case EntityType.Roadmap:
                hasPermissons = canCreate(this.props.user.permissions.roadmap);
                break;
            case EntityType.Challenge:
                hasPermissons = canCreate(this.props.user.permissions.challenge);
                break;
            case EntityType.Objective:
                hasPermissons = canCreate(this.props.user.permissions.objective);
                break;
            default:
                hasPermissons = true;
        }

        return hasPermissons;
    }

    private _getNewButtonOptions(): IContextualMenuProps {
        const { user, entityTypes, subscription } = this.props;
        const permissions = user.permissions;
        const items: IContextualMenuItem[] = [];
        const hasRoadmap = Subscription.contains(subscription, PPMFeatures.Roadmap);
        const hasIdeation = Subscription.contains(subscription, PPMFeatures.Ideation);

        if (entityTypes.includes(EntityType.Portfolio)) {
            items.push({
                key: 'create-portfolio',
                name: 'Portfolio',
                iconProps: { iconName: 'PPMXPortfolio' },
                disabled: !canCreate(permissions.portfolio),
                onClick: (x: any) => this._openCreateEntityPopup(EntityType.Portfolio)
            });
        }

        if (entityTypes.includes(EntityType.Program)) {
            items.push({
                key: 'create-program',
                name: 'Program',
                iconProps: { iconName: 'PPMXProgram' },
                disabled: !canCreate(permissions.program),
                onClick: (x: any) => this._openCreateEntityPopup(EntityType.Program)
            });
        }

        if (entityTypes.includes(EntityType.Project)) {
            items.push({
                key: 'create-project',
                name: 'Project',
                iconProps: { iconName: 'PPMXProject' },
                disabled: !canCreate(permissions.project),
                onClick: (x: any) => this._openCreateEntityPopup(EntityType.Project)
            });
        }

        if (entityTypes.includes(EntityType.PrivateProject)) {
            items.push({
                key: 'create-private-project',
                name: 'Private Project',
                iconProps: { iconName: 'Lock' },
                disabled: !canCreate(permissions.project),
                onClick: (x: any) => this._openCreateEntityPopup(EntityType.PrivateProject)
            });
        }

        if (hasRoadmap && entityTypes.includes(EntityType.Roadmap)) {
            items.push({
                key: 'create-roadmap',
                name: 'Roadmap',
                iconProps: { iconName: 'PPMXRoadmap' },
                disabled: !canCreate(permissions.roadmap),
                onClick: (x: any) => this._openCreateEntityPopup(EntityType.Roadmap)
            });
        }

        if (entityTypes.includes(EntityType.Resource)) {
            items.push({
                key: 'create-resource',
                name: 'Resource',
                iconProps: { iconName: 'PPMXResource' },
                disabled: !contains(permissions.common, CommonOperations.ResourceManage),
                onClick: (x: any) => this._openCreateEntityPopup(EntityType.Resource)
            });
        }

        if (hasIdeation && entityTypes.includes(EntityType.Challenge)) {
            items.push({
                key: 'create-challenge',
                name: 'Challenge',
                iconProps: { iconName: 'PPMXBusinessIdeas' },
                disabled: !canCreate(permissions.challenge),
                onClick: (x: any) => this._openCreateEntityPopup(EntityType.Challenge)
            });
        }

        if (entityTypes.includes(EntityType.Objective)) {
            items.push({
                key: 'create-objective',
                name: 'Objective',
                iconProps: { iconName: 'PPMXStrategicPriority' },
                disabled: !canCreate(permissions.objective),
                onClick: (x: any) => this._openCreateEntityPopup(EntityType.Objective)
            });
        }

        return {
            items: items,
            gapSpace: 10,
            useTargetAsMinWidth: true,
            calloutProps: {
                className: "header-callout"
            }
        };
    }

    private _openCreateEntityPopup(entityType: EntityType) {
        this.setState({ entityTypeToCreate: entityType });
    }
}

function mapStateToProps(state: ApplicationState) {
    return {
        user: state.user,
        subscription: state.tenant.subscription
    };
}

export default connect(mapStateToProps, actionCreators)(NewEntityHeaderButton);