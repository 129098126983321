import * as React from 'react';
import { connect } from 'react-redux';
import './ProjectSettingsPanel.css'
import { PanelType, MessageBarType, MessageBar, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { ProjectInfo, ProjectSettings, ProcessInfo } from '../../../store/ProjectsListStore';
import { IInsightsData, PANEL_CUSTOM_WIDTH } from '../../../entities/common';
import { Insights, PPMFeatures, Subscription, TenantState } from '../../../store/Tenant';
import { ApplicationState } from '../../../store';
import ExpandablePanel from '../../common/ExpandablePanel';
import { UserState } from '../../../store/User';
import Collapsible from '../../common/Collapsible';
import { PrimaryScheduleSection } from './PrimaryScheduleSection';
import { AutoCalculationSection } from './AutoCalculationSection';
import { ProjectCalculationSection } from './ProjectCalculationSection';
import { NewPpmxTasksCalculationModeSection } from './NewPpmxTasksCalculationModeSection';
import { DirtyFormMessageBar } from '../../../components/common/DirtyFormMessageBar';
import TimeTrackingProjectSettingsSection from './TimeTrackingProjectSettingsSection';
import ProjectProcessSection from './ProjectProcessSection';
import { notUndefined } from '../../utils/common';

type OwnProps = {
    entity: ProjectInfo;
    readonlyMode?: boolean;
    onDismiss: () => void;
    onSave: (changes: Partial<IInsightsData & ProjectSettings>) => void;
}

type StateProps = {
    user: UserState;
    tenant: TenantState;
    tenantInsights: Insights;
}

type Props = OwnProps & StateProps;

const saveButtonText = "Save";
const cancelButtonText = "Cancel";

type ProjectSettingsState = ProjectSettings & {
    process?: ProcessInfo;
    apiBasedApprovalUrl?: string;
}

const ProjectSettingsPanel = (props: Props) => {
    const [projectInsights, setProjectInsights] = React.useState(props.entity.insights);
    const [projectSettings, setProjectSettings] = React.useState<ProjectSettingsState>({
        ...props.entity.settings, 
        process: props.entity.process, 
        apiBasedApprovalUrl: props.entity.apiBasedApprovalUrl
    });
    const [showHasChangesWarning, setShowHasChangesWarning] = React.useState(false);

    const readonly = !props.entity.isEditable || props.readonlyMode;

    const sectionsSettings: any[] = [
        {
            title: "Primary Schedule",
            key: "primary-schedule",
            iconName: "System",
            expanded: true,
            onRenderBody: () => <PrimaryScheduleSection
                readonly={readonly}
                sourceTypes={props.entity.sourceInfos.map(_ => _.type)}
                value={projectSettings.primarySchedule}
                onChange={(value) => setProjectSettings({ ...projectSettings, primarySchedule: value })} >
                <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    All Schedule rollup fields, such as Schedule Start Date, Schedule Due Date, Schedule Progress %, etc.,
                    will be automatically recalculated to display values rolled up from the selected schedule.
                </MessageBar>
            </PrimaryScheduleSection>
        },
        {
            title: "Calculated automatically",
            key: "calculate-automatically",
            iconName: "Processing",
            onRenderBody: () => <AutoCalculationSection
                projectInsights={projectInsights}
                readonly={readonly}
                onChange={(insights: Partial<IInsightsData>) => setProjectInsights({ ...projectInsights, ...insights })}
                tenantInsights={props.tenantInsights}/>
        },
        {
            title: "Progress Calculation for PPM Express Tasks",
            key: "progress-calculation",
            iconName: "CalculatorPercentage",
            onRenderBody: () => <ProjectCalculationSection
                value={projectSettings.progressCalculationType}
                readonly={readonly}
                onChange={(value) => setProjectSettings({ ...projectSettings, progressCalculationType: value })} >
                <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Rolled up Progress for task groups and project will be automatically re-calculated.
                    For Manual Tasks to re-calculate Progress use Calculate Progress feature.
                    For Auto Tasks Progress will be re-calculated automatically.
                </MessageBar>
            </ProjectCalculationSection>
        },
        {
            title: "Default Mode for New PPM Express Tasks",
            key: "progress-calculation-mode",
            iconName: "PPMXCalculateProgress",
            onRenderBody: () => <NewPpmxTasksCalculationModeSection
                value={projectSettings.isTaskAutoCalculationMode}
                readonly={readonly}
                onChange={(value) => setProjectSettings({ ...projectSettings, isTaskAutoCalculationMode: value })} />
        },
        props.tenant.timeTracking.globalSettings.newTimeTrackingEnabled && Subscription.contains(props.tenant.subscription, PPMFeatures.TimeTracking) 
            ? {
                title: "Time Tracking",
                key: "time-tracking",
                iconName: "Clock",
                onRenderBody: () => <TimeTrackingProjectSettingsSection
                    value={projectSettings.calculateCompletedWorkBasedOnReportedTime}
                    originalValue={props.entity.settings.calculateCompletedWorkBasedOnReportedTime}
                    readonly={readonly}
                    isPrivateProject={!!props.entity.isPrivate}
                    isGlobalSettingEnabled={props.tenant.timeTracking.globalSettings.calculateCompletedWorkBasedOnReportedTime}
                    onChange={(value) => setProjectSettings({ ...projectSettings, calculateCompletedWorkBasedOnReportedTime: value })} />
            }
            : undefined,
        {
            title: "Project Process",
            key: "project-process",
            iconName: "CRMProcesses",
            onRenderBody: () => <ProjectProcessSection
                value={props.entity}
                readonly={readonly}
                onChange={value => setProjectSettings({ ...projectSettings, ...value })} />
        }
    ].filter(notUndefined);

    const _isPrimaryScheduleChanged = () => props.entity.settings.primarySchedule !== projectSettings.primarySchedule;

    const _isCalculationTypeChanged = () => props.entity.settings.progressCalculationType !== projectSettings.progressCalculationType;
    const _isCalculationModeChanged = () => props.entity.settings.isTaskAutoCalculationMode !== projectSettings.isTaskAutoCalculationMode;
    const _isProcessChanged = () => (projectSettings.process && props.entity.process.id !== projectSettings.process?.id) 
        ||  props.entity.apiBasedApprovalUrl !== projectSettings.apiBasedApprovalUrl;

    const isCalculatedAutomaticalyChanged = () => {
        return props.entity.insights.keyDateStatusCalculationDisabled !== projectInsights.keyDateStatusCalculationDisabled
            || props.entity.insights.statusCalculationDisabled !== projectInsights.statusCalculationDisabled
            || props.entity.insights.taskStatusCalculationDisabled !== projectInsights.taskStatusCalculationDisabled
            || props.entity.insights.warningsCalculationDisabled !== projectInsights.warningsCalculationDisabled;
    }

    const _isTimeTrackingSettingChanged = () => props.entity.settings.calculateCompletedWorkBasedOnReportedTime !== projectSettings.calculateCompletedWorkBasedOnReportedTime;

    const _onSave = () => {
        props.onSave(getUpdates<IInsightsData & ProjectSettings>({ ...props.entity.insights, ...props.entity.settings }, { ...projectInsights, ...projectSettings }));
        props.onDismiss();
    }

    const isDataChanged =
        _isCalculationTypeChanged() ||
        _isCalculationModeChanged() ||
        isCalculatedAutomaticalyChanged() ||
        _isPrimaryScheduleChanged() ||
        _isTimeTrackingSettingChanged() ||
        _isProcessChanged();

    const _onDismiss = React.useCallback((ev: React.SyntheticEvent<HTMLElement>) => {
        if (isDataChanged && ev?.currentTarget?.classList?.contains("ms-Overlay")) {
            setShowHasChangesWarning(true);
            ev.preventDefault();
            return;
        }
        props.onDismiss();
    }, [isDataChanged, props.onDismiss, setShowHasChangesWarning]);

    return <ExpandablePanel
        className="project-settings-panel"
        isOpen
        type={PanelType.custom}
        customWidth={PANEL_CUSTOM_WIDTH}
        isLightDismiss
        onDismiss={_onDismiss}
        onRenderHeader={() => <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Project Settings</p>
            <div className='ms-Panel-secondaryText'>Select project settings for the current project.</div>
        </div>}
        onRenderFooterContent={() => <>
            {showHasChangesWarning && <DirtyFormMessageBar primaryCommandLabel={saveButtonText} cancelCommandLabel={cancelButtonText} />}
            <div className='commands'>
                <PrimaryButton
                    text={saveButtonText}
                    disabled={!isDataChanged}
                    onClick={_onSave} />
                <DefaultButton text={cancelButtonText} onClick={props.onDismiss} />
            </div>
        </>}
    >
        {sectionsSettings.map(section => <Collapsible {...section} >
            {section.onRenderBody()}
        </Collapsible>)}
    </ExpandablePanel>;
}

function mapStateToProps(state: ApplicationState): StateProps {
    return {
        user: state.user,
        tenant: state.tenant,
        tenantInsights: state.tenant.insights,
    }
}

export default connect(mapStateToProps)(ProjectSettingsPanel);

function getUpdates<T>(original: T, changed: T): Partial<T> {
    const result: Partial<T> = {};
    for (const p in changed) {
        if (original[p] !== changed[p]) {
            result[p] = changed[p];
        }
    }
    return result;
}