import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    PrimaryButton, MessageBar, MessageBarType, IDialogContentProps, IContextualMenuItem, DefaultButton,
    ActionButton, Selection, ICommandBarItemProps
} from "office-ui-fabric-react";
import { ApplicationState } from "../../store";
import { RouteComponentProps } from "react-router-dom";
import * as Metadata from "../../entities/Metadata";
import EntitiesScreenBuilder from "../common/EntitiesScreen"; 
import { Challenge, DEFAULT_BULK_EDIT_COLUMNS, ChallengeStage, actionCreators } from "../../store/ChallengesListStore";
import { IDeletionResult } from "../../store/services/storeHelper";
import * as Notifications from "../../store/NotificationsStore";
import * as LayoutsStore from "../../store/layouts";
import * as FiltersStore from "../../store/filters";
import * as ViewsStore from "../../store/views";
import { UserState } from "../../store/User";
import { contains, CommonOperations, canCreate, canUpdate } from "../../store/permissions";
import Spinner from "../common/Spinner";
import EmptyEntitiesScreen from "../common/EmptyEntitiesScreen";
import { Dictionary, EntityType, entityTypeLabelMap } from "../../entities/common";
import { Reporting, ReportsNav } from "../utils/reporting";
import RemoveDialog from "../common/RemoveDialog";
import { FilterHelper } from "../../store/challenge/filters";
import { SortService } from "../../services/SortService";
import EntitiesCardList from "../common/EntitiesCardList";
import { toDictionaryById } from "../utils/common";
import { IDetailsProps, IListProps } from "../common/extensibleEntity/EntityDetailsList";
import ListSubView from "../views/list/ListSubView";
import EditListSubView from "../views/list/EditListSubView";
import ChallengeCard from "../views/card/ChallengeCard";
import ChallengeCreation from "./ChallengeCreation";
import IdeaCreation from "../idea/IdeaCreation";
import { default as GenericEntitiesFilter } from "../common/EntitiesFilter";
import { LayoutService } from "../utils/LayoutService";
import { TenantState } from "../../store/Tenant";
import { Sorter } from "../utils/HierarchyManager";
import SharePanel from "../common/SharePanel";
import { RowMenu } from "../common/extensibleEntity/RowMenu";
import { RowMenuContainer } from "../common/extensibleEntity/RowMenuContainer";
import { IEntitiesScreenView, HeaderProps } from "../common/EntitiesScreenHeader";
import { MenuTitleBuilder } from "../MenuTitleBuilder";
import ApplyLayoutConfirmationDialog from "../common/ApplyLayoutConfirmationDialog";
import RowMenuWithExtensions from "../common/extensibleEntity/RowMenuWithExtensions";

type StateProps = {
    challenges: Challenge[];
    layouts: LayoutsStore.LayoutsState;
    fields: Metadata.Field[];
    filters: Metadata.IFilter<Metadata.BaseFilterValue>[];
    activeFilter?: Metadata.IFilter<Metadata.BaseFilterValue>;
    autoFilterId: string;
    views?: ViewsStore.IViewsState;
    deletionResult: IDeletionResult[] | undefined;
    isLoading: boolean;
    user: UserState;
    tenant: TenantState;
    challengesReports: ReportsNav;
    preFilterId?: string;
};

type ActionProps = {
    challengesActions: typeof actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
};
type Props = StateProps & ActionProps & RouteComponentProps<{}>;

type State = {
    isCreate: boolean;
    share?: Challenge;
    createIdeaForChallengeId?: string;
    challengesToRemove: Challenge[];
    layoutToApply?: Metadata.Layout;
    canEdit: boolean;
    canManageConfiguration: boolean;
    selectedItems: Challenge[];

    isListViewEdit: boolean;

    preFilter: Metadata.PreFilter<Challenge>;
    entityFilterHelper: Metadata.IEntityFilterHelper<Challenge>;
};

const EntitiesFilter = GenericEntitiesFilter<Challenge>();
const EntitiesScreen = EntitiesScreenBuilder<Challenge>();

class ChallengesList extends React.Component<Props, State> {
    private _selection: Selection;

    constructor(props: Props) {
        super(props);

        const preFilterOptions: Metadata.PreFilterOption<Challenge>[] = [
            { key: "only-my", name: "Only Mine", predicate: _ => !!_.attributes.Manager && !!_.attributes.Manager.find(m => m.id === props.user.id) }
        ];

        this.state = {
            isCreate: false,
            canEdit: this._canEdit(props),
            canManageConfiguration: this._canManageConfiguration(this.props),
            selectedItems: [],

            isListViewEdit: false,
            challengesToRemove: [],
            preFilter: Metadata.PreFilter.create(preFilterOptions,
                active => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id, active?.key)),
            entityFilterHelper: new FilterHelper({
                challenges: this.props.challenges,
                fields: this.props.fields,
                layouts: this.props.layouts.allIds.map(_ => this.props.layouts.byId[_])
            })
        };

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedItems: this._selection.getSelection() as Challenge[] });
            }
        });
    }

    componentWillMount() {
        this.props.challengesActions.loadChallenges();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.challenges !== nextProps.challenges || this.props.fields !== nextProps.fields) {
            const entityFilterHelper = new FilterHelper({
                challenges: this.props.challenges,
                fields: this.props.fields,
                layouts: nextProps.layouts.allIds.map(_ => nextProps.layouts.byId[_])
            });
            this.setState({ entityFilterHelper });
        }
    }

    private _canEdit(props: Props) {
        return canCreate(props.user.permissions.challenge) || canUpdate(props.user.permissions.challenge)
            || props.challenges.some(_ => _.isEditable);
    }

    private _canManageConfiguration(props: Props) {
        return contains(props.user.permissions.common, CommonOperations.ConfigurationManage);
    }

    private _viewChanged = (view: IEntitiesScreenView<Challenge>) => {
        this.setState({ selectedItems: [] });
        this.props.viewsActions.setActiveView(view.url);
    }
    
    private _clearPreFilter = () => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id);

    public render() {
        if (this.props.isLoading) {
            return <Spinner />;
        }

        const { deletionResult } = this.props;
        const notDeleted = deletionResult?.filter(_ => !_.isDeleted);
        const { challengesToRemove, createIdeaForChallengeId } = this.state;
        return <>
            {this.props.challenges.length === 0
                ? <EmptyEntitiesScreen
                    className="challenge"
                    title="challenges"
                    description="Identify challenges that your organization is facing and start collecting ideas, suggestions and solutions from your team to drive changes and innovation!">
                    <PrimaryButton disabled={!canCreate(this.props.user.permissions.challenge)} text="Create Challenge"
                        onClick={() => this.setState({ isCreate: true })} />
                </EmptyEntitiesScreen>
                : <EntitiesScreen
                    canManageConfiguration={this.state.canManageConfiguration}
                    clearPreFilter={this._clearPreFilter}
                    title="business challenges"
                    activeViewType={this.props.views!.activeViewType}
                    viewChanged={this._viewChanged}
                    fields={this.props.fields}
                    defaultBulkEditColumns={DEFAULT_BULK_EDIT_COLUMNS}
                    entities={this.applyFilter(this.props.challenges)}
                    entitiesIsUpdating={this.props.layouts.isApplyingLayout}
                    getBulkEditEntities={this.getBulkEditEntities}
                    actions={{
                        bulkComponent: {
                            bulkUpdate: this.gridBulkUpdate
                        }
                    }}
                    views={[this.getCardView(), this.getListView()]}
                    filter={{
                        activeFilter: this.props.activeFilter,
                        autoFilterId: this.props.autoFilterId,
                        getAttributeValue: this.getAttributeValue,
                        onFilterRender: this._renderFilter
                    }}
                    headerProps={this.getHeaderProps()}
                    router={{
                        history: this.props.history,
                        match: this.props.match,
                        location: this.props.location
                    }}
                    baseUrl="/challenges"
                    canEdit={this.state.canEdit}
                    selectedItemIds={this.state.selectedItems.map(_ => _.id)}
                />}
            {this.state.share && <SharePanel
                key="share-panel"
                entity={this.state.share}
                entityType={EntityType.Challenge}
                layouts={this.props.layouts}
                hideView
                onDismiss={() => this.setState({ share: undefined })} />}
            {this.state.isCreate && <ChallengeCreation onDismiss={() => this.setState({ isCreate: false })} openOnComplete />}
            {!!challengesToRemove.length && <RemoveDialog
                onClose={() => this.setState({ challengesToRemove: [] })}
                onComplete={() => {
                    this.props.challengesActions.removeChallenges(challengesToRemove.filter(_ => _.isEditable).map(_ => _.id));
                }}
                dialogContentProps={this._getRemoveDialogContent(challengesToRemove)}
                confirmButtonProps={{ text: "Delete", disabled: challengesToRemove.filter(_ => _.isEditable).length === 0 }} >
                {challengesToRemove.some(_ => !_.isEditable) && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    You don't have permissions to delete the following challenges:
                    <ul>
                        {challengesToRemove.filter(_ => !_.isEditable).map(_ => <li key={_.id}>{_.attributes.Name}</li>)}
                    </ul>
                </MessageBar>}
            </RemoveDialog>}
            {deletionResult && notDeleted && <RemoveDialog
                onClose={() => this.props.challengesActions.dismissDeletionResult()}
                confirmButtonProps={{ text: "Got it" }}
                modalProps={{ styles: { main: { minWidth: 500 } } }}
                dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                {deletionResult.length > 1 && notDeleted.length > 0 && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Failed to delete the following {notDeleted.length > 1 ? "challenges" : "challenge"}:
                    <ul>
                        {notDeleted.map(_ => <li key={_.id}>{_.name}</li>)}
                    </ul>
                    Business {notDeleted.length > 1 ? "Challenges" : "Challenge"} with submitted ideas cannot be removed. Please remove all related ideas first.
                </MessageBar>}
            </RemoveDialog>}
            {createIdeaForChallengeId && <IdeaCreation onDismiss={() => this.setState({ createIdeaForChallengeId: undefined })} challengeId={createIdeaForChallengeId} />}
            {this.state.layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => {
                    this.props.challengesActions.applyLayoutMany(this.state.selectedItems.map(_ => _.id), this.state.layoutToApply!.id);
                    this.props.notificationsActions.pushNotification({
                        message: `Layout '${this.state.layoutToApply!.name}' applied`,
                        type: Notifications.NotificationType.Info
                    });
                }}
                onDismiss={() => this.setState({ layoutToApply: undefined })}
                entityType={EntityType.Challenge}
                layoutName={this.state.layoutToApply!.name}
                count={this.state.selectedItems.length}
            />}
        </>;
    }

    private _getRemoveDialogContent(toRemove: Challenge[]) {
        toRemove = toRemove.filter(_ => _.isEditable);
        if (toRemove.length === 1) {
            return {
                title: "Delete challenge",
                subText: `Are you sure you want to delete challenge "${toRemove[0].attributes.Name}"?`
            }
        }

        return {
            title: "Delete challenges",
            subText: toRemove.length
                ? `Are you sure you want to delete selected challenges (${toRemove.length} items)?`
                : undefined
        }
    }

    private _getDeletionResultDialogContent(deletionResult: IDeletionResult[]): IDialogContentProps {
        if (deletionResult.length === 1 && !deletionResult[0].isDeleted) {
            return {
                title: "Unable to delete challenge",
                subText: `Business Challenge with submitted ideas cannot be removed. Please remove all related ideas first.`
            };
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return deleted.length === 1
            ? {
                title: "Challenge deletion is complete",
                subText: `Challenge "${deleted[0].name}" was deleted successfully.`
            }
            : deleted.length
                ? {
                    title: "Challenges deletion is complete",
                    subText: `Selected challenges (${deleted.length} items) were deleted successfully.`
                }
                : {
                    title: "Challenges deletion failed"
                };
    }

    private getBulkEditEntities = (): Challenge[] => {
        if (this.state.selectedItems.length > 0) {
            return this.state.selectedItems;
        }
        return this.applyFilter(this.props.challenges);
    }

    private getHeaderProps = (): HeaderProps => {
        const { challengesReports, history } = this.props;

        const reportItems: IContextualMenuItem[] = challengesReports.packs.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openReport(this.props.history, _);
                }
            }));

        const challengesItems: IContextualMenuItem[] = challengesReports.subPacks.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openChallengesReport(history, _, this.getBulkEditEntities());
                }
            }));

        return {
            entityType: EntityType.Challenge,
            createEntityTypes: [EntityType.Challenge],
            importEntityTypes: [],
            reportProps: {
                reportsButtonAdditionalOptions: Reporting.buildReportsList(challengesItems, reportItems)
            }
        }
    }

    private _renderFilter = (isFilterPanelOpen: boolean, toggleFilterPanel: () => void) => {
        return (
            <EntitiesFilter canManageConfiguration={this.state.canManageConfiguration}
                preFilter={this.state.preFilter}
                activeFilter={this.props.activeFilter}
                preFilterId={this.props.preFilterId}
                onActiveFilterChanged={this._onActiveFilterChanged}
                onFilterChanged={this.props.filtersActions.updateFilter}
                entityType={EntityType.Challenge}
                entityFilterHelper={this.state.entityFilterHelper}
                isFilterPanelOpen={isFilterPanelOpen}
                toggleFilterPanel={toggleFilterPanel} />
        );
    }

    private _onActiveFilterChanged = (id?: string) => {
        this.props.filtersActions.setActiveFilter(id, this.props.preFilterId);
    }

    private applyFilter = (_: Challenge[]) => {
        return _.filter(this._isItemVisible);
    }

    private _isItemVisible = (item: Challenge): boolean => {
        const { preFilter } = this.state;
        if (Metadata.PreFilter.isItemVisible(preFilter, item, this.props.preFilterId)) {
            return false;
        }

        const { activeFilter, fields } = this.props;
        if (activeFilter) {
            const filterValue = activeFilter.value;
            const allAttributes = this.state.entityFilterHelper.getFilterAttributes(fields);

            for (const type in filterValue) {
                if (!this.state.entityFilterHelper.helpersMap[type].validateItem(item, filterValue[type], allAttributes.filter(_ => _.type === type))) {
                    return false;
                }
            }
        }

        return true;
    }

    private getAttributeValue = (attrType: keyof Metadata.BaseFilterValue, value: any): string[] => {
        return this.state.entityFilterHelper.helpersMap[attrType].getAttributeValues(value);
    }

    private _getItemCommands = (entity: Challenge): IContextualMenuItem[] => {
        return entity.isEditable
            ? [
                {
                    key: 'share',
                    name: 'Share',
                    iconProps: { iconName: 'Share' },
                    onClick: () => this.setState({ share: entity })
                },
                {
                    key: 'edit',
                    name: 'Edit',
                    iconProps: { iconName: "Edit" },
                    onClick: () => this.props.history.push(this._getChallengeLink(entity))
                },
                {
                    key: 'delete',
                    name: 'Delete',
                    iconProps: { iconName: "Delete", style: { color: 'red' } },
                    disabled: this.props.isLoading,
                    style: { color: (this.props.isLoading ? 'initial' : 'red'), backgroundColor: (this.props.isLoading ? 'lightgrey' : 'unset') },
                    onClick: () => this.setState({ challengesToRemove: [entity] })
                }
            ]
            : [
                {
                    key: 'view',
                    name: 'View',
                    iconProps: { iconName: "View" },
                    onClick: () => this.props.history.push(this._getChallengeLink(entity))
                }
            ];
    }

    private _renderCardViewMenu = (entity: Challenge) => {
        const canSubmitIdea = this._canSubmitIdea(entity);
        return <div className="menu">
            <ActionButton
                iconProps={{ iconName: 'Add' }}
                title={canSubmitIdea
                    ? 'Submit new idea'
                    : 'New ideas can be submitted for active challenges only. Please consider changing challenge stage to Active'
                }
                disabled={!canSubmitIdea}
                onClick={(e) => { this.setState({ createIdeaForChallengeId: entity.id }); e.stopPropagation(); e.preventDefault(); }}>Submit Idea</ActionButton>
            <RowMenuWithExtensions
                commands={this._getItemCommands(entity)}
                item={entity}
                entityType={EntityType.Challenge}
                renderOnlyMenu />
        </div>;
    }

    private _canSubmitIdea = (entity: Challenge) => entity.attributes.Stage === ChallengeStage.Active && entity.canCollaborate;

    private _getChallengeLink(entity: Challenge): string {
        return `/challenge/${entity.id}`;
    }

    private getCardView(): IEntitiesScreenView<Challenge> {
        const { card } = this.props.views!;
        const fieldsMap = Metadata.toMap(this.props.fields);
        return {
            icon: 'PPMXCardView',
            url: 'card',
            subViews: card.subViews,
            sortBy: card.sortBy,
            onSortChange: this.props.viewsActions.changeCardViewSort,
            activeSubViewId: card.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setCardActiveSubView,
            render: (key: string, activeSubView: Metadata.ICardSubView, entities: Challenge[]) => {
                const comparer = SortService.getComparer(fieldsMap, card.sortBy.active.orderBy);
                return <EntitiesCardList
                    key={key}
                    entities={entities.sort(comparer)}
                    onCardRender={
                        (entity: Challenge) => <ChallengeCard
                            key={entity.id}
                            entity={entity}
                            fields={fieldsMap}
                            onMenuRender={this._renderCardViewMenu}
                            getLink={this._getChallengeLink}
                        />}
                    cardParams={{ width: 456, height: 263 }} />;
            }
        }
    }

    private getListView(): IEntitiesScreenView<Challenge> {
        const { list } = this.props.views!;
        const fields = list.fakeFields.concat(this.props.fields);
        return {
            subViews: list.subViews.allIds.map(_ => list.subViews.byId[_]),
            icon: 'PPMXListView',
            url: 'list',
            activeSubViewId: list.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setListActiveSubView,
            onEditSubViewClick: id => {
                if (list.activeSubViewId !== id) {
                    this.props.history.push(`/challenges/list/${id}`);
                }
                this.setState({ isListViewEdit: true });
            },
            onCopySubViewClick: this._onCopyListSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeListSubView,
            render: (key: string, activeSubView: Metadata.IListSubView, entities: Challenge[]) => {
                const listProps: Partial<IListProps> & IDetailsProps = {
                    onItemMenuRender: (item: Challenge) => <RowMenuWithExtensions
                        commands={this._getItemCommands(item)}
                        entityType={EntityType.Challenge}
                        selection={this._selection}
                        item={item} />,
                    extraColumns: [{
                        key: 'entity-row-menu',
                        name: '',
                        minWidth: 150,
                        isResizable: false,
                        onRender: (item: Challenge) => {
                            const canSubmitIdea = this._canSubmitIdea(item);
                            return <RowMenuContainer>
                                <PrimaryButton
                                    iconProps={{ iconName: 'PPMXCreateIdea' }}
                                    text="Submit Idea"
                                    title={canSubmitIdea
                                        ? 'Submit new idea'
                                        : 'New ideas can be submitted for active challenges only. Please consider changing challenge stage to Active'
                                    }
                                    disabled={!canSubmitIdea}
                                    onClick={() => this.setState({ createIdeaForChallengeId: item.id })}
                                />
                            </RowMenuContainer>;
                        }
                    }]
                };

                return [<ListSubView
                    key="details-view"
                    type="Details"
                    entities={entities}
                    selection={this._selection}
                    entityType={EntityType.Challenge}
                    fields={fields}
                    isFieldFake={this._buildIsFieldFake()}
                    sort={list.sortBy}
                    sorter={this._sorter}
                    onSortChange={this.props.viewsActions.changeListViewSort}
                    view={activeSubView}
                    listProps={listProps}
                    selectionModeItems={this._buildSelectionModeCommands()}
                    onColumnResized={(id, w) => this.props.viewsActions.onListColumnResized(activeSubView.id, id, w)}
                />,
                this.state.isListViewEdit
                    ? <EditListSubView
                        key="create-details-view"
                        subView={activeSubView}
                        entityType={EntityType.Challenge}
                        selectedByDefault={this.props.views!.list.selectedByDefault}
                        fields={fields}
                        onChange={changes => this.props.viewsActions.updateListSubView(activeSubView.id, changes)}
                        onSave={() => {
                            this.props.viewsActions.saveListSubView(activeSubView, 'challenges');
                            this.setState({ isListViewEdit: false });
                        }}
                        onCopy={() => this._onCopyListSubView(activeSubView)}
                        onDismiss={() => this.setState({ isListViewEdit: false })} />
                    : <span key="no-edit"></span>
                ];
            },
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addListSubView(subView);
                this.props.history.push(`/challenges/list/${subView.id}`);
                this.setState({ isListViewEdit: true });
            }
        }
    }

    private _onCopyListSubView = (view: Metadata.IListSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/challenges/list/${subView.id}`);
        this.setState({ isListViewEdit: true });
        this.props.viewsActions.saveListSubView(subView, 'challenges', undefined, view.id);
    }

    private _buildSelectionModeCommands = (): ICommandBarItemProps[] | undefined => {
        if (!this.state.canEdit) {
            return undefined;
        }
        const { selectedItems } = this.state;
        
        const layoutMenuItem = LayoutService.buildApplyLayoutMenuItem(this.props.layouts, (layout: Metadata.Layout) => {
            this.setState({ layoutToApply: layout });
        });

        return [
            {
                key: "bulk-edit",
                text: "Bulk edit",
                iconProps: { iconName: "TripleColumnEdit" },
                onClick: () => this.props.history.push(`/challenges/bulk`),
            },
            {
                ...layoutMenuItem,
                disabled: !!selectedItems.find(_ => !_.canConfigure),
            },
            {
                key: 'delete',
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle(EntityType.Challenge),
                iconProps: { iconName: "Delete" },
                className: "more-deleteButton",
                onClick: () => this.setState({ challengesToRemove: selectedItems }),
            }
        ];
    }

    private gridBulkUpdate = (updates: Dictionary<any>) => {
        this.props.challengesActions.bulkUpdate(updates);
    }

    private _sorter: Sorter<Challenge> = (orderBy) => {
        const { list } = this.props.views!;
        const fields = list.fakeFields.concat(this.props.fields);
        const isFieldFake = this._buildIsFieldFake();
        const extractor = (item: Challenge, field: Metadata.Field) => {
            if (field.name === LayoutsStore._layoutFakeFieldName) {
                return item.layoutId ? this.props.layouts.byId[item.layoutId]?.name : null;
            }
            return SortService.getFieldValueBaseImpl(field, item, isFieldFake)
        }

        const fieldsMap = Metadata.toMap(fields, isFieldFake);
        return (a, b) => SortService.getComparer(fieldsMap, orderBy, isFieldFake, extractor)(a, b);
    };

    private _buildIsFieldFake = (): (_: Metadata.Field) => boolean => {
        const { list } = this.props.views!;
        const fakeMap = toDictionaryById(list.fakeFields);
        return (_: Metadata.Field) => !!fakeMap[_.id];
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{}>): StateProps => {
    const fields = state.fields[EntityType.Challenge];
    const filters = FiltersStore.getFilter(state.filters, EntityType.Challenge);
    const autoFilterId = Metadata.Filter.getAutoFilterId(filters.all) ?? filters.all[0].id;
    return {
        challenges: state.challenges.allIds.map(_ => state.challenges.byId[_]),
        layouts: state.layouts[EntityType.Challenge],
        fields: fields.allIds.map(_ => fields.byId[_]),
        filters: filters.all,
        activeFilter: filters.active.filter,
        autoFilterId: autoFilterId,
        preFilterId: filters.active.preFilterId,
        views: state.views[EntityType.Challenge],
        deletionResult: state.challenges.deletionResult,
        isLoading: state.challenges.isLoading,
        user: state.user,
        tenant: state.tenant,
        challengesReports: state.tenant.reporting.challengesReports,
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        challengesActions: bindActionCreators(actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.Challenge), dispatch),
        viewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Challenge), dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ChallengesList)