import * as React from "react";
import ListMenuItemSelectorBuilder, * as ListMenuItemSelector from "./ListMenuItemSelector";
import { PreFilter, IFilter, BaseFilterValue } from "../../entities/Metadata";

type Props<T> = Pick<
    ListMenuItemSelector.IProps<IFilter<BaseFilterValue>>,
    "onActiveItemChanged" | "onItemEditClick" | "onItemRemoveClick" | "onItemAddClick" | "onItemCopyClick"
> & {
    filters: IFilter<BaseFilterValue>[];
    activeFilter: IFilter<BaseFilterValue> | undefined;
    canManageConfiguration: boolean;
    preFilter?: PreFilter<T>;
    preFilterId?: string;
};

const ListMenuItemSelectorT = ListMenuItemSelectorBuilder<IFilter<BaseFilterValue>>();

const ListMenuFilterSelector = <T,>(props: Props<T>) => {
    const { preFilter, activeFilter, filters, canManageConfiguration, preFilterId } = props;

    return (
        <ListMenuItemSelectorT
            iconProps={{ iconName: "Filter" }}
            preItems={preFilter ? { ...preFilter, active: PreFilter.get(preFilter, preFilterId) } : undefined}
            itemType="Filter"
            activeItem={activeFilter}
            items={filters}
            useSeparators
            entityLabel="filters"
            canManageConfiguration={canManageConfiguration}
            onActiveItemChanged={props.onActiveItemChanged}
            onItemAddClick={props.onItemAddClick}
            onItemEditClick={props.onItemEditClick}
            onItemRemoveClick={props.onItemRemoveClick}
            onItemCopyClick={props.onItemCopyClick}
        />
    );
};

export default ListMenuFilterSelector;
