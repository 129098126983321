import * as React from 'react';
import { default as DropdownInput, DropdownInputProps } from './DropdownInput';
import { IDropdownOption, IDropdownProps } from "office-ui-fabric-react";
import { Group, IGroupInfo } from "../../../entities/Metadata";
import { GroupFormatter } from '../formatters/GroupFormatter';
import { EntityGroup } from '../extensibleEntity/EntityGroupHeader';
import { notUndefined } from '../../utils/common';

export const ungroupedGroup: EntityGroup = { key: "-1", name: "Ungrouped", color: "#6a6b6d", count: 0, data: undefined };

type Props = {
    dropdownInputProps: DropdownInputProps,
    value: IGroupInfo | undefined,
    groups: Group[],
    showEmptyRow?: boolean
}

type State = { options: IDropdownOption[] }

export default class GroupDropdown extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = this._buildState(props);
    }

    componentWillReceiveProps(nextProps: Props) {
        this.setState(this._buildState(nextProps));
    }

    render() {
        const { dropdownInputProps, value } = this.props;
        const { options } = this.state;

        const inputProps: IDropdownProps = {
            ...dropdownInputProps.inputProps,
            options,
            onRenderOption: _ => _ ? <GroupFormatter value={_.data} /> : <span />,
            onRenderTitle: _ => <GroupFormatter value={_?.length ? _[0].data : undefined} />,
            calloutProps: { className: "group-dropdown-input-callout" }
        };

        return (
            <DropdownInput {...dropdownInputProps}
                value={value ? value.id : ungroupedGroup.key}
                onChanged={this._onChange}
                inputProps={inputProps}
            />
        );
    }

    private _buildState = (props: Props): State => {
        const options: IDropdownOption[] = [
            ...[props.showEmptyRow
                ? { key: ungroupedGroup.key, text: "", data: { id: ungroupedGroup.key, name: "", color: "" } }
                : undefined],
            ...props.groups.map(_ => ({ key: _.id, text: _.name, data: _ }))
        ].filter(notUndefined);

        return { options };
    }

    private _onChange = (groupId: string) => {
        const { dropdownInputProps, groups } = this.props;
        const group = groupId === ungroupedGroup.key
            ? undefined
            : groups.find(_ => _.id === groupId);

        if (dropdownInputProps.onChanged) {
            const groupInfo: IGroupInfo | null = group ? { id: group.id, name: group.name, color: group.color } : null;
            dropdownInputProps.onChanged(groupInfo);
        }
    }
}