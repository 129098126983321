import { Action, Reducer } from "redux";
import { AppThunkAction } from ".";
import { Dictionary, EntityType, IPatch, EnumConfigMap, StageCategory } from "../entities/common";
import { get, post, remove } from "../fetch-interceptor";
import { addOrUpdateOrRemove, IDeletionResult, IEntityStore, partialUpdate, StoreHelper } from "./services/storeHelper";
import { defaultCatch } from "./utils";
import { Process, ProcessStatus, StageType } from './process/common';
import { IProcessStage } from '../entities/Subentities';
import { BaseSubentitiesUpdateAction, ICreationData } from './Subentity';
import { push, RouterAction } from 'react-router-redux';

export interface ProcessesState extends IEntityStore<Process> {
    isLoading: boolean;
    isListLoading: boolean;
    deletionResult?: IDeletionResult[];
}

export const ProcessStatusMap: EnumConfigMap = {
    [ProcessStatus.Active]: { title: "Active", cssClassName: "Active" },
    [ProcessStatus.Inactive]: { title: "Inactive", cssClassName: "Inactive" },
    [ProcessStatus.Draft]: { title: "Draft", cssClassName: "Draft" }
}

interface RequestProcessesAction {
    type: 'REQUEST_PROCESSES';
}

interface ReceiveProcessesAction {
    type: 'RECEIVED_PROCESSES';
    processes: Process[];
}

interface LoadProcessAction {
    type: 'LOAD_PROCESS';
    id: string;
}

interface LoadedProcessAction {
    type: 'LOADED_PROCESS';
    process: Process;
}

interface BulkUpdateProcessesSuccessAction {
    type: 'BULK_UPDATE_PROCESSES_SUCCESS';
    processes: Process[];
}

interface CreateProcessAction {
    type: 'CREATE_PROCESS';
}

interface CreatedProcessAction {
    type: 'CREATED_PROCESS';
    process: Process;
}

interface ReceivedDeleteProcessesResultAction {
    type: 'RECEIVED_REMOVE_PROCESSES_RESULT';
    deletionResult?: IDeletionResult[];
}

interface UpdateProcessStagesAction extends BaseSubentitiesUpdateAction<IProcessStage> {
    type: "UPDATE_PROCESS_STAGES";
}

export type KnownAction = 
    | RequestProcessesAction
    | ReceiveProcessesAction
    | LoadProcessAction
    | LoadedProcessAction
    | BulkUpdateProcessesSuccessAction
    | CreateProcessAction
    | CreatedProcessAction
    | ReceivedDeleteProcessesResultAction
    | UpdateProcessStagesAction;

const unloadedState: ProcessesState = {
    byId: {},
    allIds: [],
    isLoading: false,
    isListLoading: false,
};

export const actionCreators = {
    requestProcesses: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Process[]>(`api/process`)
            .then(data => {
                dispatch({ type: 'RECEIVED_PROCESSES', processes: data });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'REQUEST_PROCESSES' });  
    },
    loadProcess: (id: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        get<Process>(`api/process/${id}`)
            .then(data => {
                dispatch({ type: 'LOADED_PROCESS', process: data });
                dispatch({ type: "UPDATE_PROCESS_STAGES", entityId: data.id, addOrUpdate: data.stages });
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'LOAD_PROCESS', id });
    },
    createProcess: (name: string, entityType: EntityType, openOnComplete?: boolean): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<Process>(`api/process/${entityType}`, { name })
            .then(data => {
                dispatch({ type: 'CREATED_PROCESS', process: data });
                if (openOnComplete) {
                    dispatch(push(`/process/${data.id}`, {}));
                }
            })
            .catch(defaultCatch(dispatch));

        dispatch({ type: 'CREATE_PROCESS' });
    },
    removeProcesses: (ids: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<IDeletionResult[]>(`api/process/bulkDelete`, { ids })
            .then(data => {
                dispatch({ type: "RECEIVED_REMOVE_PROCESSES_RESULT", deletionResult: data });
            })
            .catch(defaultCatch(dispatch));
    },
    dismissDeletionResult: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "RECEIVED_REMOVE_PROCESSES_RESULT" });
    },
    bulkUpdateProcesses: (updates: Dictionary<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Process[]>(`api/process/bulkUpdate`, updates)
            .then(data => {
                dispatch({ type: 'BULK_UPDATE_PROCESSES_SUCCESS', processes: data });
            })
            .catch(defaultCatch(dispatch));
    },
    updateProcessAttributes: (processId: string, newAttributeValues: Dictionary<any>): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Process>(`api/process/${processId}/attributes`, newAttributeValues)
            .then(data => {
                dispatch({ type: 'LOADED_PROCESS', process: data });
            })
            .catch(defaultCatch(dispatch));
    },
    makeProcessDefault: (processId: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Process[]>(`api/process/${processId}/default`, {})
            .then(data => {
                dispatch({ type: 'BULK_UPDATE_PROCESSES_SUCCESS', processes: data });
            })
            .catch(defaultCatch(dispatch));
    },
    cloneProcess: (processId: string): AppThunkAction<KnownAction | RouterAction> => (dispatch, getState) => {
        post<Process>(`api/process/${processId}/clone`, {})
            .then(data => {
                dispatch({ type: 'CREATED_PROCESS', process: data });
                dispatch(push(`/process/${data.id}`, {}));
            })
            .catch(defaultCatch(dispatch));
    },
    updateProcessStageTypes: (processId: string, stageTypes: StageType[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Process>(`api/process/${processId}/stageTypes`, stageTypes)
            .then(data => {
                dispatch({ type: 'LOADED_PROCESS', process: data });
            })
            .catch(defaultCatch(dispatch));
    },
    createProcessStages: (processId: string, stages: ICreationData[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Process>(`api/process/${processId}/stage`, stages)
            .then(data => {
                dispatch({ type: 'LOADED_PROCESS', process: data });
                dispatch({ type: 'UPDATE_PROCESS_STAGES', entityId: processId, addOrUpdate: data.stages });
            })
            .catch(defaultCatch(dispatch));
    },
    updateProcessStages: (processId: string, stages: IPatch<IProcessStage>[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Process>(`api/process/${processId}/stage/bulkUpdate`, stages)
            .then(data => {
                dispatch({ type: 'LOADED_PROCESS', process: data });
                dispatch({ type: 'UPDATE_PROCESS_STAGES', entityId: processId, addOrUpdate: data.stages });
            })
            .catch(defaultCatch(dispatch));
    },
    dragProcessStagesToCategory: (processId: string, stageIds: string[], category: StageCategory, insertBeforeId?: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        post<Process>(`api/process/${processId}/stages/dragToCategory`, { ids: stageIds, insertBeforeId, category })
            .then(data => {
                dispatch({ type: 'LOADED_PROCESS', process: data });
                dispatch({ type: 'UPDATE_PROCESS_STAGES', entityId: processId, addOrUpdate: data.stages });
            })
            .catch(defaultCatch(dispatch));
    },
    removeProcessStages: (processId: string, stageIds: string[]): AppThunkAction<KnownAction> => (dispatch, getState) => {
        remove<Process>(`api/process/${processId}/stage`, { ids: stageIds })
            .then(data => {
                dispatch({ type: 'LOADED_PROCESS', process: data });
                dispatch({ type: 'UPDATE_PROCESS_STAGES', entityId: processId, remove: stageIds });
            })
            .catch(defaultCatch(dispatch));
    },
};

export const reducer: Reducer<ProcessesState> = (state: ProcessesState = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_PROCESSES':
            return {
                ...state,
                isLoading: true,
                isListLoading: true
            };
        case 'RECEIVED_PROCESSES':
            return {
                ...state,
                ...StoreHelper.create(action.processes),
                isLoading: false,
                isListLoading: false
            };
        case 'CREATE_PROCESS':
            return {
                ...state,
                isLoading: true
            };
        case 'CREATED_PROCESS':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.process),
                isLoading: false
            };
        case 'LOAD_PROCESS':
            return {
                ...state,
                activeEntityId: action.id,
                activeEntity: state.activeEntity && state.activeEntity.id === action.id ? state.activeEntity : undefined,
                isLoading: true
            };
        case 'LOADED_PROCESS':
            return {
                ...state,
                ...StoreHelper.addOrUpdate(state, action.process),
                activeEntity: state.activeEntityId === action.process.id ? action.process : state.activeEntity,
                isLoading: false
            };
        case 'BULK_UPDATE_PROCESSES_SUCCESS':
            return {
                ...state,
                ...StoreHelper.union(state, action.processes),
                isLoading: false
            };
        case 'RECEIVED_REMOVE_PROCESSES_RESULT':
            let newState = { ...state };
            if (action.deletionResult?.length) {
                action.deletionResult.forEach(result => {
                    if (result.isDeleted && newState.byId[result.id]) {
                        newState = { ...newState, ...StoreHelper.remove(newState, result.id) };
                    }
                });
            }
            return {
                ...newState,
                isLoading: false,
                deletionResult: action.deletionResult
            };
        case 'UPDATE_PROCESS_STAGES':
            return StoreHelper.applyHandler(state, action.entityId, (process: Process) => {
                const stages = addOrUpdateOrRemove(process.stages, action.addOrUpdate, action.remove);
                stages.sort((a, b) => a.attributes.Order - b.attributes.Order);
                return partialUpdate(process, { stages });
            });
        default:
            return state;
    }
};
