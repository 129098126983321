import * as React from 'react';
import * as analytics from '../../../../analytics';
import { bindActionCreators } from 'redux'
import { IControlConfiguration, ISectionUIControlProps } from "../../interfaces/ISectionUIControlProps";
import {
    default as SubentitiesList, ISubentitiesListSettings, SubListControlSettings,
    SubentitiesListHierarchyContext
} from "../../../../components/common/SubentitiesList";
import { SectionHierarchyManager } from "../../../../components/utils/SectionHierarchyManager";
import { ProjectInfo } from '../../../../store/ProjectsListStore';
import { nameof, namesof } from '../../../../store/services/metadataService';
import {
    ITask, ITaskAttrs, buildMilestone, ISubentitySectionActions, IPredecessorInfo, sortTasksByRank, PredecessorType, buildPredecessorsMap
} from '../../../../entities/Subentities';
import GroupSelectionPanel from './tasksControl/GroupSelectionPanel';
import * as Metadata from "../../../../entities/Metadata";
import { UserState } from "../../../../store/User";
import { connect } from 'react-redux';
import { ApplicationState } from "../../../../store/index";
import * as TasksTimeline from '../../../task/timeline';
import { ContextualMenuItemType, getId, IContextualMenuItem, MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { GroupInfo } from '../../CreateGroupPanel';
import {
    Dictionary, IUserInfo, StatusCategory, ISourceInfo, SourceInfo, ppmxTaskConnectionId, EntityType, IPatch, 
    IWithSubViews, IWithSortBy, IWithScale, ProgressCalculationType
} from "../../../../entities/common";
import * as TimelineList from '../../timeline/TimelineList';
import * as utils from "../../timeline/utils";
import EntityGroupHeader, { EntityGroup } from '../../extensibleEntity/EntityGroupHeader';
import { withRouter, RouteComponentProps } from "react-router-dom";
import PersonPickerInput from '../../inputs/PersonPickerInput';
import { arraysEqual, formatValue, notUndefined, toDate, toDictionaryById, waitForFinalEvent } from '../../../utils/common';
import { ProgressIcon } from '../../../common/ProgressFormatter';
import { ListGroupingProps } from '../../extensibleEntity/EntityDetailsList';
import KeyDateTooltipContent from '../../timeline/KeyDateTooltipContent';
import { ITimelineSegment } from '../../timeline/TimelineSegment';
import { ITimelineMarker } from '../../timeline/TimelineMarker';
import { CalendarDataSet } from '../../../../store/CalendarStore';
import { allowEditReadOnlyProgress, allowEditTaskProgress, allowEditTasksProgress, allowedReadonlyProgressFields, TaskHierarchy, TITLE_FIELD_ID } from '../../../../store/tasks';
import { SourceType, SourceType_, SourceTypeMap } from '../../../../store/ExternalEpmConnectStore';
import { SectionControlPlaceholder } from '../SectionPlaceholder';
import RefreshProjectButton from './projectSummaryControl/RefreshProjectButton';
import IterationSelect from '../../IterationSelect';
import { ungroupedGroup } from '../../inputs/GroupDropdown';
import { TimePortalService } from '../../../utils/TimePortalService';
import * as NotificationsStore from '../../../../store/NotificationsStore';
import { ITimelineProps, MAX_ROWS_COUNT_FOR_CORRECT_DND } from '../../extensibleEntity/EntityTimelineList';
import { handleDuration } from '../../../utils/duration';
import TaskBaseline from '../../../task/TaskBaseline';
import TaskSetBeselineConfirmation from '../../../task/TaskSetBeselineConfirmation';
import { IStyleSettings } from '../../timeline/TimelineList';
import { FilterHelper } from '../../../../store/task/filters';
import { FilterHelperProps } from '../../../../store/subentities/filters';
import { InlineInput, TextEditor } from '../../inputs/InlineAddInput';
import TaskPredecessors from '../../../task/TaskPredecessors/TaskPredecessors';
import { ITimelineRelation } from '../../timeline/TimelineRelation';
import { ICreationData } from '../../../../store/Subentity';
import { IWithActiveFilter } from '../../../../store/services/settingsService';
import { IWithStyleSettings, PreferenceUpdates, UserViewPreferencesUpdate, Views } from '../../../../store/services/viewSaver';
import * as StatusDescriptorFactory from '../../../../entities/StatusDescriptorFactory';
import { ConfirmationDialog } from '../../ConfirmationDialog';
import { TaskProgressCalculator } from '../../../../components/task/Fields';
import { titleSuffixRender } from '../../../../components/mywork/TaskEditPanel';
import { IInputProps } from '../../interfaces/IInputProps';
import { PPMFeatures, Subscription, TenantState } from '../../../../store/Tenant';
import AISchedulePanel from '../../../project/AISchedulePanel';
import { v4 as uuidv4 } from 'uuid';
import { ViewService } from '../../../../services/ViewService';
import { TaskVarianceCalculator } from '../../../task/TaskVarianceCalculator';
import { TaskVariance } from '../../../task/TaskBaseline/TaskBaseline';
import { GroupSummary } from '../../../task/TaskGroupTooltipContent';
import * as TimeTrackingReportTimeMenu from '../../../../components/timeTracking/TimeTrackingReportTimeMenu';

const TaskSuggessionId: string = "new-task-suggession";
const SubtaskSuggessionId: string = "new-subtask-suggession";
export const ProgressScale = [0, 25, 50, 75, 100];
const FeatureForAllTasksFilterMessage: string = "Feature is only available for All Tasks filter with no pre-filters.";
export type IActions = ISubentitySectionActions<ITask> & {
    createGroup: (group: Metadata.IBaseGroupInfo) => void;
    updateGroup: (group: Metadata.Group) => void;
    removeGroup: (groupId: string) => void;
    reorderGroups: (groupIds: string[]) => void;
    bulkUpdate: (items: IPatch<ITask>[]) => void;
    bulkCreate: (items: ICreationData[]) => void;
    reportTime: (task: ITask, seconds: number, date: Date) => void;
    setBaseline: (entities: string[]) => void;
    indent: (ids: string[], parentId?: string) => void;
    outdent: (ids: string[], parentId?: string) => void;
    rollupTasks: (ids: string[]) => void;
    updateCalculationMode: (isAutoMode: boolean, ids: string[]) => void;
}

type ICustomElementRender = Dictionary<(props: IInputProps, state: ITaskAttrs, field: Metadata.Field) => JSX.Element>;
export type IDataContext = {
    activeSourceInfo: ISourceInfo;
    groups?: Metadata.Group[];
    fakeFields?: Metadata.Field[];
    readOnlyFields?: ((entity: ITask) => string[]) | string[];
}
type SystemControlSettings = {
    timeline: IWithSubViews & IWithSortBy,
    details: IWithSubViews & IWithSortBy
} & IWithActiveFilter;

type StyleValues = {
    showBaseline: boolean;
}

type ControlSettings = {
    viewType: Views;
    timeline: IWithScale & IWithStyleSettings<StyleValues>;
} & {
        [key in SourceType]: SystemControlSettings
    }
export type IConfiguration = IControlConfiguration<IActions, ControlSettings, IDataContext>;
type ExternalTasksControlProps = ISectionUIControlProps<IActions, ISubentitiesListSettings, ProjectInfo, ICustomElementRender, ControlSettings, IDataContext>;
type StateProps = {
    user: UserState;
    calendar: CalendarDataSet;
    isListLoading: boolean;
    isListUpdating: boolean;
    timePortalUrl: string;
    fields: Metadata.Field[];
    tenant: TenantState;
    connectionTaskTypes?: string[];
};
type ActionsProps = { notificationsActions: typeof NotificationsStore.actionCreators }

type Props = ExternalTasksControlProps & StateProps & ActionsProps & RouteComponentProps<{}>;

type State = {
    tasks: ITask[];
    groups: EntityGroup[] | undefined;
    preFilterItems: Metadata.PreFilterOption<ITask>[];
    readOnly?: boolean;
    isTimelineDnDMessageShown?: boolean;
    setBaselineSelectedEntities?: ITask[];
    isOpenAutoModeConfirmation?: string[];
    isAISchedulePanelOpen: boolean;
    selectedGroup?: EntityGroup;
    expandedInFilter: string[];
}

const SubList = SubentitiesList<ITask>();

const tasksStyleSettings: IStyleSettings<StyleValues> = {
    showBaseline: { label: 'Show Baseline' }
}

//after filter change check same filter on ui in DataService.GetProjectTasksDailyCalculation.cs
function InDateRangeFilter(begin: Date, end: Date): (entity: ITask) => boolean {
    return (_) =>
        (!!_.attributes.StartDate && !!_.attributes.DueDate
            && toDate(_.attributes.StartDate)! < begin
            && toDate(_.attributes.DueDate)!.getEndOfDay() > end)
        || (!!_.attributes.StartDate
            && toDate(_.attributes.StartDate)! >= begin
            && toDate(_.attributes.StartDate)!.getEndOfDay() <= end)
        || (!!_.attributes.DueDate
            && toDate(_.attributes.DueDate)! >= begin
            && toDate(_.attributes.DueDate)!.getEndOfDay() <= end);
}

function defaultPrefilterItems(): Metadata.PreFilterOption<ITask>[] {
    const today = {
        begin: new Date().getBeginOfDay(),
        end: new Date().getEndOfDay()
    };
    const week = new Date().getThisWeek();

    return [
        {
            key: Metadata.TaskPrefilterKey.today,
            name: 'Today Tasks',
            predicate: InDateRangeFilter(today.begin, today.end)
        },
        {
            key: Metadata.TaskPrefilterKey.thisweek,
            name: 'This Week Tasks',
            predicate: InDateRangeFilter(week.start, week.end)
        },
        {
            key: Metadata.TaskPrefilterKey.late,
            name: 'Late Tasks',
            predicate: _ => _.attributes.Progress !== 100 && !!_.attributes.DueDate && toDate(_.attributes.DueDate)! < today.begin
        },
        {
            key: Metadata.TaskPrefilterKey.nodates,
            name: 'No Dates',
            predicate: _ => !_.attributes.StartDate && !_.attributes.DueDate
        },
        {
            key: Metadata.TaskPrefilterKey.noassignments,
            name: 'No Assignments',
            predicate: _ => !_.attributes.AssignedTo?.length
        }
    ];
}
const nonFilterableFields = namesof<ITaskAttrs>(['Group', 'Predecessor']);
const timeDelay = 600;

const getGroupKey = (task: ITask): string => task.attributes.Group?.id || ungroupedGroup.key

const MIN_COUNT_TO_LINK = 2;
class TasksControl extends React.Component<Props, State> {
    private _inlineTasksToCreate: ICreationData[];
    private _debouncedOnSaveInlineTask: () => void;
    private _hierarchy: SectionHierarchyManager<ITask, SubentitiesListHierarchyContext<ITask>>;

    constructor(props: Props) {
        super(props);
        this._inlineTasksToCreate = [];
        this._debouncedOnSaveInlineTask = waitForFinalEvent(this._bulkSaveInlineTasks, timeDelay, 'bulk-save-inline-tasks');
        this._hierarchy = new SectionHierarchyManager<ITask, SubentitiesListHierarchyContext<ITask>>({
            fieldId: TITLE_FIELD_ID,
            collapseItemsOnSorting: true,
            allowDrag: () => {
                const filterObj = this._hierarchy.getContext();
                return TaskHierarchy.allowDrag(this.props.datacontext.activeSourceInfo.type, filterObj?.filter, filterObj?.prefilter);
            },
            isHierarchicalSort: () => TaskHierarchy.isHierarchicalSort(this.props.datacontext.activeSourceInfo.type),
            filterRootHierarchyItems: (tasks) => TaskHierarchy.filterRootHierarchyItems(this.props.datacontext.activeSourceInfo.type, tasks),
            isHierarchyView: (filterObj) => TaskHierarchy.isHierarchyView(this.props.datacontext.activeSourceInfo.type, filterObj?.filter, filterObj?.prefilter),
            getDefaultOutlineLevel: () => TaskHierarchy.getDefaultLevel(this.props.datacontext.activeSourceInfo.type),
            getItemIsParent: (entity: ITask): boolean => {
                const filterObj = this._hierarchy.getContext();
                const isHierarchyView = TaskHierarchy.isHierarchyView(this.props.datacontext.activeSourceInfo.type, filterObj?.filter, filterObj?.prefilter);
                return (isHierarchyView && !!entity.hierarchy.isParent)
                    || (!isHierarchyView && !!entity.hierarchy.isParent 
                        && (!!this._hierarchy.getParentKey(entity) || this.state.expandedInFilter.includes(this._hierarchy.getKey(entity))));
            },
            getItemId: (entity) => entity.sourceType === SourceType.Ppmx ? entity.id : entity.externalId,
            getItemParentId: (entity) => entity.sourceType === SourceType.Ppmx ? entity.hierarchy.parentId : entity.hierarchy.parentExternalId,
            getGroupKey: getGroupKey,
            supportsOutline: () => TaskHierarchy.isSupportOutline(this.props.datacontext.activeSourceInfo.type)
        });

        this.state = {
            tasks: [],
            groups: undefined,
            preFilterItems: props.datacontext ? this._getPrefilterItems()[props.datacontext.activeSourceInfo.type] : [],
            isTimelineDnDMessageShown: this._checkDnDLimitation(props),
            isAISchedulePanelOpen: false,
            expandedInFilter: [],
        };
    }

    componentWillMount() {
        const groups = this._buildGroups(this.props);
        const readOnly = !this.props.entity.canCollaborate || this.props.datacontext.activeSourceInfo.connectionId !== ppmxTaskConnectionId

        this.setState({ tasks: Array.from(this.props.entity.tasks || []), groups, readOnly });
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.entity.tasks !== nextProps.entity.tasks
            || this.props.datacontext.groups !== nextProps.datacontext.groups
            || !arraysEqual(this.props.connectionTaskTypes, nextProps.connectionTaskTypes)) {
            const groups = this._buildGroups(nextProps);
            this.setState({ groups, tasks: Array.from(nextProps.entity.tasks || []) });
        }
        const newReadonly = !nextProps.entity.canCollaborate || nextProps.datacontext.activeSourceInfo.connectionId !== ppmxTaskConnectionId
        if (newReadonly != this.state.readOnly) {
            this.setState({ readOnly: newReadonly });
        }

        if (this.props.datacontext.activeSourceInfo.type !== nextProps.datacontext.activeSourceInfo.type) {
            this.setState({ preFilterItems: this._getPrefilterItems()[nextProps.datacontext.activeSourceInfo.type] });
        }

        const isTimelineDnDMessageShown = this._checkDnDLimitation(nextProps, this.state.isTimelineDnDMessageShown)
        if (isTimelineDnDMessageShown !== undefined) {
            this.setState({ isTimelineDnDMessageShown });
        }
    }

    render() {
        const { isConfigureMode, switchToConfigureMode, onConfigureModeComplete, entity, actions, settings, isFullScreen,
            uiControlElementsCustomRender, customFieldValidatorBuilder, isListLoading, isListUpdating, datacontext, user, tenant, onSaveSettings } = this.props;
        const activeSourceInfo: ISourceInfo = datacontext.activeSourceInfo;

        if (SourceType_.isTaskUnactualVersion(activeSourceInfo) || (SourceInfo.syncFailed([activeSourceInfo]) && !isListLoading && (!entity.tasks || entity.tasks.length === 0))) {
            return <SectionControlPlaceholder
                key="no-data"
                iconName="TaskManagementSectionIcon"
                title="Tasks section is empty">
                <div className="align-center">
                    <div>
                        To see actual {SourceTypeMap[activeSourceInfo.type]} data, please click Refresh
                    </div>
                    <RefreshProjectButton entity={entity} className="tasks-control" />
                </div>
            </SectionControlPlaceholder>;
        }

        if (!entity.isSyncable && activeSourceInfo.type != SourceType.Ppmx && SourceType_.isTaskViewable(activeSourceInfo) && !isListLoading && (!entity.tasks || entity.tasks.length === 0)) {
            return <SectionControlPlaceholder
                key="no-data"
                iconName="TaskManagementSectionIcon"
                title="Tasks synchronization is in progress">
                <div className="align-center">
                    <div>
                        Please wait until synchronization is completed
                    </div>
                </div>
            </SectionControlPlaceholder>;
        }

        const { readOnly } = this.state;
        const relationsMap = (entity.tasks || [])?.reduce<Dictionary<TasksTimeline.TaskRelation[]>>((acc, _) => {
            if (_.attributes.Predecessor?.length) {
                _.attributes.Predecessor.forEach(predecessor => {
                    acc[predecessor.id] = [...(acc[predecessor.id] ?? []), { relatedId: _.id, type: predecessor.type }];
                    //same nail as in roadmaps
                    //needed for correct rendering svg during export to png
                    acc[_.id] = [...(acc[_.id] ?? []), { relatedId: predecessor.id, type: predecessor.type, isReverse: true }];
                })
            }
            return acc;
        }, {});
        const tasksMap = toDictionaryById(entity.tasks || []);
        const timelineProps: ITimelineProps = {
            buildRow: (task: ITask) => TasksTimeline.buildTimelineItem(task, tasksMap, relationsMap[task.id]),
            renderSegmentContent: TasksTimeline.renderSegmentContent,
            renderSegmentTooltipContent: (row, segment) => TasksTimeline.renderSegmentTooltipContent(row, segment, this._openTask),
            renderMarkerTooltipContent: _ => <KeyDateTooltipContent
                keyDate={buildMilestone(_.entity as ITask)!}
                createdFromType={EntityType.Task}
                onClick={() => this._openTask(_.entity as ITask)} />,
            onSegmentClick: _ => this._openTask(_.entity as ITask),
            onMarkerClick: _ => this._openTask(_.entity as ITask),
            onSegmentChange: readOnly ? undefined : (row: TimelineList.IRow, _segment, delta: Partial<ITimelineSegment>) =>
                this._updateTaskDates(row.entity as ITask, delta.startDate, delta.finishDate?.getBeginOfDay()),
            onMarkerChange: readOnly ? undefined : (row: TimelineList.IRow, _marker, delta: Partial<ITimelineMarker>) => {
                const task = row.entity as ITask;
                let startDate: Date | undefined = undefined;
                if (task.attributes.StartDate) {
                    //for milestones, keep duration unchanged
                    const duration = Math.round(utils.diffDays(toDate(task.attributes.StartDate)!, toDate(task.attributes.DueDate)!));
                    startDate = delta.date!.clone().addDays(-duration);
                }

                this._updateTaskDates(task, startDate, delta.date);
            },
            buildRelationCommands: this._buildTimelineRelationCommands,
            timelineGrouping: this._buildGrouping(),
            styling: activeSourceInfo.type !== SourceType.Ppmx ? undefined : {
                settings: tasksStyleSettings,
                values: this.props.controlSettings.timeline.styleSettingValues,
                onChange: (key, value) => onSaveSettings?.(PreferenceUpdates.viewType(Views.Timeline, PreferenceUpdates.style(key, value)))
            },
        };
        const listGrouping: ListGroupingProps = {
            getGroupKey: getGroupKey,
            renderGroupHeader: (group, isCollapsed, isSelected, toggleCollapse, toggleSelect) =>
                <EntityGroupHeader group={group}
                    isCollapsed={isCollapsed}
                    isSelected={isSelected}
                    onToggleCollapse={toggleCollapse}
                    onToggleSelect={toggleSelect}
                    onGroupNameClick={readOnly ? undefined : switchToConfigureMode}
                    renderCounter={group.count ?
                        (grp) => <span className="subitems-counter"
                            title={`${grp.data.tasksCounts.complete} task${grp.data.tasksCounts.complete == 1 ? '' : 's'} ` +
                                `out of ${grp.count} in the group ${grp.data.tasksCounts.complete == 1 ? 'is' : 'are'} completed`}>
                            {grp.data.tasksCounts.complete} / {grp.count}
                        </span>
                        : () => <span className="subitems-counter" title={`0 tasks in group`}>0</span>}
                />,
            renderGroupFooter: readOnly ? undefined : (group) => this.renderGroupFooter(group, user, tenant)
        };

        const metadataGroups = this._getGroups(this.props);
        return <>
            <SubList
                isFullScreen={isFullScreen}
                sectionId={this.props.sectionId}
                controlId={this.props.id}
                parentEntityId={this.props.entity.id}
                parentEntityType={this.props.entityType}
                timelineProps={timelineProps}
                groups={this.state.groups}
                listGrouping={listGrouping}
                readOnly={!!readOnly}
                allowExtensions={this.props.entity.canCollaborate}
                fakeFields={datacontext.fakeFields}
                subentityInfo={{
                    subentityType: EntityType.Task,
                    subentityTypeLabel: "Task",
                    subentityCollectionName: nameof("tasks", this.props.entity)
                }}
                isArchived={this.props.entity.isArchived}
                useViews
                useFilters
                preFilterItems={this.state.preFilterItems}
                sourceType={activeSourceInfo.type}
                entities={this.state.tasks}
                entitiesIsLoading={isListLoading}
                entitiesIsUpdating={isListUpdating}
                settings={settings}
                actions={{
                    ...actions,
                    create: this._create,
                    update: this._update,
                }}
                commandsConfig={{
                    add: {
                        title: "Create a new task, it will be added to the end of the tasks list" 
                    },
                    aiGeneration: {
                        hidden: !Subscription.contains(tenant.subscription, PPMFeatures.AiInsights),
                        action: () => {
                            analytics.trackEvent("Start with AI Schedule", user);
                            this.setState({ isAISchedulePanelOpen: true })
                        },
                        name: "Generate with AI",
                        title: !this.props.tenant.aiInsights?.aiEnabled 
                            ? "To use AI features, PPM Insights AI should be enabled in the tenant by PPM Express Administrator" 
                            : undefined,
                        disabled: !this.props.tenant.aiInsights?.aiEnabled
                    },
                    outline: {
                        disabled: () => !this._isHierarchyView(),
                        title: () => !this._isHierarchyView() ? FeatureForAllTasksFilterMessage : undefined,
                    }
                }}
                mandatoryEditFields={getMandatoryEditFields(activeSourceInfo.type)}
                nonFilterableFields={nonFilterableFields}
                readOnlyFields={datacontext.readOnlyFields}
                uiControlElementsCustomRender={uiControlElementsCustomRender}
                customFieldValidatorBuilder={customFieldValidatorBuilder}
                onItemRender={this._onListItemRender}
                emptyScreen={{
                    iconName: 'TaskManagementSectionIcon',
                    title: activeSourceInfo.type == SourceType.Ppmx ? 'Welcome to PPM Express Task Management!' : 'Welcome to Task Management!',
                    description: activeSourceInfo.type == SourceType.Ppmx ? 'You can use this section to manage tasks for a current project.'
                        : `This section shows tasks synchronized from ${SourceTypeMap[activeSourceInfo.type]}`
                }}
                externalCommands={{
                    actionsCommandItems: this._buildActionsCommandItems,
                    selectionModeCommands: this._buildSelectionModeCommands
                }}
                getDeletionMessage={(selectedItems: ITask[]) => selectedItems.length === 1
                    ? `Are you sure you want to delete task "${selectedItems[0].attributes.Name}"?`
                    : `Are you sure you want to delete ${selectedItems.length} tasks?`
                }
                renderDeleteDialogContent={(selectedItems: ITask[]) => {
                    const msgs: string[] = [];

                    if (selectedItems.some(_ => _.hierarchy.isParent)) {
                        msgs.push("Deleting the task will delete all its subtasks as well.");
                    }

                    const milestones = selectedItems.filter(_ => _.attributes.IsMilestone);
                    if (milestones.length > 0) {
                        msgs.push(selectedItems.length === 1
                            ? 'Related milestone will be deleted as well.'
                            : `${milestones.length} related milestone${milestones.length > 1 ? 's' : ''} will be deleted as well.`
                        );
                    }

                    return msgs.length
                        ? <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                            {msgs.map((msg, index) => <>{msg}{!index && <br />}</>)}
                        </MessageBar>
                        : undefined;
                }}
                buildEntityFilterHelper={this._buildFilterHelper}
                hierarchy={this._hierarchy}
                entityPanelProps={{
                    mandatoryEditableFields: (task: ITask) => allowEditReadOnlyProgress(task, user.resource?.id) ? allowedReadonlyProgressFields : undefined,
                    extraTabs: activeSourceInfo.type !== SourceType.Ppmx ? undefined : [{
                        key: 'predecessors',
                        label: 'Predecessors',
                        renderHeaderSecondaryText: () => "Add predecessor(s) for the task, then select the dependency type and optionally set lead or lag time.",
                        renderBody: (task, onChange) => <TaskPredecessors
                            readonly={!!readOnly}
                            task={task}
                            tasks={this.props.entity.tasks || []}
                            onChange={onChange}
                            calendar={this.props.calendar}
                        />
                    }, {
                        key: 'baseline',
                        label: 'Baseline',
                        renderHeaderSecondaryText: () => `View the task baseline, its current state and variance value`,
                        renderBody: (updatedTask: ITask) => <TaskBaseline task={updatedTask} variance={this._getTaskVariance(updatedTask)} />
                    }]
                }}
                controlSettings={this._getControlSettings()}
                onSaveSettings={onSaveSettings ? this._onSaveSettings : undefined}
                entityPanelHeader={{ suffixRender: titleSuffixRender }}
                menu={{
                    isEnabled: true,
                    mandatoryFields: ViewService.getViewMandatoryFields(EntityType.Risk),
                    getItemCommands: this._getItemCommands,
                }}
                resetExpandedAfterFilterChange
            />
            {isConfigureMode && metadataGroups &&
                <GroupSelectionPanel
                    groups={metadataGroups}
                    onDismiss={onConfigureModeComplete}
                    reorderGroups={this.reorderGroups}
                    allowCreateGroups={entity.canCollaborate}
                    allowEditGroups={entity.canCollaborate}
                    saveGroup={this.saveGroup}
                    removeGroup={this.removeGroup}
                    entityName={{ singular: "Task Group", plural: "Task Groups" }}
                    removeDialog={{ messageBuilder: this.removeTaskGroupDialogMessageBuilder }} />}
            {this.state.setBaselineSelectedEntities &&
                <TaskSetBeselineConfirmation
                    selectedItems={this.state.setBaselineSelectedEntities}
                    onDismiss={() => this.setState({ setBaselineSelectedEntities: undefined })}
                    onConfirm={() => this._setBaseline(this.state.setBaselineSelectedEntities!)}
                />}
            {this.state.isOpenAutoModeConfirmation && <ConfirmationDialog
                onDismiss={() => this.setState({ isOpenAutoModeConfirmation: undefined })}
                onYes={() => {
                    this.props.actions.updateCalculationMode(true, this.state.isOpenAutoModeConfirmation!);
                    this.setState({ isOpenAutoModeConfirmation: undefined });
                }}
                onNo={() => { }}
                yesButtonProps={{ text: "Confirm" }}
                noButtonProps={{ text: "Cancel" }}
                dialogContentProps={{
                    title: 'Confirmation',
                    subText: 'Are you sure you want to apply Auto mode to the selected tasks?'
                }} >
                <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Values in progress-related fields will be re-calculated for these tasks without the possibility to undo the re-calculation.
                </MessageBar>
            </ConfirmationDialog>}
            {
                this.state.isAISchedulePanelOpen && <AISchedulePanel
                    projectId={this.props.entity.id}
                    projectDescription={this.props.entity.attributes.Description}
                    startDate={this.props.entity.attributes.StartDate}
                    finishDate={this.props.entity.attributes.FinishDate}
                    groups={metadataGroups!}
                    group={this.state.selectedGroup ? metadataGroups?.find(_ => _.id === this.state.selectedGroup!.key) : undefined}
                    onDismiss={() => this.setState({ isAISchedulePanelOpen: false, selectedGroup: undefined })}/>
            }
        </>;
    }

    private _getTaskVariance = (updatedTask: ITask): TaskVariance | undefined => {
        const task = this.state.tasks.find(_ => _.id === updatedTask.id);
        return calculateTaskVariance(updatedTask, task, this.props.calendar);
    }

    private _isHierarchyView = (): boolean => {
        const filterObj = this._hierarchy.getContext();
        return TaskHierarchy.isHierarchyView(this.props.datacontext.activeSourceInfo.type, filterObj?.filter, filterObj?.prefilter);
    }

    private _getControlSettings = (): SubListControlSettings => {
        const { controlSettings, datacontext } = this.props;
        const sourceSettings = controlSettings[datacontext.activeSourceInfo.type.toString()];
        return {
            ...sourceSettings,
            timeline: {
                ...sourceSettings?.timeline,
                ...controlSettings.timeline
            },
            viewType: controlSettings!.viewType
        }
    }

    private _onSaveSettings = (update: UserViewPreferencesUpdate, sendToServer?: boolean) => {
        const { datacontext, onSaveSettings } = this.props;
        if (update.type === 'Timeframe' || update.type === 'Quantization') {
            onSaveSettings?.(update, sendToServer);
            return;
        }

        onSaveSettings?.({
            ...update,
            parentSettingsPathKeys: [datacontext.activeSourceInfo.type.toString(), ...update.parentSettingsPathKeys]
        }, sendToServer);
    }

    private _onListItemRender = (entity: ITask, index: number, field: Metadata.Field, defaultRender: () => JSX.Element): JSX.Element => {
        if (field.name === nameof<ITaskAttrs>("Name") && this._isTaskSuggession(entity)) {
            return <TextEditor
                required
                value={entity.attributes.Name}
                onSave={name => this._createTaskFromSuggesion(name, entity)}
                onCancel={() => this._removeTaskSuggesion(entity.id)} />;
        }
        return defaultRender();
    }

    private _createTaskSuggessionBefore = (beforeEntityId: string): void => {
        const { tasks } = this.state;
        const beforeEntity = tasks.find(_ => _.id === beforeEntityId);
        if (!beforeEntity || !this.props.actions.buildNew) {
            return;
        }
        const taskSuggession: ITask = {
            ...this.props.actions.buildNew(),
            id: TaskSuggessionId
        };
        taskSuggession.attributes.Group = beforeEntity.attributes.Group;

        taskSuggession.hierarchy = {
            parentId: beforeEntity.hierarchy.parentId,
            isParent: false,
            outlineLevel: beforeEntity.hierarchy.outlineLevel,
            parentIds: beforeEntity.hierarchy.parentIds
        };

        const newTasks = [...this.state.tasks];
        newTasks.splice(tasks.indexOf(beforeEntity), 0, taskSuggession);
        this.setState({ tasks: newTasks });
    }

    private _createSubtaskSuggession = (parentId: string): void => {
        const newTasks = [...this.state.tasks];
        const parentTask = newTasks.find(_ => _.id === parentId);
        if (!parentTask || !this.props.actions.buildNew) {
            return;
        }

        const taskSuggession: ITask = {
            ...this.props.actions.buildNew(),
            id: SubtaskSuggessionId
        };
        taskSuggession.attributes.Group = parentTask.attributes.Group;

        taskSuggession.hierarchy = {
            parentId: parentId,
            isParent: false,
            outlineLevel: parentTask.hierarchy.outlineLevel + 1,
        };

        const updatedParentTask = {
            ...parentTask,
            hierarchy: {
                ...parentTask.hierarchy,
                isParent: true
            }
        };

        newTasks.splice(newTasks.indexOf(parentTask), 1, updatedParentTask, taskSuggession);

        this.setState({ tasks: newTasks }, () => this._hierarchy.expand(parentId));
    }

    private _createTaskFromSuggesion = (name: string, taskSuggession: ITask) => {
        taskSuggession.attributes.Name = name;
        const index = this.state.tasks.findIndex(_ => this._isTaskSuggession(_));
        if (index === -1) {
            this.setState({ tasks: this.state.tasks.filter(_ => !this._isTaskSuggession(_)) });
            return;
        }

        const beforeTask = taskSuggession.id === TaskSuggessionId
            ? this.state.tasks[index + 1]
            : taskSuggession.id === SubtaskSuggessionId
                ? this.state.tasks
                    .filter(_ => !this._isTaskSuggession(_) && _.hierarchy.parentId === taskSuggession.hierarchy.parentId)
                    .sort((a: ITask, b: ITask) => sortTasksByRank(a, b))[0]
                : undefined;

        this.props.actions.create!({
            attributes: taskSuggession.attributes as unknown as Dictionary<unknown>,
            externalData: taskSuggession.externalData,
            insertBeforeId: beforeTask?.id,
            parentId: taskSuggession.id === SubtaskSuggessionId ? this.state.tasks.find(_ => _.id === taskSuggession.hierarchy.parentId)?.id : undefined
        });

        const tasks = [...this.state.tasks];
        tasks[index].id = getId();
        tasks[index].attributes.Name = name;
        this.setState({ tasks });

        analytics.trackEvent('Add new PPM Express task', this.props.user, { itemTitle: name });
    }

    private _removeTaskSuggesion = (id: string) => {
        const parentTaskIds = this.state.tasks
            .filter(_ => _.id === id && _.hierarchy.parentId)
            .map(_ => _.hierarchy.parentId!);
        const tasks = this.state.tasks.filter(_ => _.id !== id);

        tasks
            .filter(_ => parentTaskIds.includes(_.id))
            .forEach(_ => _.hierarchy.isParent = tasks.some(__ => __.hierarchy.parentId === _.id));

        this.setState({ tasks });
    }

    private _create = (data: ICreationData<ITaskAttrs>) => {
        this.props.actions.create!(data);
        analytics.trackEvent("Create PPM Express task", this.props.user, { itemTitle: data.attributes.Name });
    }

    private _update = (id: string, changes: Partial<ITaskAttrs>) => {
        const subentity = this.state.tasks.find(_ => _.id === id);
        if (changes?.Predecessor?.length && subentity?.isAutoMode && subentity?.hierarchy.isParent) {
            this.props.notificationsActions.pushNotification({
                type: NotificationsStore.NotificationType.Warn,
                message: 'Start/Due Date could not be calculated from related child tasks due to dependency constraint.'
            });
        }

        this.props.actions.update!(id, changes);
    }

    private _buildFilterHelper = (props: FilterHelperProps) => {
        return new FilterHelper(props);
    }

    private _buildTimelineRelationCommands = (relation: ITimelineRelation): IContextualMenuItem[] | undefined => {
        if (!relation) {
            return undefined;
        }
        return [
            !this.state.readOnly ? {
                key: 'delete',
                title: "Delete",
                iconProps: { iconName: 'Delete', style: { color: 'red' } },
                onClick: () => {
                    const updates: IPatch<ITask>[] = [];
                    const taskId = relation.isReverse ? relation.parent.key : relation.child.key;
                    const predcessorId = relation.isReverse ? relation.child.key : relation.parent.key;
                    const task = this.props.entity.tasks?.find(_ => _.id === taskId);
                    if (task) {
                        const predecessors = task.attributes.Predecessor?.filter((_: IPredecessorInfo) => _.id !== predcessorId) ?? [];
                        updates.push({ id: task.id, attributes: { [nameof<ITaskAttrs>("Predecessor")]: predecessors } });
                        this.props.actions.bulkUpdate(updates);
                    }
                }
            } : undefined
        ].filter(notUndefined);
    }

    private removeTaskGroupDialogMessageBuilder = (removeGroup: Metadata.Group) => {
        const metadataGroups = this._getGroups(this.props)!;
        return `Are you sure you want to delete Group "${removeGroup.name}"?
All tasks within the group will be automatically moved to "${(metadataGroups.filter(_ => _.id !== removeGroup.id).find(_ => _.isDefault)
                || metadataGroups.filter(_ => _.id !== removeGroup.id)[0]).name}" Group.`;
    }

    private _openTask = (task: ITask) => {
        const query = new URLSearchParams(this.props.location.search);
        query.set(EntityType.Task.toLowerCase(), task.id);

        this.props.history.replace({ search: query.toString(), state: this.props.location.state });
    }

    private _getGroups(props: Props): Metadata.Group[] | undefined {
        return props.datacontext.groups as Metadata.Group[] | undefined;
    }

    private saveGroup = (group: GroupInfo) => {
        if (group.id) {
            this.props.actions.updateGroup(group as Metadata.Group);
        } else {
            this.props.actions.createGroup(group as Metadata.IBaseGroupInfo);
        }
    }

    private removeGroup = (group: Metadata.Group) => {
        this.props.actions.removeGroup(group.id);
    }

    private reorderGroups = (groups: Metadata.Group[]) => {
        this.props.actions.reorderGroups(groups.map(_ => _.id));
    }

    private getCalculateTaskGroupSummaryFunc = (props: Props) => {
        const activeSourceType = props.datacontext.activeSourceInfo.type;
        const entity = props.entity;
        if (activeSourceType === SourceType.Ppmx) {
            switch (entity.settings.progressCalculationType) {
                case ProgressCalculationType.StoryPoints:
                    return TasksTimeline.calculateTaskGroupSummaryUsingStoryPoints;
                case ProgressCalculationType.AverageProgress:
                    return TasksTimeline.calculateTaskGroupSummaryUsingAverageProgress;
                default:
                    return TasksTimeline.calculateTaskGroupSummaryUsingEffort;
            }
        }

        if ([SourceType.MondayCom].includes(activeSourceType)) {
            return (tasks: ITask[], calendar: CalendarDataSet): GroupSummary => {
                const types = props.connectionTaskTypes ?? [];
                return TasksTimeline.calculateTaskGroupSummaryUsingAverageProgress(tasks, calendar, (_) => types.includes(_.attributes.Type!));
            };
        }

        if ([SourceType.O365Planner].includes(activeSourceType)) {
            return TasksTimeline.calculateTaskGroupSummaryUsingAverageProgress;
        }

        return TasksTimeline.calculateTaskGroupSummaryUsingEffort;
    }

    private _buildGroups(props: Props): EntityGroup[] | undefined {
        const calculateTaskGroupSummary = this.getCalculateTaskGroupSummaryFunc(props);

        const metadataGroups = this._getGroups(props);
        if (!metadataGroups) {
            return undefined;
        }
        let groupedTasks: Dictionary<ITask[]> = (props.entity.tasks || []).reduce(
            (result: Dictionary<ITask[]>, item: ITask) => ({
                ...result,
                [item.attributes.Group?.id || ungroupedGroup.key]: [...(result[item.attributes.Group?.id || ungroupedGroup.key] || []), item],
            }), {});

        const groups: EntityGroup[] = metadataGroups.filter(_ => _.isShown)
            .map<EntityGroup>(_ => ({
                key: _.id,
                name: _.name,
                color: _.color,
                count: (groupedTasks[_.id] || []).length,
                data: calculateTaskGroupSummary(groupedTasks[_.id] || [], props.calendar)
            }));

        let groupsMap = metadataGroups.reduce((result: Dictionary<Metadata.Group>, _) => ({ ...result, [_.id]: _ }), {});
        let missingGroups = (props.entity.tasks || []).filter(_ => !!_.attributes.Group && !groupsMap[_.attributes.Group.id])
            .reduce((result: Metadata.IGroupInfo[], _) => (result.findIndex(__ => __.id === _.attributes.Group!.id) === -1 ? [...result, _.attributes.Group!] : result), []);

        if (missingGroups.length > 0) {
            groups.push(...missingGroups.map<EntityGroup>(_ => ({
                key: _.id,
                name: `${_.name} (Missing)`,
                color: _.color,
                count: (groupedTasks[_.id] || []).length,
                data: calculateTaskGroupSummary(groupedTasks[_.id] || [], props.calendar)
            })));
        }

        if (!!groupedTasks[ungroupedGroup.key]) {
            groups.push({
                ...ungroupedGroup,
                count: (groupedTasks[ungroupedGroup.key] || []).length,
                data: calculateTaskGroupSummary(groupedTasks[ungroupedGroup.key] || [], props.calendar)
            });
        }

        return groups;
    }

    private renderGroupFooter(group: EntityGroup, user: UserState, tenant?: TenantState): JSX.Element | null {
        return <div className="with-group-marker group-footer align-center">
            <div className="group-marker" style={{ backgroundColor: group.color }} />
            <div className="container">
                <InlineInput 
                    inlineAddInputProps={{ 
                        types: [{ label: "Task", key: "task" }], 
                        onSave: (name) => this._saveInlineTask(name, group.key) 
                    }}
                    items={[{ 
                        iconName: 'D365CustomerInsights',
                        onClick: () => { 
                            analytics.trackEvent("Start with AI Schedule", user);
                            this.setState({ isAISchedulePanelOpen: true, selectedGroup: group })
                        },
                        label: "Generate with AI",
                        isVisible: !!(tenant && Subscription.contains(tenant.subscription, PPMFeatures.AiInsights)),
                        title: !this.props.tenant.aiInsights?.aiEnabled 
                            ? "To use AI features, PPM Insights AI should be enabled in the tenant by PPM Express Administrator" 
                            : undefined,
                        disabled: !this.props.tenant.aiInsights?.aiEnabled
                    }]} />
            </div>
        </div>
    }

    private _saveInlineTask = (name: string, groupKey: string) => {
        const group = this._getGroups(this.props)?.find(_ => _.id === groupKey);
        const newTask = this.props.actions.buildNew!();
        newTask.id = uuidv4();
        newTask.attributes.Name = name;
        newTask.attributes.Group = group && { id: group.id, name: group.name, color: group.color };

        this.setState({tasks: [...this.state.tasks, newTask]});

        this._inlineTasksToCreate.push({
            id: newTask.id,
            attributes: newTask.attributes,
            externalData: newTask.externalData
        });
        this._debouncedOnSaveInlineTask();
        analytics.trackEvent('Add new PPM Express task', this.props.user, { itemTitle: name });
    }

    private _bulkSaveInlineTasks = () => {
        if (this._inlineTasksToCreate.length) {
            this.props.actions.bulkCreate(this._inlineTasksToCreate);
            this._inlineTasksToCreate = [];
        }
    }

    private _updateTaskDates = (task: ITask, startDate?: Date, dueDate?: Date) => {
        const patch: Partial<ITaskAttrs> = {};
        if (startDate !== undefined) {
            patch.StartDate = startDate.toDateOnlyString();
        } else if (dueDate !== undefined) {
            patch.DueDate = dueDate.toDateOnlyString();
        }
        // StartDate resize should update Duration, not move DueDate
        const isResize = startDate !== undefined && dueDate === undefined || startDate === undefined && dueDate !== undefined;
        const extra = handleDuration(task.attributes, patch, this.props.calendar, !isResize);
        this.props.actions.update!(task.id, { ...patch, ...extra });
    }

    private _buildGrouping = (): TimelineList.TimelineGroupingProps => ({
        getGroupRow: (group: EntityGroup, rows: TimelineList.IRow[]): TimelineList.IRow => TasksTimeline.getGroupRow(group)
    });

    private _moveToGroup = (selectedItems: ITask[], group: Metadata.Group) => {
        if (selectedItems.length) {
            const updates: IPatch<ITask>[] = [];
            selectedItems.forEach(_ => {
                _.attributes.Group = group && { id: group.id, name: group.name, color: group.color };
                updates.push({ id: _.id, attributes: { Group: _.attributes.Group } });
            });
            this.props.actions.bulkUpdate(updates);
        }
    }

    private _link = (selectedItems: ITask[]) => {
        if (selectedItems.length < MIN_COUNT_TO_LINK) {
            return;
        }
        const predecessorsMap = buildPredecessorsMap(this.props.entity.tasks);
        const updates: IPatch<ITask>[] = [];
        for (let i = 1; i < selectedItems.length; i++) {
            const predecessor = selectedItems[i - 1];
            const task = selectedItems[i];
            if (!predecessorsMap[predecessor.id]?.[task.id]
                && predecessor.hierarchy.parentIds?.indexOf(task.id) === -1
                && task.hierarchy.parentIds?.indexOf(predecessor.id) === -1
                && !task.attributes.Predecessor?.find(_ => _.id === predecessor.id)) {
                task.attributes.Predecessor = [
                    ...task.attributes.Predecessor ?? [],
                    { id: predecessor.id, name: predecessor.attributes.Name, lag: 0, type: PredecessorType.FinishToStart }];
                updates.push({ id: task.id, attributes: { Predecessor: task.attributes.Predecessor } });
            }
        }
        if (updates.length) {
            this.props.actions.bulkUpdate(updates);
        }
    }

    private _unlink = (selectedItems: ITask[]) => {
        if (!selectedItems.length) {
            return;
        }

        const updates: IPatch<ITask>[] = [];
        selectedItems.forEach(task => {
            if (!!task.attributes.Predecessor?.length) {
                task.attributes.Predecessor = [];
                updates.push({ id: task.id, attributes: { Predecessor: task.attributes.Predecessor } });
            }
        })
        if (updates.length) {
            this.props.actions.bulkUpdate(updates);
        }
    }

    private _setProgress = (selectedItems: ITask[], value: number) => {
        if (!selectedItems.length) {
            return;
        }

        const taskStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Task, this.props.fields)!;
        const updates: IPatch<ITask>[] = selectedItems
            .filter(_ => allowEditTaskProgress(this.state.readOnly, _, this.props.user.resource?.id))
            .map(_ => {
                if (value == 100 && _.attributes.Progress != 100) {
                    _.attributes.Status = taskStatusDescriptor.getCategoryDefaultStatusValue(StatusCategory.Done);
                }

                const attrUpdates: Partial<ITaskAttrs> = {
                    Progress: value,
                    ...TaskProgressCalculator.RecalculateByProgress(this.props.entity, _, value)
                };
                _.attributes = { ..._.attributes, ...attrUpdates };
                return { id: _.id, attributes: attrUpdates };
            });
        this.props.actions.bulkUpdate(updates);
    }

    private _setCalculationMode = (isAutoMode: boolean, selectedItems: ITask[]) => {
        if (!selectedItems.length) {
            return;
        }

        const selectedIds = selectedItems.map(_ => _.id);
        if (isAutoMode && selectedIds.length > 1) {
            this.setState({ isOpenAutoModeConfirmation: selectedIds });
        } else {
            this.props.actions.updateCalculationMode(isAutoMode, selectedIds);
        }
    }

    private _reportTime = (item: ITask, seconds: number, date: Date) => {
        this.props.actions.reportTime(item, seconds, date);
    }

    private _showSetBaselineConfirmation = (selectedItems: ITask[]) => {
        this.setState({ setBaselineSelectedEntities: selectedItems })
    }

    private _setBaseline = (selectedItems: ITask[]) => {
        if (selectedItems.length) {
            this.props.actions.setBaseline(selectedItems.map(_ => _.id));
            analytics.trackEvent('Set tasks baseline', this.props.user, { count: selectedItems.length });
        }
    }

    private _clone = (selectedItems: ITask[]) => {
        if (selectedItems.length) {
            const toCreate: ICreationData[] = [];
            const map: { [id: string]: string } = {};
            selectedItems.forEach(_ => {
                const newItem = ({
                    id: uuidv4(),
                    attributes: { ..._.attributes },
                    externalData: {},
                    isAutoMode: _.isAutoMode,
                    parentId: _.hierarchy.parentId && map[_.hierarchy.parentId]
                });
                map[_.id] = newItem.id!;
                toCreate.push(newItem);
            })

            this.props.actions.bulkCreate(toCreate);
        }
    }

    private _assignTo = (selectedItems: ITask[], value: IUserInfo[]) => {
        if (selectedItems.length) {
            const updates: IPatch<ITask>[] = [];
            selectedItems.forEach(_ => {
                _.attributes.AssignedTo = value;
                updates.push({ id: _.id, attributes: { AssignedTo: _.attributes.AssignedTo } });
            });
            this.props.actions.bulkUpdate(updates);
        }
    }

    private _buildSelectionModeCommands = (selectedItems: ITask[]): IContextualMenuItem[] => {
        const metadataGroups = this._getGroups(this.props);
        const allowChangeProgress = allowEditTasksProgress(this.state.readOnly, selectedItems, this.props.user.resource?.id);
        const isHierarchyView = this._isHierarchyView();

        return [
            allowChangeProgress ? {
                key: "setProgress",
                name: "Set progress",
                title: "Set one of the predefined values into Progress, % for the selected tasks.\nFor tasks in Auto mode progress-related fields will be automatically recalculated.\nFor tasks in Manual mode progress-related fields will need to be manually updated",
                iconProps: { iconName: "Completed" },
                subMenuProps: {
                    items: ProgressScale.map((_) => ({
                        key: _.toString(),
                        name: formatValue(_, Metadata.FormatType.Percent),
                        title: `Set ${_.toString()}% Progress for the selected tasks`,
                        className: "progress-formatter",
                        onRenderIcon: () => <ProgressIcon progress={_} />,
                        iconProps: {},
                        onClick: () => this._setProgress(selectedItems, _),
                    })),
                },
            } : undefined,
            !this.state.readOnly ? {
                key: "Indent",
                name: `Indent`,
                title: !isHierarchyView ? FeatureForAllTasksFilterMessage :
                    'Indent the selected Tasks to reposition them lower in the hierarchy, one level at a time,\nthereby transforming them into subtasks of the nearest preceding Task in the Tasks list',
                iconProps: { iconName: "PPMXIndentArrow" },
	            disabled: !isHierarchyView,
                onClick: () => this._indent(selectedItems),
            } : undefined,
            !this.state.readOnly ? {
                key: "Outdent",
                name: `Outdent`,
                title: !isHierarchyView ? FeatureForAllTasksFilterMessage :
                    'Outdent the selected Tasks to reposition them higher in the hierarchy, one level at a time,\nthereby removing their subtask status and placing them at the same level as their nearest preceding Task',
                iconProps: { iconName: "PPMXOutdentArrow" },
	            disabled: !isHierarchyView,
	            onClick: () => this._outdent(selectedItems),
            } : undefined,
            !this.state.readOnly ? {
                key: "Link",
                name: `Link`,
                title: "Link the selected Tasks by creating Finish-to-Start dependencies between them",
                iconProps: { iconName: "AddLink" },
                onClick: () => this._link(selectedItems),
            } : undefined,
            !this.state.readOnly ? {
                key: "Unlink",
                name: `Unlink`,
                title: "Remove the link between the selected Tasks, thereby removing all predecessors from the selected Tasks",
                iconProps: { iconName: "RemoveLink" },
                onClick: () => this._unlink(selectedItems),
            } : undefined,
            !this.state.readOnly ? {
                key: "assignTo",
                name: "Assign to",
                title: "Assign one or multiple Resource to the selected Tasks",
                iconProps: { iconName: "PPMXResource" },
                subMenuProps: {
                    items: [
                        {
                            key: "personPicker",
                            onRender: () => (
                                <div style={{ maxWidth: "280px" }}>
                                    <PersonPickerInput
                                        inputProps={{ placeholder: selectedItems[0]?.attributes.AssignedTo?.length ? undefined : "Select resource" }}
                                        value={selectedItems[0]?.attributes.AssignedTo}
                                        multichoice={true}
                                        onChanged={(value) => {
                                            this._assignTo(selectedItems, value);
                                        }}
                                        searchUrl="api/resource/find"
                                    />
                                </div>
                            ),
                        },
                    ],
                },
            } : undefined,
            !this.state.readOnly && this.props.entity.isEditable ? {
                key: "SetBaseline",
                name: `Set Baseline`,
                title: "Take a snapshot of selected Tasks that includes information about task’s Start Date,\nDue Date, Duration, Effort and Original Estimate",
                iconProps: { iconName: "PPMXBaseline" },
                onClick: () => this._showSetBaselineConfirmation(selectedItems),
            } : undefined,
            !this.state.readOnly ? {
                key: "clone",
                name: "Clone",
                title: "Clone the selected Tasks; cloned tasks will be added to the end of the Tasks list",
                iconProps: { iconName: "Copy" },
                onClick: () => this._clone(selectedItems),
            } : undefined,
            !this.state.readOnly ? {
                key: "SetMode",
                name: "Set Mode",
                title: "Set Progress calculation mode for the selected Tasks",
                iconProps: { iconName: "PPMXAutoSchedule" },
                subMenuProps: { items: this.buildScheduleModeMenu(selectedItems, true, false) },
            } : undefined,
            !this.state.readOnly ? {
                key: "CalculateRollup",
                name: `Calculate Progress`,
                title: "Calculate Progress, % and progress-related fields for the selected Tasks in the Manual mode:\nrollup calculation is performed for summary Tasks,\nand horizontal calculation is performed for non-summary Tasks",
                iconProps: { iconName: "PPMXCalculateProgress" },
                onClick: () => this._rollupTasks(selectedItems),
            } : undefined,
            !this.state.readOnly && metadataGroups ? {
                key: "moveToGroup",
                name: `Move to Group`,
                title: "Move the selected Tasks to one of the existing Groups",
                iconProps: { iconName: "FabricMovetoFolder" },
                subMenuProps: metadataGroups && {
                    items: metadataGroups.map((_) => ({
                        key: _.id,
                        name: _.name,
                        className: "group",
                        iconProps: { className: "group-color", style: { backgroundColor: _.color } },
                        onClick: () => this._moveToGroup(selectedItems, _),
                    })),
                },
            } : undefined,
        ].filter(notUndefined);
    }

    private _buildActionsCommandItems = (): IContextualMenuItem[] => {
        return [
            !this.state.readOnly ? {
                key: "ManageGroups",
                name: `Manage Groups`,
                title: "Configure Task Groups",
                iconProps: { iconName: "GroupList" },
                onClick: this.props.switchToConfigureMode,
            } : undefined
        ].filter(notUndefined);
    }

    private buildScheduleModeMenu = (selectedItems: ITask[], showTitle: boolean, showSelector: boolean): IContextualMenuItem[] => {
        return [
            {
                key: "Auto",
                name: "Auto",
                title: showTitle ? "Horizontal and vertical (rollup) calculation for progress-related fields is performed automatically" : undefined,
                iconProps: {
                    iconName: selectedItems.length === 1 && showSelector
                        ? selectedItems[0].isAutoMode ? "CheckMark" : ""
                        : "PPMXAutoSchedule"
                },
                onClick: () => this._setCalculationMode(true, selectedItems)
            },
            {
                key: "Manual",
                name: "Manual",
                title: showTitle ? "Horizontal and vertical (rollup) calculation for progress-related fields is performed on-demand" : undefined,
                iconProps: {
                    iconName: selectedItems.length === 1 && showSelector
                        ? !selectedItems[0].isAutoMode ? "CheckMark" : ""
                        : "PPMXManualSchedule"
                },
                onClick: () => this._setCalculationMode(false, selectedItems)
            }
        ];
    }

    private _isTaskSuggession = (task: ITask): boolean => task.id === SubtaskSuggessionId || task.id === TaskSuggessionId;

    private _getItemCommands = (selectedItems: ITask[], currentItem?: ITask, filterContext?: SubentitiesListHierarchyContext<ITask>) => {
        if (selectedItems.some(_ => this._isTaskSuggession(_))) {
            return [];
        }
        const { readOnly, expandedInFilter, tasks } = this.state;
        const metadataGroups = this._getGroups(this.props);
        const selectedItem = selectedItems.length === 1 ? selectedItems[0] : undefined;
        const isHierarchyView = TaskHierarchy.isHierarchyView(this.props.datacontext.activeSourceInfo.type, filterContext?.filter, filterContext?.prefilter);
        const allowChangeProgress = allowEditTasksProgress(readOnly, selectedItems, this.props.user.resource?.id);

        const showExpandHiearchyMenu = !!currentItem && !isHierarchyView
            && tasks.find(_ => _.id === currentItem?.id)?.hierarchy.isParent
            && !this._hierarchy.getParentKey(currentItem);

        const items: IContextualMenuItem[] =
            [
                !readOnly ? {
                    key: 'assignTo',
                    name: 'Assign to',
                    iconProps: { iconName: "PPMXResource" },
                    subMenuProps: {
                        items:
                            [{
                                key: "personPicker",
                                onRender: () => <div style={{ maxWidth: '280px' }}>
                                    <PersonPickerInput
                                        inputProps={{ placeholder: selectedItems[0]?.attributes.AssignedTo?.length ? undefined : 'Select resource' }}
                                        value={selectedItems[0]?.attributes.AssignedTo}
                                        multichoice={true}
                                        onChanged={(value) => { this._assignTo(selectedItems, value); }}
                                        searchUrl="api/resource/find" /></div>
                            }]
                    }
                } : undefined,
                !readOnly && this.props.entity.isEditable ? {
                    key: 'setBaseline',
                    name: 'Set Baseline',
                    iconProps: { iconName: "PPMXBaseline" },
                    onClick: () => this._showSetBaselineConfirmation(selectedItems)
                } : undefined,
                (!readOnly || selectedItems.some(_ => allowEditReadOnlyProgress(selectedItem, this.props.user.resource?.id))) && allowChangeProgress ? {
                    key: 'setProgress',
                    name: 'Set progress',
                    iconProps: { iconName: "Completed" },
                    subMenuProps: {
                        items: ProgressScale.map(_ => (
                            {
                                key: _.toString(),
                                name: formatValue(_, Metadata.FormatType.Percent),
                                className: "progress-formatter",
                                onRenderIcon: () => <ProgressIcon progress={_} />,
                                iconProps: {},
                                onClick: () => this._setProgress(selectedItems, _)
                            }))
                    }
                } : undefined,
                this._buildReportTimeMenuItem(readOnly, selectedItems),
                !readOnly ? { key: 'divider_1', itemType: ContextualMenuItemType.Divider } : undefined,
                !readOnly ? {
                    key: "add-subtask",
                    name: "Add subtask",
                    iconProps: { iconName: "Add" },
                    disabled: !selectedItem || !isHierarchyView,
                    title: !isHierarchyView ? FeatureForAllTasksFilterMessage : undefined,
                    onClick: () => this._createSubtaskSuggession(selectedItems[0].id)
                } : undefined,
                showExpandHiearchyMenu && !!currentItem && !expandedInFilter.includes(this._hierarchy.getKey(currentItem)) ? {
                    key: "expand-hierarchy",
                    name: "Expand hierarchy",
                    iconProps: { iconName: "PPMXExpandAll" },
                    onClick: () => {
                        this.setState({ expandedInFilter: [...expandedInFilter, this._hierarchy.getKey(currentItem)] });
                        this._hierarchy.expand(currentItem.id, tasks.reduce((lvl, t) => Math.max(lvl, t.hierarchy.outlineLevel), 0));
                    }
                } : undefined,
                showExpandHiearchyMenu && !!currentItem && expandedInFilter.includes(this._hierarchy.getKey(currentItem)) ? {
                    key: "collapse-hierarchy",
                    name: "Collapse hierarchy",
                    iconProps: { iconName: "PPMXCollapseAll" },
                    onClick: () => {
                        this.setState({ expandedInFilter: expandedInFilter.filter(_ => _ !== this._hierarchy.getKey(currentItem)) });
                        this._hierarchy.collapse(currentItem);
                    }
                } : undefined,
                !readOnly ? {
                    key: "outdent-task",
                    name: "Outdent",
                    iconProps: { iconName: "PPMXOutdentArrow" },
                    disabled: !isHierarchyView,
                    title: !isHierarchyView ? FeatureForAllTasksFilterMessage : undefined,
                    onClick: () => this._outdent(selectedItems)
                } : undefined,
                !readOnly ? {
                    key: "indent-task",
                    name: "Indent",
                    iconProps: { iconName: "PPMXIndentArrow" },
                    disabled: !isHierarchyView,
                    title: !isHierarchyView ? FeatureForAllTasksFilterMessage : undefined,
                    onClick: () => this._indent(selectedItems)
                } : undefined,
                !readOnly ? {
                    key: "insert-task-above",
                    name: "Insert task above",
                    iconProps: { iconName: "Insert" },
                    disabled: !selectedItem || !isHierarchyView,
                    title: !isHierarchyView ? FeatureForAllTasksFilterMessage : undefined,
                    onClick: () => this._createTaskSuggessionBefore(selectedItems[0].id)
                } : undefined,
                !readOnly ? {
                    key: "calculate-rollup",
                    name: "Calculate progress",
                    iconProps: { iconName: "PPMXCalculateProgress" },
                    onClick: () => this._rollupTasks(selectedItems)
                } : undefined,
                !readOnly ? {
                    key: "link-task",
                    name: "Link",
                    iconProps: { iconName: "AddLink" },
                    onClick: () => this._link(selectedItems)
                } : undefined,
                !readOnly ? {
                    key: "unlink-task",
                    name: "Unlink",
                    iconProps: { iconName: "RemoveLink" },
                    onClick: () => this._unlink(selectedItems)
                } : undefined,
                !readOnly ? {
                    key: 'set-calculation-mode',
                    name: 'Set Mode',
                    iconProps: { iconName: "PPMXAutoSchedule" },
                    subMenuProps: { items: this.buildScheduleModeMenu(selectedItems, false, true) }
                } : undefined,
                { key: 'divider_2', itemType: ContextualMenuItemType.Divider },
                !readOnly ? {
                    key: 'clone',
                    name: 'Clone Task',
                    iconProps: { iconName: "Copy" },
                    onClick: () => this._clone(selectedItems)
                } : undefined,
                !readOnly && metadataGroups ? {
                    key: 'moveToGroup',
                    name: 'Move to group',
                    iconProps: { iconName: "FabricMovetoFolder" },
                    subMenuProps: {
                        items: metadataGroups.map(_ => (
                            {
                                key: _.id,
                                name: _.name,
                                className: "group",
                                iconProps: { className: "group-color", style: { backgroundColor: _.color } },
                                onClick: () => this._moveToGroup(selectedItems, _)
                            }))
                    }
                } : undefined
            ].filter(notUndefined);

        return items;
    }

    private _buildReportTimeMenuItem = (
        readOnly: boolean | undefined,
        selectedItems: ITask[],

    ): IContextualMenuItem | undefined => {
        if (this.props.entity.isPrivate) {
            return undefined;
        }

        if (this.props.user.resource &&
            selectedItems.length > 0 &&
            selectedItems[0].attributes.AssignedTo?.find(_ => _.id === this.props.user.resource?.id)) {
         
            const hasTimeTrackingFeature = Subscription.contains(this.props.tenant.subscription, PPMFeatures.TimeTracking);
            const isNewTimeTrackingEnabled = hasTimeTrackingFeature && this.props.tenant.timeTracking.globalSettings.newTimeTrackingEnabled;
            const isDisabled = selectedItems.length !== 1;

            if (isNewTimeTrackingEnabled) {
                return TimeTrackingReportTimeMenu.buildReportTimeMenuItem(
                    (hours: number, date: Date) => this._reportTime(selectedItems[0], hours * 60 * 60, date),
                    isDisabled,
                    this.props.tenant.timeTracking.globalSettings);
            }

            const isLinkedToPpmxTime = this.props.entity.sourceInfos.some(_ => _.type === SourceType.PpmxTime && _.sourceData.isLinked);

            if (isLinkedToPpmxTime && !readOnly) {
                return TimePortalService.buildReportTimeMenuItem(
                    this.props.timePortalUrl,
                    (seconds: number, date: Date) => this._reportTime(selectedItems[0], seconds, date),
                    isDisabled);
            }
        }

        return undefined;
    }

    private _indent = (selectedItems: ITask[]) => {
        if (selectedItems.length) {
            this.props.actions.indent(selectedItems.map(_ => _.id));
            selectedItems.forEach(_ => {
                const prevtask = this._findPrevTask(_.id);
                if (prevtask && this._hierarchy.getExpandedIds().indexOf(prevtask.id) === -1) {
                    this._hierarchy.expand(prevtask.id);
                }
            });
        }
    }

    private _outdent = (selectedItems: ITask[]) => this.props.actions.outdent(selectedItems.map(_ => _.id));

    private _findPrevTask = (id: string): ITask | undefined => {
        const task = this.state.tasks.find(_ => _.id === id);
        if (!task) {
            return undefined;
        }
        const tasks = this.state.tasks.filter(_ => _.attributes.Group?.id === task?.attributes.Group?.id
            && _.hierarchy.outlineLevel === task?.hierarchy.outlineLevel
            && _.hierarchy.parentId === task?.hierarchy.parentId)
        const index = tasks.findIndex(_ => _.id === id);
        if (index <= 0) {
            return undefined;
        }

        return tasks[index - 1];
    }

    private _getPrefilterItems(): { [K: number]: Metadata.PreFilterOption<ITask>[] } {
        return {
            [SourceType.Ppmx]: this._ppmxPrefilterItems(),
            [SourceType.O365Planner]: defaultPrefilterItems(),
            [SourceType.Spo]: defaultPrefilterItems(),
            [SourceType.MondayCom]: defaultPrefilterItems(),
            [SourceType.VSTS]: this._vstsPrefilterItems(),
            [SourceType.Jira]: this._jiraPrefilterItems(),
            [SourceType.Smartsheet]: defaultPrefilterItems(),
            [SourceType.MPPFile]: defaultPrefilterItems(),
            [SourceType.P4W]: defaultPrefilterItems(),
        }
    }

    private _ppmxPrefilterItems = (): Metadata.PreFilterOption<ITask>[] => {
        const result = defaultPrefilterItems();
        const index = result.findIndex(_ => _.key === Metadata.TaskPrefilterKey.late) + 1;
        result.splice(index, 0,
            {
                key: Metadata.TaskPrefilterKey.slipped,
                name: 'Slipped Tasks',
                predicate: _ => _.attributes.Progress !== 100 && !!_.baseline && (
                    (!!_.baseline.startDate && !!_.attributes.StartDate && toDate(_.baseline.startDate)! < toDate(_.attributes.StartDate)!) ||
                    (!!_.baseline.effort && !!_.attributes.Effort && _.baseline.effort < _.attributes.Effort) ||
                    (!!_.baseline.originalEstimate && !!_.attributes.OriginalEstimate && _.baseline.originalEstimate < _.attributes.OriginalEstimate) ||
                    (!!_.baseline.dueDate && !!_.attributes.DueDate && toDate(_.baseline.dueDate)! < toDate(_.attributes.DueDate)!) ||
                    (!!_.baseline.duration && !!_.attributes.Duration && _.baseline.duration < _.attributes.Duration))
            });
        return result;
    }

    private _vstsPrefilterItems = (): Metadata.PreFilterOption<ITask>[] => {
        const { entity } = this.props;
        const currentIteration = IterationSelect.getCurrent(entity.iterations, SourceType.VSTS);
        const ranges = getDateRanges();
        return [{
            key: Metadata.TaskPrefilterKey.today, name: 'Today', predicate: InDateRangeFilter(ranges.today.begin, ranges.today.end)
        }, {
            key: Metadata.TaskPrefilterKey.thisweek, name: 'This Week', predicate: InDateRangeFilter(ranges.thisWeek.begin, ranges.thisWeek.end)
        }, {
            key: Metadata.TaskPrefilterKey.currentIteration, name: 'Current Iteration', predicate: (_: ITask) => _.attributes.Iteration === currentIteration?.externalData?.["IterationPath"]
        }];
    }

    private _jiraPrefilterItems = (): Metadata.PreFilterOption<ITask>[] => {
        const { entity } = this.props;
        const currentIteration = IterationSelect.getCurrent(entity.iterations, SourceType.Jira);
        const ranges = getDateRanges();
        return [{
            key: Metadata.TaskPrefilterKey.today, name: 'Today', predicate: InDateRangeFilter(ranges.today.begin, ranges.today.end)
        }, {
            key: Metadata.TaskPrefilterKey.thisweek, name: 'This Week', predicate: InDateRangeFilter(ranges.thisWeek.begin, ranges.thisWeek.end)
        }, {
            key: Metadata.TaskPrefilterKey.currentIteration, name: 'Current Iteration', predicate: (_: ITask) => _.attributes.Iteration === currentIteration?.attributes.Name
        }];
    }

    private _checkDnDLimitation(props: Props, prevValue?: boolean): boolean | undefined {
        if (!this._hierarchy.allowDrag()) {
            return undefined;
        }

        if (!prevValue && props.controlSettings.viewType === 'Timeline'
            && props.entity.tasks && props.entity.tasks.length > MAX_ROWS_COUNT_FOR_CORRECT_DND) {
            props.notificationsActions.pushNotification({
                type: NotificationsStore.NotificationType.Info,
                message: "Drag & Drop is not supported for large number of tasks on Timeline View. Please use List View for this purpose."
            });

            return true;
        }

        if (prevValue && props.controlSettings.viewType === 'Details') {
            return false;
        }

        return undefined;
    }

    private _rollupTasks = (selectedItems: ITask[]) => {
        this.props.actions.rollupTasks(selectedItems.map(_ => _.id));
        if (selectedItems.some(_ => !!_.attributes.Predecessor?.length && _.hierarchy?.isParent)) {
            this.props.notificationsActions.pushNotification({
                type: NotificationsStore.NotificationType.Warn,
                message: "Start/Due Date could not be calculated from related child tasks due to dependency constraint."
            });
        }
    }
}

function getDateRanges() {
    return {
        today: {
            begin: new Date().getBeginOfDay(),
            end: new Date().getEndOfDay()
        },
        thisWeek: {
            begin: new Date().getWeekStartDate().getBeginOfDay(),
            end: new Date().getWeekStartDate().addDays(6).getEndOfDay()
        }
    }
}

export function calculateTaskVariance(updatedTask: ITask, task: ITask | undefined, calendar: CalendarDataSet): TaskVariance | undefined {
    if (!task || !task.baseline) {
        return undefined;
    }

    const startDateVariance = task.attributes.StartDate === updatedTask.attributes.StartDate
        ? task.baseline.startDateVariance
        : TaskVarianceCalculator.CalculateDateVariance(updatedTask.attributes.StartDate, task.baseline.startDate, calendar);

    const dueDateVariance = task.attributes.DueDate === updatedTask.attributes.DueDate
        ? task.baseline.dueDateVariance
        : TaskVarianceCalculator.CalculateDateVariance(updatedTask.attributes.DueDate, task.baseline.dueDate, calendar);

    const durationVariance = task.attributes.Duration === updatedTask.attributes.Duration
        ? task.baseline.durationVariance
        : TaskVarianceCalculator.CalculateVariance(updatedTask.attributes.Duration, task.baseline.duration);

    const effortVariance = task.attributes.Effort === updatedTask.attributes.Effort
        ? task.baseline.effortVariance
        : TaskVarianceCalculator.CalculateVariance(updatedTask.attributes.Effort, task.baseline.effort);

    const originalEstimateVariance = task.attributes.OriginalEstimate === updatedTask.attributes.OriginalEstimate 
        ? task.baseline.originalEstimateVariance
        : TaskVarianceCalculator.CalculateVariance(updatedTask.attributes.OriginalEstimate, task.baseline.originalEstimate);

    return {
        startDateVariance,
        dueDateVariance,
        durationVariance,
        effortVariance,
        originalEstimateVariance
    }
}

function mapStateToProps(state: ApplicationState, ownProps: ExternalTasksControlProps): StateProps {
    const fields = state.fields[EntityType.Task];
    const projectsList = ownProps.entity.isArchived ? state.archivedProjectsList : state.projectsList;
    return {
        user: state.user,
        calendar: state.calendar,
        isListLoading: projectsList.tasks.isListLoading,
        isListUpdating: projectsList.tasks.isLoading,
        timePortalUrl: state.time.portalUrl,
        tenant: state.tenant,
        fields: fields.allIds.map(_ => fields.byId[_]),
        connectionTaskTypes: ownProps.datacontext.activeSourceInfo?.type === SourceType.MondayCom
            ? state.mondayCom.progressCalculationSettings.entities.types
            : undefined,
    };
}

function mergeActionCreators(dispatch: any): ActionsProps {
    return {
        notificationsActions: bindActionCreators(NotificationsStore.actionCreators, dispatch)
    }
}

export default withRouter(connect(mapStateToProps, mergeActionCreators)(TasksControl));

export function getMandatoryEditFields(type: SourceType | null) {
    if (type === SourceType.Ppmx) {
        return namesof<ITaskAttrs>(['Name', 'Description', 'Group', 'Predecessor', 'Duration', 'StartDate', 'DueDate', 'IsMilestone', 'Progress', 'AssignedTo',
            'CompletedWork', 'Effort'
        ]);
    }
    if (type === SourceType.O365Planner) {
        return namesof<ITaskAttrs>(['Name', 'Description', 'Group', 'Duration', 'StartDate', 'DueDate', 'Progress', 'AssignedTo', 'Priority', 'Tags', 'Status']);
    }
    if (type === SourceType.Spo) {
        return namesof<ITaskAttrs>([
            'Name', 'Duration', 'StartDate', 'DueDate', 'Progress', 'AssignedTo', 'Effort', 'CompletedWork', 'RemainingWork', 'WBS', 'Status', 'Priority', 'Type'
        ]);
    }
    if (type === SourceType.VSTS) {
        return namesof<ITaskAttrs>([
            'Name', 'Description', 'Type', 'AreaPath', 'Iteration', 'AssignedTo', 'State', 'Priority', 'Duration', 'StartDate', 'DueDate', 'Effort', 'CompletedWork',
            'RemainingWork', 'StoryPoints', 'Tags', 'Progress', 'Parent', 'ParentName', 'Status',
        ]);
    }
    if (type === SourceType.Jira) {
        return namesof<ITaskAttrs>([
            'Name', 'Description', 'Type', 'State', 'Iteration', 'AssignedTo', 'Duration', 'StartDate', 'DueDate', 'Priority', 'Effort', 'CompletedWork', 'RemainingWork',
            'StoryPoints', 'Tags', 'Parent', 'Progress', 'Status'
        ])
    }
    if (type === SourceType.MondayCom) {
        return namesof<ITaskAttrs>(['Name', 'Group', 'StartDate', 'DueDate', 'Progress', 'AssignedTo', 'Status', 'Type']);
    }
    if (type === SourceType.Smartsheet) {
        return namesof<ITaskAttrs>(['Name', 'Description', 'Duration', 'StartDate', 'DueDate', 'Progress', 'AssignedTo', 'Status', 'Priority', 'Type']);
    }
    if (type === SourceType.MPPFile) {
        return namesof<ITaskAttrs>([
            'Name', 'Duration', 'StartDate', 'DueDate', 'Progress', 'AssignedTo', 'Effort', 'CompletedWork', 'RemainingWork', 'WBS', 'Status', 'Priority', 'Type'
        ]);
    }
    if (type === SourceType.P4W) {
        return namesof<ITaskAttrs>([
            'Name', 'Description', 'Duration', 'StartDate', 'DueDate', 'Progress', 'AssignedTo', 'Effort', 'CompletedWork', 'RemainingWork', 'WBS', 'Status', 'Priority', 'Type', 'IsMilestone', 'Tags'
        ]);
    }

    return [];
}