import { Field } from "./Metadata";
import { CategoryOption, StageCategory } from "./common";

export type IStageDropDownSettings = {
    options: CategoryStageOption[];
}

export type CategoryStageOption = CategoryOption<StageCategory>;

export default class StageDescriptor {
    private readonly _stageField: Field;

    constructor(stageField: Field) {
        this._stageField = stageField;
    }

    public getOption(stageValue: string): CategoryStageOption | undefined {
        const options = this.getOptions();
        return options?.find(_ => _.name === stageValue);
    }

    public getOptions(): CategoryStageOption[] {
        const stageSettings = this._stageField.settings as IStageDropDownSettings;
        return stageSettings.options;
    }
}
