import * as React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from 'react-router-dom';
import { ProjectInfo, ProjectAttrs } from '../../../store/ProjectsListStore';
import { IEntityCardProps } from '../../common/interfaces/IEntity';
import * as Metadata from "../../../entities/Metadata";
import { Dictionary, EntityType, IUserInfo, StatusCategory, ppmxTaskConnectionId } from "../../../entities/common";
import { toPercentString, FormatDate, formatFieldValue } from "../../utils/common";
import { ActiveFilter } from '../../../store/project/filters';
import FavoriteToggle from '../../common/FavoriteToggle';
import LinkedSystemIcons from '../../common/sectionsControl/uiControls/summaryControl/LinkedSystemIcons';
import ProjectLogo from '../../project/ProjectLogo';
import CategoryColorStatusView from '../list/columns/CategoryColorStatusView';
import CardMetric, { CardMetricConfig } from './CardMetric';
import EntityWarning from '../../common/warnings/EntityWarning';
import { nameof } from '../../../store/services/metadataService';
import TasksIcon from '../../common/sectionsControl/uiControls/projectSummaryControl/TasksIcon';
import { getTasksMetrics, getPortfolios } from '../../../store/project/utils';
import ResourceFormatter from '../../common/formatters/ResourceFormatter';
import { UserState, isAdminViewer, isInReadonlyMode } from '../../../store/User';
import PortfolioFormatter from '../../common/formatters/PortfolioFormatter';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import ProgramFormatter from '../../common/formatters/ProgramFormatter';
import { CostsKPI } from './KPICard';
import * as StatusDescriptorFactory from '../../../entities/StatusDescriptorFactory';
import PrivateProjectIcon from '../../common/PrivateProjectIcon';

export type ProjectCardState = {}
type ProjectCardOwnProps = IEntityCardProps<ProjectInfo, ProjectCardState> & {
    showCosts?: boolean;
    onMenuRender: (entity: ProjectInfo) => JSX.Element | null;
    fields: Dictionary<Metadata.Field>;
    onFavoriteChange: (IsFavorite: boolean) => void;
}

type StoreProps = {
    user: UserState;
    isReadonlyMode: boolean;
};

type ProjectCardProps = ProjectCardOwnProps & StoreProps & RouteComponentProps<{}>;

class ProjectCard extends React.Component<ProjectCardProps> {
    public render() {
        const { entity, fields } = this.props;

        const attrs = entity.attributes;
        const projectUrl = `/project/${entity.id}`;

        const portfolioInfos = getPortfolios(entity);

        const statusField = fields[nameof<ProjectAttrs>("OverallStatus")];
        const statusDescriptor = StatusDescriptorFactory.createStatusDescriptor(statusField)!;
        const statusOption = statusDescriptor.getOptionOrDefault(attrs.OverallStatus, StatusCategory.NA);

        return (
            <div className="card flex-card proj-card" onClick={(e) => {
                e.ctrlKey ? window.open(projectUrl, '_blank') : this.props.history.push(projectUrl);
            }}>
                <div className="first-row">
                    <ProjectLogo imageId={entity.imageId} />
                    <div className="info">
                        <div className="title overflow-text" title={attrs.Name}>
                            {entity.isPrivate && <PrivateProjectIcon />}
                            {attrs.Name}
                        </div>
                        <div className="primary-details">
                            <CategoryColorStatusView
                                statusOption={statusOption}
                                trend={entity.insights.statuses[nameof<ProjectAttrs>("OverallStatus")]?.trend}
                                title={statusField?.label}
                            />
                            <div className="connect-buttons">
                                {!!getTasksMetrics(entity.sourceInfos, entity.calculation.taskProgresses, ppmxTaskConnectionId)?.total && <TasksIcon entity={entity} />}
                                <LinkedSystemIcons
                                    sourceInfos={entity.sourceInfos}
                                    taskProgresses={entity.calculation.taskProgresses}
                                    showTitle
                                    showMetrics
                                    excludeCollaborative
                                    allowNavigate
                                    entityType={EntityType.Project} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="metrics">
                    {
                        this.renderMetrics(attrs, fields)
                    }
                </div>
                <div className="separator"></div>

                {this.props.showCosts && <CostsKPI costs={entity.calculation.costs} />}

                <div className="row">
                    <div className="align-center">
                        <Icon iconName="PPMXPortfolio" className="card-icon" />
                        <span className="title">Portfolio</span>
                    </div>
                    {
                        portfolioInfos.length
                            ? <div className="align-center">
                                <PortfolioFormatter portfolio={portfolioInfos} withNavigation={true} />
                            </div>
                            : <div className="align-center">
                                <span>(Project is not within any portfolio)</span>
                            </div>
                    }
                </div>

                <div className="row">
                    <div className="align-center">
                        <Icon iconName="PPMXProgram" className="card-icon" />
                        <span className="title">Program</span>
                    </div>
                    {
                        attrs.Program.length > 0
                            ? <div className="align-center">
                                <ProgramFormatter program={attrs.Program} withNavigation={true} />
                            </div>
                            : <div className="align-center">
                                <span>(Project is not within any program)</span>
                            </div>
                    }
                </div>

                <div className="row">
                    <div className="align-center">
                        <Icon iconName="PPMXCardManager" className="card-icon" />
                        <span className="title">Manager</span>
                    </div>
                    {
                        attrs.Manager.length > 0
                            ? <div className="align-center">
                                <ResourceFormatter resource={attrs.Manager} onClick={this.goToProjects} />
                            </div>
                            : <div className="align-center">
                                <span>(Manager is not assigned yet)</span>
                            </div>
                    }
                </div>
                <div className="separator"></div>

                <div className="actions">
                    {this.props.onMenuRender(entity)}
                    <FavoriteToggle
                        isFavorite={entity.isFavorite}
                        disabled={this.props.isReadonlyMode}
                        onChange={this.onFavoriteChange}
                        className="clickable favorite"
                    />
                    <EntityWarning warnings={entity.warnings} multipleItemsMessage="There are warnings related to this project. Please review project details for more information" />
                </div>
            </div>
        );
    }

    private goToProjects = (e: any, resource: IUserInfo) => {
        e.stopPropagation();
        e.preventDefault();
        this.props.history.push(`/projects`, new ActiveFilter("Custom").withManager(resource).buildLocationState());
    }

    private onFavoriteChange = (isFavorite: boolean) => {
        this.props.onFavoriteChange(isFavorite);
    }

    private renderMetrics(attrs: ProjectAttrs, fields: Dictionary<Metadata.Field>): JSX.Element[] {
        const metrics: { config: CardMetricConfig, key: string }[] = [
            {
                config: {
                    title: "Start Date",
                    value: FormatDate(attrs.StartDate)
                },
                key: "start-date"
            },
            {
                config: {
                    title: "Finish Date",
                    value: FormatDate(attrs.FinishDate)
                },
                key: "finish-date"
            },
            {
                config: {
                    title: "Stage",
                    value: attrs.Stage?.name,
                    valueClassName: `colorfull`,
                    valueStyle: { 
                        color: attrs.Stage?.color 
                    }
                },
                key: "stage"
            },
            {
                config: {
                    title: "ID",
                    value: attrs.Identifier
                },
                key: "id"
            }
        ];

        if (this.props.showCosts) {
            const { actual, plan } = this.props.entity.calculation.costs;
            metrics.splice(0, 0,
                {
                    config: {
                        title: "Budget",
                        value: formatFieldValue(attrs.Budget, Metadata.FormatType.Cost, this.props.user)
                    },
                    key: "budget"
                },
                {
                    config: {
                        title: "Cost Completed",
                        value: plan ? toPercentString(actual / plan) : "N/A" 
                    },
                    key: "cost-completed"
                });
        }

        return metrics.map(_ => <CardMetric config={_.config} key={_.key} />);
    }
}

function mapStateToProps(state: ApplicationState): StoreProps {
    return {
        user: state.user,
        isReadonlyMode: isInReadonlyMode(state.user, state.tenant)
    };
}

export default withRouter<ProjectCardOwnProps>(connect(mapStateToProps)(ProjectCard));
