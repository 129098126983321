import * as React from 'react';
import { bindActionCreators } from 'redux';
import { PanelType, DefaultButton, Overlay } from 'office-ui-fabric-react';
import { IPermissions, LicenseType, LicenseTypeMap } from '../../store/permissions';
import PermissionsEdit from './PermissionsEdit';
import { ApplicationState } from "../../store/index";
import { connect } from 'react-redux';
import { actionCreators as tenantActionCreators, getDefaultPermissions, IDefaultPermissions } from "../../store/Tenant";
import Spinner from '../common/Spinner';
import DirtyPanel from '../common/DirtyPanel';

type OwnProps = {
    license: LicenseType;
    onDismiss: () => void;
    readOnly: boolean;
}
type StateProps = {
    isLoading: boolean;
    defaultPermissions?: IDefaultPermissions<IPermissions>;
}
type ActionProps = {
    tenantActions: typeof tenantActionCreators;
}
type Props = OwnProps & ActionProps & StateProps;

const primaryCommandLabel = 'Save'

const DefaultPermissionsPanelImpl = (props: Props) => {
    React.useEffect(() => { !props.defaultPermissions && props.tenantActions.loadDefaultPermissions() }, []);
    const [permissions, setPermissions] = React.useState(getDefaultPermissions(props.license, props.defaultPermissions));
    React.useEffect(() => { setPermissions(getDefaultPermissions(props.license, props.defaultPermissions)) }, [props.license, props.defaultPermissions]);
    const [isDirty, setIsDirty] = React.useState(false);

    const { license, readOnly, isLoading } = props;
    const cancelCommandLabel = readOnly ? 'Close' : "Cancel";
    return <DirtyPanel
        className="user-edit"
        isLightDismiss
        type={PanelType.custom}
        customWidth="400px"
        isOpen
        isDirty={isDirty}
        cancelCommandLabel={cancelCommandLabel}
        primaryCommandLabel={primaryCommandLabel}
        onDismiss={props.onDismiss}
        onRenderHeader={_onRenderHeader}
        onRenderFooterContent={_onRenderFooterContent}>
        {permissions && <PermissionsEdit
            license={license}
            readOnly={readOnly}
            permissions={permissions}
            onChange={_onPermissionsChange}
            hideLicenseSelector
        />}
        {isLoading && <Overlay><Spinner /></Overlay>}
    </DirtyPanel>;

    function _onPermissionsChange(_: LicenseType, changes: Partial<IPermissions>) {
        setPermissions({ ...permissions!, ...changes });
        setIsDirty(true);
    }

    function _onRenderHeader() {
        return <div className="ms-Panel-header">
            <p className="ms-Panel-headerText">Edit {LicenseTypeMap[license].title} License</p>
            <div className='ms-Panel-secondaryText'>Define default permissions for new {LicenseTypeMap[license].title}s</div>
        </div>;
    }

    function _onRenderFooterContent() {
        return <div className="commands">
            {
                !readOnly && <DefaultButton
                    primary
                    text={primaryCommandLabel}
                    iconProps={{ iconName: "Save" }}
                    onClick={_save}
                    disabled={!permissions}
                />
            }
            <DefaultButton
                text={cancelCommandLabel}
                onClick={props.onDismiss}
            />
        </div>;
    }

    function _save() {
        props.tenantActions.updateDefaultPermissions(props.license, permissions!);
        props.onDismiss();
    }
}

function mapStateToProps(state: ApplicationState, ownProps: OwnProps): StateProps {
    return {
        isLoading: state.tenant.isLoading,
        defaultPermissions: state.tenant.defaultPermissions
    }
}
function mergeActionCreators(dispatch: any): ActionProps {
    return {
        tenantActions: bindActionCreators(tenantActionCreators, dispatch)
    }
}
export const DefaultPermissionsPanel = connect(mapStateToProps, mergeActionCreators)(DefaultPermissionsPanelImpl);