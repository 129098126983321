
import React from "react";
import { Dictionary } from "../../../../entities/common";

type Props = {
    item: FlowChartItem;
    getItemStyles: GetItemStyles;
}

export const ItemCard = React.forwardRef<HTMLDivElement, Props>(({ item, getItemStyles }, ref) => {
    const styles = getItemStyles(item);
    return <div
        ref={ref}
        className={`flow-chart-item-card overflow-text`}
        style={styles}
        title={item.attributes.Name}>
        {item.attributes.Name}
    </div>;
})

export type FlowChartItem = { id: string, attributes: { Name?: string } & Dictionary<any> }
export type GetItemStyles = (item: FlowChartItem) => React.CSSProperties;