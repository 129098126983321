import { IEditable, ServerEntityType, StageCategory } from "../../entities/common";
import { IProcessStage } from "../../entities/Subentities";

export type StageType = {
    id: string;
    category: StageCategory;
    name: string;
    color: string;
};

export interface StageTypeInfo { 
    id: string;
    category: StageCategory;
    name: string;
    color: string;
}

export interface Process extends IEditable {
    id: string;
    attributes: IProcessAttrs;
    areStagesEditable: boolean;
    isSystem: boolean;
    stageTypes: StageType[];
    stages: IProcessStage[];
}

export type IProcessAttrs = {
    Name: string;
    Description: string;
    Status: ProcessStatus;
    IsDefault: boolean;
    EntityType: ServerEntityType;
    CreatedDate: string;
};

export enum ProcessStatus {
    Draft = 0,
    Active = 1,
    Inactive = 2,
}

export const canBeDeleted = (process: Process) => process.isEditable && process.areStagesEditable && !process.attributes.IsDefault;
