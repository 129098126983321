import * as React from 'react';
import * as Metadata from "../../../entities/Metadata";
import TextInput from './TextInput';
import DropdownInput from "./DropdownInput";
import ColorDropdownInput from "./ColorDropdownInput";
import ToggleOptionInput from "./ToggleOptionInput";
import TagPicker from './TagPicker';
import { FieldInputElementProps } from '../interfaces/IInputProps';
import { FieldsService } from '../FieldsService';
import * as StatusDescriptorFactory from '../../../entities/StatusDescriptorFactory';
import { StatusCategory } from '../../../entities/common';
import StageDescriptor from '../../../entities/StageDescriptor';

const TextFieldInputElement = (props: FieldInputElementProps) => {
    const { field, entityType, validator } = props;
    if (field.settings) {
        if (field.settings.editControl === "Dropdown") {
            return <DropdownInput {...props}
                value={props.value || field.defaultValue}
                useEmptyValue={!Metadata.isRequired(field)}
                inputProps={{ options: Metadata.getOptions(field), readOnly: field.isReadonly }}
                multichoice={field.settings ? field.settings['multichoice'] == true : false} />;
        }
        if (field.settings.editControl === "ColorStatusDropdown") {
            const statusDescriptor = StatusDescriptorFactory.createStatusDescriptor(field);
            const statusOptions = statusDescriptor.getOptions();
            const defaultValue = statusDescriptor.getCategoryDefaultStatusValue(StatusCategory.NA)
            return (
                <ColorDropdownInput
                    {...props}
                    defaultValue={defaultValue}
                    inputProps={{ readOnly: field.isReadonly }}
                    options={statusOptions}
                />
            );
        }
        if (field.settings.editControl === "ColorStageDropdown") {
            const stageOptions = new StageDescriptor(field).getOptions();
            return <ColorDropdownInput
                {...props}
                useEmptyValue={!Metadata.isRequired(field)}
                inputProps={{ readOnly: field.isReadonly }}
                options={stageOptions}
            />;
        }
        if (field.settings.editControl === "ToggleOption") {
            const inputProps = { options: Metadata.getOptions(field), readOnly: field.isReadonly };
            return <ToggleOptionInput {...props} inputProps={inputProps} />;
        }
        if (FieldsService.isTag(field)) {
            return <TagPicker {...props} field={field} entityType={entityType} validator={validator} />;
        }
    }

    const textInputProps = {
        readOnly: field.isReadonly,
        multiline: field.settings?.multiline,
        placeholder: field.settings?.placeholder,
        rows: field.settings?.rows,
        resizable: field.settings?.resizable
    };

    return <TextInput {...props} inputProps={textInputProps} validator={validator} showMoreMode={field.settings?.multiline} />;
}

export default TextFieldInputElement;