import React from 'react';
import { EntityType, StatusCategory } from '../../entities/common';
import { Field, FormatType } from '../../entities/Metadata';
import { isObjective, Objective, ObjectiveOrKeyResult } from '../../store/ObjectivesListStore';
import { OKRValueFormatter } from '../common/formatters/OkrFormatters';
import { IRow, } from "../common/timeline/TimelineList";
import { ITimelineSegment, Visibility } from '../common/timeline/TimelineSegment';
import { minMax } from '../common/timeline/utils';
import { getMinimalTimeframe } from '../project/timeline';
import { TimelineProgress } from '../task/timeline';
import { formatValue, toDate } from '../utils/common';
import ObjectiveTooltipContent from './ObjectiveTooltipContent';
import * as StatusDescriptorFactory from '../../entities/StatusDescriptorFactory';
import { calculateShadeColor } from '../common/ShadableEntityIcon';

export function renderSegmentContent(row: IRow, objectiveFields: Field[]): JSX.Element | undefined {
    const { attributes: { Status, Progress, ValueType, CurrentValue, TargetValue } } = row.entity as Objective;
    const statusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Objective, objectiveFields)!;

    return <TimelineProgress progress={Progress} color={statusDescriptor.getColorOrDefault(Status, StatusCategory.NA)}>
        <div className="subitems-counter">
            <OKRValueFormatter value={CurrentValue} type={ValueType} /> / <OKRValueFormatter value={TargetValue} type={ValueType} />
            <span className="extra"> ({formatValue(Progress, FormatType.Percent)} complete)</span>
        </div>
    </TimelineProgress>;
}

export function renderSegmentTooltipContent(row: IRow, segment: ITimelineSegment): JSX.Element | undefined {
    return <ObjectiveTooltipContent entity={row.entity as Objective} />;
}

export function buildTimelineItem(
    objective: ObjectiveOrKeyResult,
    objectiveFields: Field[],
    datesVisibility?: Visibility
): IRow {
    let segments: ITimelineSegment[] = [];

    if (isObjective(objective)) {
        const startDate = toDate(objective.attributes.StartDate);
        const finishDate = toDate(objective.attributes.FinishDate);

        const timeframe = getMinimalTimeframe(startDate, finishDate);
        const allDates = [
            timeframe.start,
            timeframe.end
        ];
        const { minDate, maxDate } = minMax(allDates);
        if (minDate != startDate || maxDate != finishDate) {
            segments = [...segments, {
                key: 'min-to-max',
                startDate: minDate?.getBeginOfDay()!,
                finishDate: maxDate?.getEndOfDay()!,
                className: `min-to-max`
            }];
        }
        if (startDate && finishDate) {
            const objectiveStatusDescriptor = StatusDescriptorFactory.createStatusDescriptorFor(EntityType.Objective, objectiveFields)!;
            const color = objectiveStatusDescriptor.getColorOrDefault(objective.attributes.Status, StatusCategory.NA);
            const shadeColor = calculateShadeColor(color, .5);
            segments = [...segments, {
                key: "start-to-finish",
                startDate: startDate.getBeginOfDay()!,
                finishDate: finishDate.getEndOfDay()!,
                datesVisibility: datesVisibility,
                className: `start-to-finish`,
                style: { backgroundColor: shadeColor, borderColor: color }
            }];
        }
    }

    if (segments.length) {
        segments[segments.length - 1].addContent = true;
    }

    return {
        key: objective.id,
        entity: objective,
        segments: segments,
        markers:[]
    };
}