import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { ApplicationState } from '../../store';
import * as Metadata from '../../entities/Metadata';
import { IDeletionResult } from '../../store/services/storeHelper';
import { getActiveViewType, IEntitiesScreenProps } from '../common/EntitiesScreen';
import { IEntitiesScreenView, HeaderProps } from '../common/EntitiesScreenHeader';
import EntitiesScreenBuilder from '../common/EntitiesScreen';
import EmptyEntitiesScreen from "../common/EmptyEntitiesScreen";
import {
    PrimaryButton, IContextualMenuItem, IDialogContentProps, MessageBar, MessageBarType, arraysEqual, IObjectWithKey, Selection
} from 'office-ui-fabric-react';
import { Dictionary, EntityType, IExtensibleEntity } from "../../entities/common";
import { Reporting, ReportNav, ReportsNav } from "../utils/reporting";
import EntitiesCardList from "../common/EntitiesCardList";
import * as PortfoliosListStore from '../../store/PortfoliosListStore';
import * as ProgramsListStore from '../../store/ProgramsListStore';
import * as ProjectsListStore from '../../store/ProjectsListStore';
import RemoveDialog from '../common/RemoveDialog';
import ListSubView, { getPngExportConfigSelectors } from "../views/list/ListSubView";
import EditListSubView from "../views/list/EditListSubView";
import { default as ProgramCard, ProgramCardState } from '../views/card/ProgramCard';
import { SortService } from "../../services/SortService";
import { notUndefined, toDictionaryById } from "../utils/common";
import Spinner from "../common/Spinner";
import { Validator } from "../../validation";
import DatePickerInput from '../common/inputs/DatePickerInput';
import { IBulkEditInput } from "../common/EntitiesBulkEdit";
import ProgramCreation from './ProgramCreation';
import { UserState } from "../../store/User";
import { contains, CommonOperations, canCreate, canUpdate, LicenseType } from "../../store/permissions";
import { ProgramFilterValue, FilterHelper } from '../../store/program/filters';
import * as Notifications from "../../store/NotificationsStore";
import * as LayoutsStore from "../../store/layouts";
import * as FiltersStore from "../../store/filters";
import * as ViewsStore from "../../store/views";
import { IDetailsProps, IListProps } from '../common/extensibleEntity/EntityDetailsList';
import { ITimelineProps } from '../common/extensibleEntity/EntityTimelineList';
import * as ProgramTimeline from './timeline';
import * as ProjectTimeline from '../project/timeline';
import * as CommonTimeline from '../portfolio/commonTimeline';
import { ViewService } from '../../services/ViewService';
import { Visibility } from '../common/timeline/TimelineSegment';
import { StatusCalculationTypes } from '../../store/Tenant';
import { default as GenericEntitiesFilter } from '../common/EntitiesFilter';
import { LayoutService } from '../utils/LayoutService';
import SharePanel from '../common/SharePanel';
import MenuWithReports from '../reporting/MenuWithReports';
import { HierarchyManager, IHierarchyItem, Sorter } from '../utils/HierarchyManager';
import { HierarchyContainer, isProgram, isProject } from '../utils/HierarchyContainer';
import { IRow } from '../common/timeline/TimelineList';
import { getStatusesInputs } from '../portfolio/PortfoliosList';
import PngExporter, { PngExportConfig } from '../common/PngExporter';
import { PngExportControlDetails } from '../../store/PngExporterStore';
import { IInputProps } from '../common/interfaces/IInputProps';
import { buildExportToCsvMenuItem } from '../common/headerMenuItemBuilders';
import { MenuTitleBuilder } from '../MenuTitleBuilder';
import ApplyLayoutConfirmationDialog from '../common/ApplyLayoutConfirmationDialog';

type ActionProps = {
    portfoliosActions: typeof PortfoliosListStore.actionCreators;
    programsActions: typeof ProgramsListStore.actionCreators;
    projectsActions: typeof ProjectsListStore.actionCreators;
    notificationsActions: typeof Notifications.actionCreators;
    filtersActions: ReturnType<typeof FiltersStore.actionCreators.forEntity>;
    viewsActions: ReturnType<typeof ViewsStore.actionCreators.forEntity>;
};
type StateProps = {
    portfolios: PortfoliosListStore.Portfolio[];
    programs: ProgramsListStore.Program[];
    projects: ProjectsListStore.ProjectInfo[];
    layouts: LayoutsStore.LayoutsState;
    programFields: Metadata.Field[];
    programFakeFields: Metadata.Field[];
    projectFields: Metadata.Field[];
    projectFakeFields: Metadata.Field[];
    programFilters: Metadata.IFilter<ProgramFilterValue>[];
    keyDateFields: Metadata.Field[];
    activeFilter?: Metadata.IFilter<ProgramFilterValue>;
    autoFilterId: string;
    preFilterId?: string;
    programReports: ReportNav[];
    programsReports: ReportsNav;
    views?: ViewsStore.IViewsState;
    deletionResult: IDeletionResult[] | undefined;
    isLoading: boolean;
    isListLoading: boolean;
    isListUpdating: boolean;
    isImportDialogOpen: boolean;
    user: UserState;
    statusCalculation: StatusCalculationTypes;
    pngExportDetails?: PngExportControlDetails;
};
export type Props = StateProps & ActionProps & RouteComponentProps<{}>;

type State = {
    isCreate: boolean;
    share?: ProgramsListStore.Program;
    programsToRemove: ProgramsListStore.Program[];
    layoutToApply?: Metadata.Layout;
    canManageConfiguration: boolean;
    canEdit: boolean;
    selectedItems: ProgramsListStore.Program[];
    allItems: ProgramsListStore.ProgramOrProject[];

    isListViewEdit: boolean;
    isTimelineViewEdit: boolean;

    preFilter: Metadata.PreFilter<ProgramsListStore.Program>;
    entityFilterHelper: Metadata.IEntityFilterHelper<ProgramsListStore.Program>;
};

const EntitiesFilter = GenericEntitiesFilter<ProgramsListStore.Program>();
const EntitiesScreen = EntitiesScreenBuilder<ProgramsListStore.Program>();

class ProgramsList extends React.Component<Props, State> {
    private _hierarchy: HierarchyManager<ProgramsListStore.Program, void>;
    private _selection: Selection;

    constructor(props: Props) {
        super(props);
        const preFilterOptions: Metadata.PreFilterOption<ProgramsListStore.Program>[] = [
            { key: "only-my", name: "Only Mine", predicate: _ => !!_.attributes.Manager && !!_.attributes.Manager.find(m => m.id === props.user.id) }
        ];

        this.state = {
            isCreate: false,
            canManageConfiguration: contains(props.user.permissions.common, CommonOperations.ConfigurationManage),
            canEdit: this._canEdit(props),
            selectedItems: [],
            allItems: _buildPgOrPjEntities(props.programs, props.projects),

            isListViewEdit: false,
            isTimelineViewEdit: false,
            preFilter: Metadata.PreFilter.create(preFilterOptions,
                active => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id, active?.key)),
            programsToRemove: [],
            entityFilterHelper: new FilterHelper({
                fields: props.programFields,
                portfolios: props.portfolios,
                programs: props.programs,
                layouts: props.layouts.allIds.map(_ => props.layouts.byId[_])
            }),
        };

        this._hierarchy = new HierarchyManager<ProgramsListStore.Program, void>({
            fieldId: props.programFields.find(_ => _.name === "Name")?.id!,
            getItemId: (entity: ProgramsListStore.ProgramOrProject) => entity.id,
            getItemParentId: (entity: ProgramsListStore.ProgramOrProject) => entity.parentId,
            isHierarchicalSort: () => true
        });

        this._selection = new Selection({
            onSelectionChanged: () => {
                this.setState({ selectedItems: this._selection.getSelection() as ProgramsListStore.Program[] });
            },
            getKey: (_) => this._hierarchy.getKey(_ as ProgramsListStore.Program & IHierarchyItem),
            canSelectItem: this.canSelectItem as ((item: IObjectWithKey, index?: number) => boolean)
        });
    }

    componentWillMount() {
        this.props.portfoliosActions.requestPortfolios();
        this.props.programsActions.requestPrograms();
        this.props.projectsActions.requestProjects();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.programFields !== nextProps.programFields) {
            const entityFilterHelper = new FilterHelper({
                fields: nextProps.programFields,
                portfolios: nextProps.portfolios,
                programs: nextProps.programs,
                layouts: nextProps.layouts.allIds.map(_ => nextProps.layouts.byId[_])
            });
            this.setState({ entityFilterHelper });
        }
        if (!arraysEqual(this.props.programs, nextProps.programs)
            || !arraysEqual(this.props.projects, nextProps.projects)) {
            this.setState({ allItems: _buildPgOrPjEntities(nextProps.programs, nextProps.projects) })
        }
    }

    private _canEdit(props: Props) {
        return canCreate(props.user.permissions.program) || canUpdate(props.user.permissions.program) || props.programs.some(_ => _.isEditable);
    }

    private _viewChanged = (view: IEntitiesScreenView<ProgramsListStore.Program>) => {
        this.setState({ selectedItems: [] });
        this.props.viewsActions.setActiveView(view.url);
    }

    private _getFilteredEntities = () => this.state.allItems.filter(_ => _.entityType === EntityType.Program && this._isItemVisible(_ as ProgramsListStore.Program));

    private _clearPreFilter = () => this.props.filtersActions.setActiveFilter(this.props.activeFilter?.id);

    public render() {
        if (!this.props.isImportDialogOpen && (this.props.isLoading || this.props.isListLoading)) {
            return <Spinner />;
        }

        const { deletionResult, pngExportDetails } = this.props;
        const { programsToRemove, allItems } = this.state;
        const notDeleted = deletionResult?.filter(_ => !_.isDeleted);

        const top = this._getFilteredEntities();

        return <>
            {this.props.programs.length === 0
                ? <EmptyEntitiesScreen
                    className="program"
                    title="programs"
                    description="Get the complete program visibility across your project and instant access to fresh program data and actionable insights - all in one place">
                    <PrimaryButton disabled={!canCreate(this.props.user.permissions.program)} text="Create Program" onClick={() => this.setState({ isCreate: true })} />
                </EmptyEntitiesScreen>
                : <PngExporter details={pngExportDetails} getConfig={this._getPngExportConfig}>
                    <HierarchyContainer<ProgramsListStore.ProgramOrProject, void>
                        items={top}
                        allItems={allItems}
                        expandIds={[]}
                        hierarchy={this._hierarchy}>
                        <EntitiesScreen {...this._buildEntitiesScreenProps()} />
                    </HierarchyContainer>
                </PngExporter>}
            {this.state.share && <SharePanel
                key="share-panel"
                entity={this.state.share}
                entityType={EntityType.Program}
                layouts={this.props.layouts}
                onDismiss={() => this.setState({ share: undefined })} />}
            {this.state.isCreate && <ProgramCreation onDismiss={() => this.setState({ isCreate: false })} openOnComplete />}
            {!!programsToRemove.length && <RemoveDialog
                onClose={() => this.setState({ programsToRemove: [] })}
                onComplete={() => {
                    this.props.programsActions.removePrograms(programsToRemove.filter(_ => _.isEditable).map(_ => _.id));
                }}
                dialogContentProps={this._getRemoveDialogContent(programsToRemove)}
                confirmButtonProps={{ text: "Delete", disabled: programsToRemove.filter(_ => _.isEditable).length === 0 }} >
                {programsToRemove.some(_ => !_.isEditable) && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    You don't have permissions to delete the following programs:
                    <ul>
                        {programsToRemove.filter(_ => !_.isEditable).map(_ => <li key={_.id}>{_.attributes.Name}</li>)}
                    </ul>
                </MessageBar>}
            </RemoveDialog>}
            {deletionResult && notDeleted && <RemoveDialog
                onClose={() => this.props.programsActions.dismissDeletionResult()}
                confirmButtonProps={{ text: "Got it" }}
                modalProps={{ styles: { main: { minWidth: 500 } } }}
                dialogContentProps={this._getDeletionResultDialogContent(deletionResult)}>
                {deletionResult.length > 1 && notDeleted.length > 0 && <MessageBar messageBarType={MessageBarType.warning} isMultiline>
                    Failed to delete the following {notDeleted.length > 1 ? "programs" : "program"}:
                    <ul>
                        {notDeleted.map(_ => <li key={_.id}>{_.name}</li>)}
                    </ul>
                    Please check if you have necessary permissions. If {notDeleted.length > 1
                        ? "programs contain" : "the program contains"} projects, please remove all related projects first.
                </MessageBar>}
            </RemoveDialog>}
            {this.state.layoutToApply && <ApplyLayoutConfirmationDialog
                onConfirm={() => {
                    this.props.programsActions.applyLayoutMany(this.state.selectedItems.map(_ => _.id), this.state.layoutToApply!.id);
                    this.props.notificationsActions.pushNotification({
                        message: `Layout '${this.state.layoutToApply!.name}' applied`,
                        type: Notifications.NotificationType.Info
                    });
                }}
                onDismiss={() => this.setState({ layoutToApply: undefined })}
                entityType={EntityType.Program}
                layoutName={this.state.layoutToApply!.name}
                count={this.state.selectedItems.length}
            />}
        </>;
    }

    private _buildEntitiesScreenProps(): IEntitiesScreenProps<ProgramsListStore.Program> {
        const top = this._getFilteredEntities();
        return {
            title: "programs",
            clearPreFilter: this._clearPreFilter,
            canManageConfiguration: this.state.canManageConfiguration,
            activeViewType: this.props.views!.activeViewType,
            viewChanged: this._viewChanged,
            fields: this.props.programFields,
            fakeFields: this.props.programFakeFields,
            bulkEditAttributesCustomRender: this.bulkEditAttributesCustomRenderBuilder(),
            defaultBulkEditColumns: ProgramsListStore.DEFAULT_BULK_EDIT_COLUMNS,
            entities: top as ProgramsListStore.Program[],
            entitiesIsUpdating: this.props.layouts.isApplyingLayout,
            getBulkEditEntities: this.getBulkEditEntities,
            actions: {
                bulkComponent: {
                    bulkUpdate: this.gridBulkUpdate
                },
                importFromFile: this.props.programsActions.importEntitiesFromFile
            },
            views: this._getViews(),
            filter: {
                activeFilter: this.props.activeFilter,
                autoFilterId: this.props.autoFilterId,
                getAttributeValue: this.getAttributeValue,
                onFilterRender: this._renderFilter
            },
            headerProps: this.getHeaderProps(),
            router: {
                history: this.props.history,
                match: this.props.match,
                location: this.props.location
            },
            baseUrl: "/programs",
            canEdit: this.state.canEdit,
            selectedItemIds: this.state.selectedItems.map(_ => _.id),
            getPngExportConfig: this._getPngExportConfig
        }
    }

    private _getRemoveDialogContent(toRemove: ProgramsListStore.Program[]) {
        toRemove = toRemove.filter(_ => _.isEditable);
        return toRemove.length === 1
            ? {
                title: "Delete program",
                subText: `Are you sure you want to delete program "${toRemove[0].attributes.Name}"?`
            }
            : {
                title: "Delete programs",
                subText: toRemove.length
                    ? `Are you sure you want to delete selected programs (${toRemove.length} items)?`
                    : undefined
            }
    }

    private _getDeletionResultDialogContent(deletionResult: IDeletionResult[]): IDialogContentProps {
        if (deletionResult.length === 1 && !deletionResult[0].isDeleted) {
            return {
                title: "Unable to delete program",
                subText: `Please check if you have necessary permissions. If the program "${deletionResult[0].name}" contains projects, please remove all related projects first.`
            };
        }

        const deleted = deletionResult.filter(_ => _.isDeleted);
        return deleted.length === 1
            ? {
                title: "Program deletion is complete",
                subText: `Program "${deleted[0].name}" was deleted successfully.`
            }
            : deleted.length
                ? {
                    title: "Programs deletion is complete",
                    subText: `Selected programs (${deleted.length} items) were deleted successfully.`
                }
                : {
                    title: "Programs deletion failed"
                };
    }

    private getBulkEditEntities = (): ProgramsListStore.Program[] => {
        if (this.state.selectedItems.length > 0) {
            return this.state.selectedItems;
        }
        return this.applyFilter(this.props.programs);
    }

    private getHeaderProps = (): HeaderProps => {
        const { programsReports, history } = this.props;

        const reportItems: IContextualMenuItem[] = programsReports.packs.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openReport(history, _);
                }
            }));

        const programReportItems: IContextualMenuItem[] = programsReports.subPacks.map(_ => (
            {
                key: _.id,
                name: _.title,
                iconProps: { iconName: "FileSymlink" },
                onClick: () => {
                    Reporting.openProgramsReport(history, _, this.getBulkEditEntities());
                }
            }));

        return {
            entityType: EntityType.Program,
            createEntityTypes: [EntityType.Portfolio, EntityType.Program, EntityType.Project, EntityType.Roadmap, EntityType.Resource, EntityType.PrivateProject],
            importEntityTypes: [EntityType.Project, EntityType.Resource],
            reportProps: {
                reportsButtonAdditionalOptions: Reporting.buildReportsList(programReportItems, reportItems)
            },
            allowExportToFile: true,
            allowImportFromFile: this.props.user.license === LicenseType.Regular
        }
    }

    private _isFieldFake = (field: Metadata.Field): boolean => {
        return this.props.programFakeFields.some(_ => _.id === field.id) ||
            this.props.projectFakeFields.some(_ => _.id === field.id);
    }

    private _renderFilter = (isFilterPanelOpen: boolean, toggleFilterPanel: () => void) => (
        <EntitiesFilter
            canManageConfiguration={this.state.canManageConfiguration}
            preFilter={this.state.preFilter}
            preFilterId={this.props.preFilterId}
            activeFilter={this.props.activeFilter}
            onActiveFilterChanged={this._onActiveFilterChanged}
            onFilterChanged={this.props.filtersActions.updateFilter}
            isFilterPanelOpen={isFilterPanelOpen}
            toggleFilterPanel={toggleFilterPanel}
            entityType={EntityType.Program}
            entityFilterHelper={this.state.entityFilterHelper}
        />
    );

    private _onActiveFilterChanged = (id?: string) => {
        this.props.filtersActions.setActiveFilter(id, this.props.preFilterId);
    }

    private applyFilter = (_: ProgramsListStore.Program[]) => {
        return _.filter(this._isItemVisible);
    }

    private _isItemVisible = (item: ProgramsListStore.Program): boolean => {
        const { preFilter } = this.state;
        if (Metadata.PreFilter.isItemVisible(preFilter, item, this.props.preFilterId)) {
            return false;
        }

        const { activeFilter } = this.props;
        if (activeFilter) {
            const filterValue = activeFilter.value;
            const allAttributes = this.state.entityFilterHelper.getFilterAttributes(this.props.programFields);

            for (const type in filterValue) {
                if (!this.state.entityFilterHelper.helpersMap[type].validateItem(item, filterValue[type], allAttributes.filter(_ => _.type === type))) {
                    return false;
                }
            }
        }

        return true;
    }

    private getAttributeValue = (attrType: keyof ProgramFilterValue, value: any): string[] => {
        return this.state.entityFilterHelper.helpersMap[attrType].getAttributeValues(value);
    }

    private _renderMenu = (entity: ProgramsListStore.ProgramOrProject) => {
        if (entity.entityType !== EntityType.Program) {
            return null;
        }

        const commands: IContextualMenuItem[] = [
            entity.isEditable ? {
                key: 'share',
                name: 'Share',
                iconProps: { iconName: 'Share' },
                onClick: () => this.setState({ share: entity as ProgramsListStore.Program })
            } : undefined,
            entity.isEditable ? {
                key: 'pmedit',
                name: 'Edit',
                iconProps: { iconName: "Edit" },
                onClick: () => this.props.history.push(`/program/${entity.id}`)
            } : undefined,
            entity.isEditable ? {
                key: 'pmDelete',
                name: 'Delete',
                iconProps: { iconName: "Delete", style: { color: 'red' } },
                disabled: this.props.isLoading,
                style: { color: (this.props.isLoading ? 'initial' : 'red'), backgroundColor: (this.props.isLoading ? 'lightgrey' : 'unset') },
                onClick: () => {
                    this.setState({ programsToRemove: [entity as ProgramsListStore.Program] });
                }
            } : undefined
        ].filter(notUndefined);

        return <MenuWithReports
            commands={commands}
            item={entity}
            entityType={EntityType.Program}
            reports={this.props.programReports}
            onClick={_ => Reporting.openProgramReport(this.props.history, _, entity as ProgramsListStore.Program)} />;
    }

    private getCardView(): IEntitiesScreenView<ProgramsListStore.Program> {
        const { card } = this.props.views!;
        const fieldsMap = Metadata.toMap(this.props.programFields);
        return {
            icon: 'PPMXCardView',
            url: Metadata.ViewTypes.card,
            subViews: card.subViews,
            sortBy: card.sortBy,
            onSortChange: this.props.viewsActions.changeCardViewSort,
            activeSubViewId: card.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setCardActiveSubView,
            render: (key: string, activeSubView: Metadata.ICardSubView, entities: ProgramsListStore.Program[]) => {
                const comparer = SortService.getComparer(fieldsMap, card.sortBy.active.orderBy);
                return <EntitiesCardList
                    key={key}
                    entities={entities.sort(comparer)}
                    onCardRender={
                        (entity: ProgramsListStore.Program,
                            cardState: ProgramCardState | undefined,
                            persistCardState: (newCardState: Partial<ProgramCardState>) => void) => <ProgramCard
                                key={entity.id}
                                entity={entity}
                                fields={fieldsMap}
                                showWork={activeSubView.showWork}
                                showCosts={activeSubView.showCosts}
                                onMenuRender={this._renderMenu}
                                state={cardState}
                                onChangeState={persistCardState}
                            />}
                    cardParams={{ width: 456, height: 263 }} />;
            }
        }
    }

    private buildItemRender = (allProjectFields: Metadata.Field[])
        : (item: IExtensibleEntity, index: number, field: Metadata.Field, defaultRender: () => JSX.Element | null) => JSX.Element => {

        return (item: ProgramsListStore.ProgramOrProject, index, field, defaultRender) => {
            if (isProject(item)) {
                return ViewService.buildCellRenderer(item, EntityType.Project, allProjectFields, field, this._isFieldFake);
            }

            return defaultRender();
        }
    }

    private canSelectItem = (item: ProgramsListStore.ProgramOrProject): boolean => isProgram(item);

    private sorter: Sorter<ProgramsListStore.ProgramOrProject> = (orderBy) => {
        const { projectFakeFields, programFakeFields } = this.props;

        const allProgramFields = this.props.programFields.concat(programFakeFields),
            allProjectFields = this.props.projectFields.concat(projectFakeFields);

        const extractor = (item: ProgramsListStore.Program, field: Metadata.Field) => {
            if (field.name === LayoutsStore._layoutFakeFieldName) {
                return item.layoutId ? this.props.layouts.byId[item.layoutId]?.name : null;
            }
            return SortService.getFieldValueBaseImpl(field, item, this._isFieldFake)
        }

        return (a, b) => {
            if (a.entityType !== b.entityType) {
                return isProgram(a) ? 0 : 1;
            }

            if (isProject(a)) {
                const allProjectFieldsMap = Metadata.toMap(allProjectFields, this._isFieldFake);
                return SortService.getComparer(allProjectFieldsMap, orderBy, this._isFieldFake)(a, b);
            }

            const allProgramFieldsMap = Metadata.toMap(allProgramFields, this._isFieldFake);
            return SortService.getComparer(allProgramFieldsMap, orderBy, this._isFieldFake, extractor)(a, b);
        }
    };

    private getListView(): IEntitiesScreenView<ProgramsListStore.Program> {
        const { projectFakeFields, views } = this.props;
        const { list } = views!;

        const allProgramFields = this._getAllProgramFields(),
            allProjectFields = this.props.projectFields.concat(projectFakeFields);

        return {
            subViews: list.subViews.allIds.map(_ => list.subViews.byId[_]),
            icon: 'PPMXListView',
            url: Metadata.ViewTypes.list,
            activeSubViewId: list.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setListActiveSubView,
            onEditSubViewClick: id => {
                if (list.activeSubViewId !== id) {
                    this.props.history.push(`/programs/list/${id}`)
                }
                this.setState({ isListViewEdit: true });
            },
            onCopySubViewClick: this._onCopyListSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeListSubView,
            render: (key: string, activeSubView: Metadata.IListSubView, entities: ProgramsListStore.Program[]) => {
                const listProps: Partial<IListProps> & IDetailsProps = {
                    onItemMenuRender: this._renderMenu,
                    onItemRender: this.buildItemRender(allProjectFields),
                    isVirtualizationDisabled: this.props.pngExportDetails?.isInProgress
                };
                return [<ListSubView
                    key="details-view"
                    type="Details"
                    entities={entities}
                    selection={this._selection}
                    hierarchy={this._hierarchy}
                    entityType={EntityType.Program}
                    fields={allProgramFields}
                    isFieldFake={this._isFieldFake}
                    sort={list.sortBy}
                    sorter={this.sorter}
                    onSortChange={this.props.viewsActions.changeListViewSort}
                    view={activeSubView}
                    listProps={listProps}
                    selectionModeItems={this._buildSelectedModeMenuItems(activeSubView)}
                    onColumnResized={(id, w) => this.props.viewsActions.onListColumnResized(activeSubView.id, id, w)}
                    showSpinner={this.props.isListUpdating} />,
                this.state.isListViewEdit ? <EditListSubView
                    key="create-details-view"
                    subView={activeSubView}
                    entityType={EntityType.Program}
                    selectedByDefault={this.props.views!.list.selectedByDefault}
                    fields={allProgramFields}
                    onChange={changes => this.props.viewsActions.updateListSubView(activeSubView.id, changes)}
                    onSave={() => {
                        this.props.viewsActions.saveListSubView(activeSubView, 'programs');
                        this.setState({ isListViewEdit: false });
                    }}
                    onCopy={() => this._onCopyListSubView(activeSubView)}
                    onDismiss={() => this.setState({ isListViewEdit: false })}
                /> : <span key="no-edit"></span>
                ];
            },
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addListSubView(subView);
                this.props.history.push(`/programs/list/${subView.id}`);
                this.setState({ isListViewEdit: true });
            }
        }
    }

    private _getAllProgramFields() {
        return this.props.programFields.concat(this.props.programFakeFields);
    }

    private getTimelineView(): IEntitiesScreenView<ProgramsListStore.Program> {
        const { projectFakeFields, programFakeFields, views } = this.props;
        const { timeline } = views!;

        const allProjectFields = this.props.projectFields.concat(projectFakeFields);

        return {
            icon: 'PPMXTimelineView',
            url: Metadata.ViewTypes.timeline,
            subViews: timeline.subViews.allIds.map(_ => timeline.subViews.byId[_]),
            activeSubViewId: timeline.activeSubViewId,
            onSubViewChange: this.props.viewsActions.setTimelineActiveSubView,
            onAddSubViewClick: () => {
                const subView = Metadata.SubView.empty();
                this.props.viewsActions.addTimelineSubView(subView);
                this.props.history.push(`/programs/timeline/${subView.id}`);
                this.setState({ isTimelineViewEdit: true });
            },
            onEditSubViewClick: id => {
                if (timeline.activeSubViewId !== id) {
                    this.props.history.push(`/programs/timeline/${id}`)
                }
                this.setState({ isTimelineViewEdit: true });
            },
            onCopySubViewClick: this._onCopyTimelineSubView,
            onRemoveSubViewClick: this.props.viewsActions.removeTimelineSubView,
            render: (key: string, activeSubView: Metadata.ITimelineSubView, entities: ProgramsListStore.Program[]) => {
                const listProps: Partial<IListProps> & ITimelineProps = {
                    buildRow: (_: ProgramsListStore.ProgramOrProject): IRow => {
                        if (_.entityType === EntityType.Program) {
                            return ProgramTimeline.buildTimelineItem(
                                _ as ProgramsListStore.Program,
                                this.props.projects,
                                this.props.programFields,
                                this.props.projectFields,
                                this.props.keyDateFields,
                                Visibility.OnHover);
                        }
                        return ProjectTimeline.buildTimelineItem(
                            _ as ProjectsListStore.ProjectInfo,
                            this.props.projectFields,
                            this.props.keyDateFields,
                            Visibility.OnHover,
                            undefined,
                            toDictionaryById(this.props.projects));
                    },
                    renderSegmentContent: CommonTimeline.renderSegmentContent,
                    renderSegmentTooltipContent: CommonTimeline.renderSegmentTooltipContent,
                    renderMarkerTooltipContent: (row, marker) => CommonTimeline.renderMarkerTooltipContent(row, marker, true),
                    onItemRender: this.buildItemRender(allProjectFields),
                    onItemMenuRender: this._renderMenu,
                    userQuantization: timeline.quantization,
                    userTimeframe: timeline.timeframe,
                    onScaleChange: (timelineChange) => timelineChange.origin && this.props.viewsActions.setTimelineScale(timelineChange.origin),
                    isVirtualizationDisabled: this.props.pngExportDetails?.isInProgress
                };
                return <>
                    <ListSubView
                        key="timeline-view"
                        type="Timeline"
                        entities={entities}
                        selection={this._selection}
                        hierarchy={this._hierarchy}
                        entityType={EntityType.Program}
                        fields={this._getAllProgramFields()}
                        isFieldFake={this._isFieldFake}
                        sort={timeline.sortBy}
                        sorter={this.sorter}
                        onSortChange={this.props.viewsActions.changeTimelineViewSort}
                        view={activeSubView}
                        listProps={listProps}
                        selectionModeItems={this._buildSelectedModeMenuItems(activeSubView)}
                        showSpinner={this.props.isListUpdating}
                        onColumnResized={(id, w) => this.props.viewsActions.onTimelineColumnResized(activeSubView.id, id, w)} />
                    {
                        this.state.isTimelineViewEdit && <EditListSubView
                            key="create-timeline-view"
                            subView={activeSubView}
                            entityType={EntityType.Program}
                            selectedByDefault={timeline.selectedByDefault}
                            fields={this._getAllProgramFields()}
                            onChange={changes => {
                                this.props.viewsActions.updateTimelineSubView(activeSubView.id, changes);
                            }}
                            onSave={() => {
                                this.props.viewsActions.saveTimelineSubView(activeSubView, 'programs');
                                this.setState({ isTimelineViewEdit: false })
                            }}
                            onCopy={() => this._onCopyTimelineSubView(activeSubView)}
                            onDismiss={() => this.setState({ isTimelineViewEdit: false })}
                        />
                    }
                </>
            }
        }
    }

    private _onCopyListSubView = (view: Metadata.IListSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/programs/list/${subView.id}`);
        this.setState({ isListViewEdit: true });
        this.props.viewsActions.saveListSubView(subView, 'programs');
    }

    private _buildSelectedModeMenuItems = (activeSubView: Metadata.ISubView): IContextualMenuItem[] | undefined => {

        const { selectedItems } = this.state;
        const entitiesScreenProps = this._buildEntitiesScreenProps();
        const exportCsvMenu = buildExportToCsvMenuItem({
            views: entitiesScreenProps.views,
            fields: entitiesScreenProps.fields,
            fakeFields: entitiesScreenProps.fakeFields,
            selectedItemIds: selectedItems.map(_ => _.id),
            entityType: EntityType.Program,
            activeSubView: activeSubView,
            selection: this._selection,
            onExportEntitiesToFile: this.props.programsActions.exportEntitiesToFile,
        });

        if (!this.state.canEdit) {
            return [exportCsvMenu];
        }

        const layoutMenuItem = LayoutService.buildApplyLayoutMenuItem(this.props.layouts, (layout: Metadata.Layout) => {
            this.setState({ layoutToApply: layout });
        });
    
        return [
            {
                key: "bulk-edit",
                text: "Bulk edit",
                iconProps: { iconName: "TripleColumnEdit" },
                onClick: () => this.props.history.push(`/programs/bulk`),
            },
            exportCsvMenu,
            {
                ...layoutMenuItem,
                disabled: !!selectedItems.find(_ => !_.canConfigure),
            },
            {
                key: 'delete',
                text: "Delete",
                title: MenuTitleBuilder.deleteSelectedTitle(EntityType.Program),
                iconProps: { iconName: "Delete" },
                className: "more-deleteButton",
                onClick: () => this.setState({ programsToRemove: selectedItems }),
            }
        ];
    };
    
    private _onCopyTimelineSubView = (view: Metadata.ITimelineSubView) => {
        const subView = Metadata.SubView.copy(view);
        this.props.history.push(`/programs/timeline/${subView.id}`);
        this.setState({ isTimelineViewEdit: true });
        this.props.viewsActions.saveTimelineSubView(subView, 'programs');
    }

    private gridBulkUpdate = (updates: Dictionary<any>) => {
        this.props.programsActions.bulkUpdate(updates);
    }

    private getFinishFieldConfig(): IBulkEditInput {
        const getValidator = (attributes: ProgramsListStore.ProgramAttrs) => Validator.new().date().dateIsGreaterThenOrEqual(attributes.StartDate).build();
        return {
            validator: (attributes: ProgramsListStore.ProgramAttrs) => getValidator(attributes),
            render: (props: IInputProps, field: Metadata.Field, attributes: ProgramsListStore.ProgramAttrs) =>
                <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={getValidator(attributes)} minDate={attributes.StartDate} />
        }
    }
    private getStartFieldConfig(): IBulkEditInput {
        const getValidator = (attributes: ProgramsListStore.ProgramAttrs) => Validator.new().date().dateIsLessThenOrEqual(attributes.FinishDate).build();
        return {
            validator: (attributes: ProgramsListStore.ProgramAttrs) => getValidator(attributes),
            render: (props: IInputProps, field: Metadata.Field, attributes: ProgramsListStore.ProgramAttrs) =>
                <DatePickerInput {...props} inputProps={{ readOnly: field.isReadonly }} validator={getValidator(attributes)} maxDate={attributes.FinishDate} />
        }
    }

    private bulkEditAttributesCustomRenderBuilder = (): Dictionary<IBulkEditInput> => {
        return {
            "StartDate": this.getStartFieldConfig(),
            "FinishDate": this.getFinishFieldConfig(),
            ...getStatusesInputs(ProgramsListStore.statuses, this.props.statusCalculation)
        }
    }

    private _getViews = () => [this.getCardView(), this.getListView(), this.getTimelineView()];

    private _getPngExportConfig = (): PngExportConfig => {
        const views = this._getViews();
        const activeView = getActiveViewType(views, this.props.views!.activeViewType);
        return {
            ...getPngExportConfigSelectors(activeView),
            name: "Programs",
            controlId: ProgramsList.name,
            activeView,
            rowsCount: this._getFilteredEntities().length + this._hierarchy.getExpandedChildrenCount()
        };
    }
}

function _buildPgOrPjEntities(allPrograms: ProgramsListStore.Program[], allProjects: ProjectsListStore.ProjectInfo[]): ProgramsListStore.ProgramOrProject[] {
    return [
        ...allPrograms.map(_ => ({ entityType: EntityType.Program, ..._ })),
        ...allPrograms.reduce((arr, cur) => [
            ...arr,
            ...allProjects
                .filter(_ => cur.projectIds.indexOf(_.id) !== -1)
                .map(_ => ({ entityType: EntityType.Project, ..._, parentId: cur.id }))
        ], [])
    ];
}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{}>): StateProps => {
    const fields = state.fields[EntityType.Program];
    const projectFields = state.fields[EntityType.Project];
    const keyDateFields = state.fields[EntityType.KeyDate];
    const filters = FiltersStore.getFilter(state.filters, EntityType.Program);
    const autoFilterId = Metadata.Filter.getAutoFilterId(filters.all) ?? filters.all[0].id;
    const programs = state.programs.allIds.map(_ => state.programs.byId[_]);
    const projects = state.projectsList.allIds.map(_ => state.projectsList.byId[_]);
    return {
        portfolios: state.portfolios.allIds.map(_ => state.portfolios.byId[_]),
        programs,
        projects,
        layouts: state.layouts[EntityType.Program],
        programFields: fields.allIds.map(_ => fields.byId[_]),
        programFakeFields: state.views[EntityType.Program].list.fakeFields,
        projectFields: projectFields.allIds.map(_ => projectFields.byId[_]),
        projectFakeFields: state.views[EntityType.Project].list.fakeFields,
        keyDateFields: keyDateFields.allIds.map(_ => keyDateFields.byId[_]),
        programFilters: filters.all,
        activeFilter: filters.active.filter,
        autoFilterId: autoFilterId,
        preFilterId: filters.active.preFilterId,
        programReports: state.tenant.reporting.programReports.subPacks,
        programsReports: state.tenant.reporting.programsReports,
        views: state.views[EntityType.Program],
        deletionResult: state.programs.deletionResult,
        isLoading: state.programs.isLoading,
        isListLoading: state.programs.isListLoading,
        isListUpdating: state.programs.isListUpdating,
        isImportDialogOpen: state.import.projects.isDialogOpen,
        user: state.user,
        statusCalculation: state.tenant.insights.program.statusCalculation,
        pngExportDetails: state.pngExporter.controls[ProgramsList.name]
    };
}

function mergeActionCreators(dispatch: any): ActionProps {
    return {
        portfoliosActions: bindActionCreators(PortfoliosListStore.actionCreators, dispatch),
        programsActions: bindActionCreators(ProgramsListStore.actionCreators, dispatch),
        projectsActions: bindActionCreators(ProjectsListStore.actionCreators, dispatch),
        notificationsActions: bindActionCreators(Notifications.actionCreators, dispatch),
        filtersActions: bindActionCreators(FiltersStore.actionCreators.forEntity(EntityType.Program), dispatch),
        viewsActions: bindActionCreators(ViewsStore.actionCreators.forEntity(EntityType.Program), dispatch)
    }
}

export default connect(mapStateToProps, mergeActionCreators)(ProgramsList)