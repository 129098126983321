
import { IconButton } from "office-ui-fabric-react";
import React from "react";
import { FlowChartItem, GetItemStyles, ItemCard } from "./ItemCard";

type Props = {
    group: FlowChartGroup;
    items: FlowChartItem[];
    className: string;
    getItemStyles: GetItemStyles
}

export const GroupCard = React.forwardRef<GroupCardRef, Props>(({ group, items, className, getItemStyles }, ref) => {
    const refs = React.useRef(Array.of<HTMLDivElement>());

    React.useImperativeHandle(ref, () => ({
        get stagesRefs() { return refs.current }
    }));

    const [isCompact, setIsCompact] = React.useState(false);
    return <div className={`flow-chart-group-card ${className} ${isCompact ? 'compact' : ''}`} style={{ borderColor: group.color }}>
        <div className="flow-chart-group-card-header">
            <div className="flow-chart-group-card-header-title overflow-text" title={group.name}>{group.name}</div>
            <div className="flow-chart-group-card-header-info">
                <div className="flow-chart-group-card-header-bubble">{items.length}</div>
                {
                    isCompact
                        ? <IconButton iconProps={{ iconName: "DoubleChevronRight" }} onClick={() => setIsCompact(false)} />
                        : <IconButton iconProps={{ iconName: "DoubleChevronLeft" }} onClick={() => setIsCompact(true)} />
                }
            </div>
        </div>
        <div className="flow-chart-group-card-stages">
            {items.map((_, index) => <ItemCard key={_.id} ref={_ => refs.current[index] = _ as any} item={_} getItemStyles={getItemStyles} />)}
        </div>
    </div>
});

export type GroupCardRef = {
    stagesRefs: HTMLDivElement[]
}

export type FlowChartGroup =  { name: string, color: string }