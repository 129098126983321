
import { IPanelProps, Panel } from 'office-ui-fabric-react';
import * as React from 'react';
import { DirtyFormMessageBar } from './DirtyFormMessageBar';

type Props = IPanelProps & {
    isDirty: boolean,
    primaryCommandLabel: string;
    cancelCommandLabel: string;
}

const DirtyPanel = (props: Props) => {
    const [showHasChangesWarning, setShowHasChangesWarning] = React.useState(false);
    const onDismiss = React.useCallback((ev: React.SyntheticEvent<HTMLElement>) => {
        if (!props.onDismiss) {
            return;
        }

        if (props.isDirty && ev?.currentTarget?.classList?.contains("ms-Overlay")) {
            setShowHasChangesWarning(true);
            ev.preventDefault();
            return;
        }

        props.onDismiss();
    }, [props.isDirty, props.onDismiss]);

    const onRenderFooterContent = React.useCallback(() => {
        return <>
            {showHasChangesWarning && <DirtyFormMessageBar
                primaryCommandLabel={props.primaryCommandLabel}
                cancelCommandLabel={props.cancelCommandLabel}
            />}
            {props.onRenderFooterContent?.(props)}
        </>
    }, [showHasChangesWarning, props.primaryCommandLabel, props.cancelCommandLabel, props.onRenderFooterContent]);

    return <Panel
        {...props}
        onDismiss={onDismiss}
        onRenderFooterContent={onRenderFooterContent}
    />;
}

export default DirtyPanel;